.custom-control {
    &.lg {
        min-height: $font-size-base * $line-height-base + $custom-control-indicator-size-lg * 0.125;
        padding-left: $custom-control-gutter + $custom-control-indicator-size-lg;
        .custom-control-label {
            // Temporary padding-top
            padding-top: 2px;
            &::before {
                left: -($custom-control-gutter + $custom-control-indicator-size-lg);
                width: $custom-control-indicator-size-lg;
                height: $custom-control-indicator-size-lg;
            }
            &::after {
                left: -($custom-control-gutter + ($custom-control-indicator-size-lg));
                width: $custom-control-indicator-size-lg;
                height: $custom-control-indicator-size-lg;
            }
        }
    }
    &.custom-radio.square {
        .custom-control-label::before {
            border-radius: 0;
        }
    }
    &.dark {
        .custom-control-label {
            color: $dark;
        }
    }
}
.custom-control-input {
    &:checked {
        ~ .custom-control-label::before {
            background-color: $primary-dark;
            border-color: $primary-dark;
        }
    }
}

.custom-control-input {
    &:not(:disabled) {
        + label {
            cursor: pointer;
        }
    }
}
.wk-myprofile-left-tab {
    .form-control {
        &::placeholder {
            color: #D5D6E5;
        }
    }
}