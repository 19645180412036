$standard-z-index: 99 !default;
.bottom-bar {
    position: fixed;
    z-index: $standard-z-index;
    width: 100%;
    left: 0;
    bottom: 0;
}
.drawer-container {
    font-family: $font-family-base;
    position: relative;
    z-index: $standard-z-index;
}
.drawer-trigger {
    padding: 4px 8px;
    font-weight: $font-weight-bold;
    display: block;
    cursor: pointer;
    position: relative;
    z-index: $standard-z-index;
    line-height: 2;
    &:hover, &.active {
        background-color: $tertiary;
        color: $white;
    }
    &.active {
        + .drawer-slide {
            transform: translateY(0);
        }
    }
}
.drawer-slide {
    background-color: $text-dark;
    color: $tertiary;
    position: absolute;
    bottom: 100%;
    left: 0;
    min-width: 240px;
    min-height: 400px;
    padding: 0.5rem;
    transform: translateY(calc(100% + 50px));
    transition: transform 0.2s;
    z-index: $standard-z-index - 1;
}
.drawer-slide-item {
    font-family: $font-family-base;
}
.drawer-slide-trigger {
    font-weight: $font-weight-bold;
    cursor: pointer;
    &:hover {
        color: $white;
        // text-decoration: underline;
    }
}
.drawer-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: $standard-z-index - 2;
    background-color: rgba($color: $dark, $alpha: .3);
    display: none;
    &.show {
        display: block;
    }
}