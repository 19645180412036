.progress-secondary {
    border: 1px solid #ccc;
    background-color: #fff;

    .progress-bar {
        background-color: $secondary;
        color: #777;
    }
}
.progress-slim {
    height: 0.75rem;
}