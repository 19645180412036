.btn.btn-collapse {
    width: 26px;
    height: 26px;
    padding: 0;
    border-radius: 50%;
    background-color: transparent !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @include button-outline-variant($secondary, $secondary);
    &:focus {
        box-shadow: none;
    }
}
.collapse-header {
    color: $secondary;
    .separator {
        background-color: $secondary;
    }
    &.sm {
        .btn.btn-collapse {
            transform: scale(0.8);
        }
    }
}
.collapse-icon {
    position: relative;
    &::before, &::after {
        content: '';
        display: block;
        // position: absolute;
        width: 14px;
        height: 1px;
        background-color: $secondary;
        transition: all 0.1s;
    }
    &::after {
        transform: translateY(-1px) rotate(90deg);
        transform-origin: center;
    }
}

.collapse.show {
    + .collapse-header {
        .collapse-icon {
            &::after {
                transform: translateY(-1px) rotate(0);
            }
        }
    }
}

.collapse-scrollable {
    > .collapse {
        max-height: 300px;
        overflow-y: auto;
        overflow-x: hidden;
    }
}
.virtual-collapse-container {
    position: relative;

    .btn.btn-collapse {
        position: absolute;
        top: 15px;
        right: 15px;
    }

    &.deletable {
        .virtual-collapse-title {
            max-width: calc(100% - 70px);
        }
        .btn-delete {
            position: absolute;
            top: 15px;
            right: 50px;
        }
    }
}

.virtual-collapse-title {
    max-width: calc(100% - 40px);
}

.type-review {
    > .collapse-header {
        border-bottom: 1px solid #d5d6e5;
        padding: 12px;
        background: #fff;
        margin: 0;
        padding: 12px;
        font-size: 16px;
        font-family: 'Museo Sans 900';

        .separator {
            display: none;
        }
        .btn.btn-collapse {
            transform: scale(1);
        }
    }
    > .collapse, > .collapsing {
        padding: 20px 12px;
        &.show {
            + .collapse-header {
                color: $primary;
            }
        }
    }
}
.type-crp {
    > .collapse-header {
        .btn.btn-collapse {
            transform: scale(1);
            border-color: $tertiary;
            @include hover-focus {
                border-color: $tertiary;
            }
        }
        .collapse-icon {
            &::before, &::after {
                background-color: $tertiary;
            }
        }
    }
}
.type-theater {
    > .collapse-header {
        .title {
            color: $text-dark !important;
        }
        .btn.btn-collapse {
            transform: scale(1);
        }
    }
}
.title-type {
    > .collapse-header {
        .title {
            font-family: $font-family-alt;
            font-size: 18px !important;
        }
    }
}
.title-type-sm {
    > .collapse-header {
        .title {
            font-family: $font-family-alt;
            font-size: 16px !important;
        }
    }
}
.expanded-dark {
    > .collapse.show + .collapse-header {
        .btn.btn-collapse {
            border-color: $secondary-dark;
        }
        .collapse-icon {
            &::before, &::after {
                background-color: $secondary-dark;
            }
        }
    }
}
.expanded-dark-title {
    > .collapse.show + .collapse-header {
        .title {
            color: $text-dark;
        }
        .btn.btn-collapse {
            border-color: $text-dark;
        }
        .collapse-icon {
            &::before, &::after {
                background-color: $text-dark;
            }
        }
    }
}
.icon-lg {
    > .collapse-header {
        .btn.btn-collapse {
            transform: scale(1);
        }
    }
}
.type-greyish {
    > .collapse-header {
        .title {
            color: #d5dce5 !important;
            font-size: 14px !important;
        }
        .btn.btn-collapse {
            transform: scale(1);
        }
    }
}
.type-movie-white {
    > .collapse-header {
        .title {
            color: $white !important;
            font-size: 16px !important;
            font-family: $font-family-alt !important;
        }
        .btn.btn-collapse {
            transform: scale(1);
        }
    }
}
@each $color, $value in $theme-colors {
    @if $color != 'secondary' {
        .type-#{$color} {
            .collapse-header {
                color: $value;
                .separator {
                    background-color: $value;
                }
            }
            .btn.btn-collapse {
                @include button-outline-variant($value, $value);
                &:focus {
                    box-shadow: none;
                }
            }
            .collapse-icon {
                &::before, &::after {
                    background-color: $value;
                }
            }
        }
    }
}

.type-secondary {
    > .collapse-header {
        .separator {
            background-color: transparent;
            border-color: #ede5cd;
        }
    }
    &.heading-secondary-dark {
        > .collapse-header {
            h2 {
                color: $secondary-dark;
            }
        }
    }
    &.heading-lg {
        > .collapse-header {
            h2 {
                font-size: 38px;
            }
        }
    }
}

.collapsing {
    &.rating-collapse {
        transition-delay: 0.5s;
        transition-duration: 1s;
        transition-timing-function: linear;
    }
}

.category-collapse-holder {
    display: flex;
    flex-direction: column-reverse;
    .collapse, .collapsing {
        + .category-collapse-trigger {
            flex-grow: 1;
            .category-collapse-trigger--btn {
                align-self: flex-start;
                flex-basis: auto;
                background-image: url("../../assets/images/icons/plus_15.png");
                padding-left: 2px;
                padding-right: 2px;
                padding: 0 !important;
                margin: 0;
                min-width: 20px;
                width: 20px;
                height: 20px;
                background-position: 0 0;
                background-repeat: no-repeat;
            }
        }
        &.show {
            + .category-collapse-trigger {
                .category-collapse-trigger--btn {
                    background-image: url("../../assets/images/icons/minus15.png");
                }
            }
        }
    }
}

.history-collapse{
    color:$text-dark;
    .btn-collapse{
        color:$text-dark;
        border: 1px solid $text-dark;
        &:hover{
            color:white;
            border: 1px solid white;
            background-color: $text-dark;
        }
    }
    .collapse-icon::before,::after{        
        border: 1px solid $text-dark;
    }
}

.text-red{
    color:$text-red;
}