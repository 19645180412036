.buyers-guide-heading {
    text-shadow: 0 10px 10px rgba(0,0,0,0.2);
    font-size: 45px;
}

.guide-edit-mode {
    &--active {
        position: relative;
        z-index: 99;
        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background-color: rgba($color: $tertiary, $alpha: 0.5);
        }
    }
    
    &--highlighted {
        position: relative;
        z-index: 100;
        &.bg-white-shaded-md {
            background-color: #fff !important;
        }
    }
}
.read-only-buyers-para-container {
    color: $text-dark;
    font-family: $font-family-alt;
    word-break: break-word;
    h6 {
        font-size: 16px;
        color: $dark;
        font-weight: $font-weight-bold;
        font-family: $font-family-base;
    }
    p {
        margin-bottom: 0.75rem;
    }
    a {
        color: $dark;
        font-weight: $font-weight-bold;
    }
    &.type-reference {
        font-size: 13px;
        ul {
            list-style-type: none;
            padding-left: 0;
            > li {
                strong {
                    color: $dark;
                }
                margin-bottom: 1rem;
            }
        }
    }
}
.guide-product-title {
    color: $tertiary;
    font-weight: $font-weight-bold;
    margin: 0.75rem 0;
    display: block;
}
.custom-tooltip-container {
    &:hover {
        .guide-product-title {
            color: $text-dark;
        }
    }
}
