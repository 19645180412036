.wk-static-wrapper {
    padding: 44px 0 28px;
    min-height: calc(100vh - 167px);
    .wk-static-sub-head {
        background: #00162B;
        width: 100%;
        text-shadow: none;
        text-align: center;
        h1 {
            color: $white;
            font-size: 44px;
            font-family: $headings-font-family;
            line-height: 2.5;
        }
    }
    .wk-static-content-body {
        background: $white;
        padding: 28px 28px;
        .wk-static-sidebar-left {
            @extend .position-sticky;
            top: 60px;
            max-height: calc(100vh - 60px);
            overflow-y: auto;
            h2 {
                color: #325271;
                width: 100%;
                margin: 0 0 0 0;
                text-transform: uppercase;
                font-size: 38px;
                font-family: $headings-font-family;
                &.wk-press-head {
                    margin-bottom: 20px;
                }
            }
            .press-sub-head {
                font-family: $font-family-base;
                color: #00162B;
                font-size: 14px;
                line-height: 1.5;
                margin-bottom: 15px;
            }
            ul {
                margin: 0;
                padding: 15px 0px;
                &.wk-press-list {
                    padding: 0 0 15px;
                    li {
                        padding-bottom: 0;
                        a {
                            font-weight: normal;
                            font-family: $font-family-alt;
                            font-size: 14px;
                            transition: all 0.5s;
                            &:hover {
                                padding-left: 10px;
                                text-decoration: none;
                            }
                        }
                    }
                }
                li {
                    list-style: none;
                    display: block;
                    padding-bottom: 8px;
                    a {
                        color: $dark;
                        font-family: $font-family-base;
                        display: inline-block;
                        font-weight: 600;
                        font-size: 15px;
                        transition: all 0.5s;
                        &:hover {
                            padding-left: 10px;
                            text-decoration: none;
                        }
                    }
                }
            }
        }
        .wk-static-content-right {
            h2 {
                font-family: $headings-font-family;
                color: $dark;
                text-transform: uppercase;
                font-size: 38px;
                margin-bottom: 20px;
                &[id] {
                    padding-top: 60px;
                    margin-top: -60px;
                }
            }
            p {
                font-family: $font-family-alt;
                color: $dark;
                line-height: 1.5;
                &[id] {
                    padding-top: 60px;
                    margin-top: -60px;
                }
                u {
                    &[id] {
                        padding-top: 60px;
                        margin-top: -60px;
                    }   
                }
            }
            h4 {
                color: #325271;
                font-family: $font-family-base;
                line-height: 1.5;
                font-weight: bold;
                margin-bottom: 8px;
            }
            .wk-awards-sub-title {
                font-size: 18px;
                font-family: $font-family-alt;
            }
            ul {
                margin: 0;
                padding: 0 20px 20px;
                li {
                    list-style: disc;
                    font-size: 14px;
                    font-family: $font-family-alt;
                    color: $dark;
                    line-height: 1.5;
                }
            }
            .wk-awards-image {
                display: block;
                max-width: 68px;
                margin-bottom: 28px;
            }
            .wk-awards-box {
                padding: 0 5px;
                border-bottom: 1px solid #EFEBEB;
                margin: 0 0 10px 0;
                .wk-awards-content {
                    margin: 0 0px 0 10px;
                    font-family: $font-family-base;
                    display: inline-block;
                    .wk-awards-img {
                        display: inline-block;
                        width: 30px;
                        margin: 0 10px 10px 10px;
                        img {
                            max-width: 100%;
                        }
                    }
                }
            }
        }
    }
}

.wk-static-wrapper-settings {
   
    .wk-static-sub-head {
        background: #00162B;
        width: 100%;
        text-shadow: none;
        text-align: center;
        h1 {
            color: $white;
            font-size: 44px;
            font-family: $headings-font-family;
            line-height: 2.5;
        }
    }
    .wk-static-content-body {
        background: $white;
        padding: 28px 28px;
        .wk-static-sidebar-left {
            @extend .position-sticky;
            top: 60px;
            max-height: calc(100vh - 60px);
            overflow-y: auto;
            h2 {
                color: #325271;
                width: 100%;
                margin: 0 0 0 0;
                text-transform: uppercase;
                font-size: 38px;
                font-family: $headings-font-family;
                &.wk-press-head {
                    margin-bottom: 20px;
                }
            }
            .press-sub-head {
                font-family: $font-family-base;
                color: #00162B;
                font-size: 14px;
                line-height: 1.5;
                margin-bottom: 15px;
            }
            ul {
                margin: 0;
                padding: 15px 0px;
                &.wk-press-list {
                    padding: 0 0 15px;
                    li {
                        padding-bottom: 0;
                        a {
                            font-weight: normal;
                            font-family: $font-family-alt;
                            font-size: 14px;
                            transition: all 0.5s;
                            &:hover {
                                padding-left: 10px;
                                text-decoration: none;
                            }
                        }
                    }
                }
                li {
                    list-style: none;
                    display: block;
                    padding-bottom: 8px;
                    a {
                        color: $dark;
                        font-family: $font-family-base;
                        display: inline-block;
                        font-weight: 600;
                        font-size: 15px;
                        transition: all 0.5s;
                        &:hover {
                            padding-left: 10px;
                            text-decoration: none;
                        }
                    }
                }
            }
        }
        .wk-static-content-right {
            h2 {
                font-family: $headings-font-family;
                color: $dark;
                text-transform: uppercase;
                font-size: 38px;
                margin-bottom: 20px;
                &[id] {
                    padding-top: 60px;
                    margin-top: -60px;
                }
            }
            p {
                font-family: $font-family-alt;
                color: $dark;
                line-height: 1.5;
                &[id] {
                    padding-top: 60px;
                    margin-top: -60px;
                }
                u {
                    &[id] {
                        padding-top: 60px;
                        margin-top: -60px;
                    }   
                }
            }
            h4 {
                color: #325271;
                font-family: $font-family-base;
                line-height: 1.5;
                font-weight: bold;
                margin-bottom: 8px;
            }
            .wk-awards-sub-title {
                font-size: 18px;
                font-family: $font-family-alt;
            }
            ul {
                margin: 0;
                padding: 0 20px 20px;
                li {
                    list-style: disc;
                    font-size: 14px;
                    font-family: $font-family-alt;
                    color: $dark;
                    line-height: 1.5;
                }
            }
            .wk-awards-image {
                display: block;
                max-width: 68px;
                margin-bottom: 28px;
            }
            .wk-awards-box {
                padding: 0 5px;
                border-bottom: 1px solid #EFEBEB;
                margin: 0 0 10px 0;
                .wk-awards-content {
                    margin: 0 0px 0 10px;
                    font-family: $font-family-base;
                    display: inline-block;
                    .wk-awards-img {
                        display: inline-block;
                        width: 30px;
                        margin: 0 10px 10px 10px;
                        img {
                            max-width: 100%;
                        }
                    }
                }
            }
        }
    }
}
.bottom-bar {
    .drawer-slide-item {
        .drawer-slide-trigger {
            .wk-movies-drop-down {
                position: absolute;
                left: calc(100% - 2px);
                background: #325271;
                width: 200px;
                top: 0;
                padding-left: 20px;
                display: none;
                // transition-delay: 1s;
            }
            &:hover {
               .wk-movies-drop-down {
                //    transition-delay: 0;
                   display: block;
                   &:after {
                       color: $white;
                   }
               } 
            }
            
            &.has-dropdown-menu {
                
                &:after {
                    content: ">";
                    background: none;
                    color: $tertiary;
                    position: absolute;
                    right: 0;
                    top: 0;
                }
            }
        }
    }
}
.wk-crpmodal {
    .wk-multiselect {
        height: 31px;
    }
    .wk-upload-media-improve {
        border-bottom: 1px solid $tertiary;
    }
    .wk-crpmodalhead {
        color: $text-dark;
    }
    p {
        color: $black;
        font-size: 14px;
        a {
         color: $darker;
         font-weight: $font-weight-semi-bold;
         &.font-normal {
             font-weight: $font-weight-normal;
         }
        }
    }
    .wk-crpmodal-quick-note {
       max-width: 284px;
       border: 1px solid #d5d6e5;
       color: $tertiary;
       line-height: 1.2;
       text-align: center;
       resize: none;
       height: 86px;
      padding-top: 28px;
      font-size: 14px;
    }
     .wk-crpmodal-quick-note2 {
        width: 100%;
        max-width: 100%;
        height: 150px;
        padding: 8px;
        text-align: left;
     }
    .wk-crp-projectinfo-text {
      i 
        {
          width: 31px;
          height: 24px;
          background-image: url(https://userdatawikireviews.s3.amazonaws.com/images/sprite.png);
          background-position: 0 0;
          background-repeat: no-repeat;
          display: inline-block;
          line-height: 14px;
          margin-top: 1px;
          vertical-align: text-top;
      }
      .icon-comma {
          background-position: -95px -61px;
          float: left;
          margin-right: 12px;
      }
      span {
          &:after {
            background: url(https://userdatawikireviews.s3.amazonaws.com/images/bq-bg.png) no-repeat scroll left top rgba(0, 0, 0, 0);
            content: "11";
            display: inline-block;
            height: 24px;
            position: relative;
            right: -8px;
            text-indent: -111111px;
            top: 2px;
            width: 31px;
          }
      }
    }
    .wk-rate-image-left {
        width: 100%;
        max-height: initial;
    }
    .wk-rating-crp-block {
       background: $turquoise;
       ul {
         &:before {
            width: 30%;
            height: 1px;
            background: $text-dark;
            position: absolute;
            content: '';
            display: inline-block;
            top: 13px;
            visibility: visible;
            left: 0;
         }
         &:after {
            width: 31%;
            height: 1px;
            background: $text-dark;
            position: absolute;
            content: '';
            display: inline-block;
            top: 13px;
            visibility: visible;
            right: 0;
         }
       }
    }
    .wk-crp-modal-profile {
        width: 30px;
        height: 30px;
        border-radius: 100%;
    }
}
.wk-other-images-new {
    .col {
        width: 25%;
        flex: none;
        margin-top: 15px;
        padding: 0px 15px;
    }
}
.wk-core-petrol-listingleft {
    .wk-core-petrol-listingimage {
        width: 100px;
    }
    .wk-core-petrol-listingcontent {
        width: calc(100% - 115px);
        margin-left: 15px;
        h4 {
          font-family: Arial, Helvetica, sans-serif;
        }
    }
}
.wk-buyers-guide-serch {
    input 
    {
       border: 1px solid $khakhi;
    }
    svg {
       position: absolute;
        right: 10px;
        top: 10px;
        path {
          fill: $khakhi;  
        }
    }
}
.wk-articles-tab-content {
    border-top: 1px solid $gray-400;
}
.wk-voting-movie-headerleft {
    background: url(https://userdatawikireviews.s3.amazonaws.com/images/voting-img2.png) no-repeat scroll 0 0;
    background-size: cover;
    h2 {
        font-size: 64px;
    }
}
.wk-vote-movie-block {
    border-top: 1px solid $gray-400;
    ul {
        li {
            list-style: none;
            a {
                transition: 0.5s ease;
                &:hover {
                    padding-left: 15px;
                    text-decoration: none;
                }
            }
        }
    }
    .wk-vote-movie-sublist {
        li {
           list-style: disc;
        } 
    }
}
.wk-home-new-tabs {
   .tab-content {
       .ff-alt {
           word-break: break-word;
       }
   }
}
.wk-home-new-adds {
  img {
      max-height: initial;
  }
}
.wk-loader-new-spinner {
    min-height: 60px;
    .spinner {
        position: absolute;
        left: 0;
        background: rgba(255,255,255,0.6);
        right: 0;
        margin: 0 auto;
        height: 100%;
        .spinner-border {
            margin-top: 30px;
        }
    }
} 
.wk-bd-new-box {
    .wk-bd-top-image {
        margin: 0 auto;
    }
}
.wk-improve-media-uploadimage {
    height: 150px;
    width: 100%;
}
.article-name{
	font-weight: bold;
    color: #726647;
}

.nw-header-min-height{ min-height: 100px; }

.wk-new-address-reported {
    width: 100%;
    background: $white;
    height: auto;
    top: 80px;
    z-index: 99;
    border: 1px solid #ddd;
    left: 0;
    right: 0;
    margin: 0 auto;
    .wk-new-address-reportedheader {
        background: #fed01a;
    }
}
.wk-flagging-new-form  {
    background: $white;
    height: auto;
    top: 80px;
    z-index: 99;
    border: 1px solid #ddd;
    width: 400px;
    left: 0;
    right: 0;
    margin: 0 auto;
    textarea.wk-flogging-notes-textarea {
        height: 110px;
        resize: none;
    }
}
.wk-edit-listing-mode {
    background: $white;
    height: auto;
    top: 80px;
    z-index: 99;
    border: 1px solid #ddd;
    width: 100%;
    left: 0;
    right: 0;
    margin: 0 auto;
   height: auto;
}
.wk-business-warning-bar {
    background-color: #fed01a;
    z-index: 9;
    width: 100%;
    .wk-business-bar-close {
        right: 20px;
        top: 60px;
    }
}
.wk-business-gallery-uploadmodal 
{
    width: 750px;
    position: fixed;
    left: 0;
    right: 0;
    margin: 0 auto;
    display: block;
    top: 80px;
    .wk-business-gallery-inner {
        background: $black;
    }
}
.wk-business-gallery-uploadmodalouter {
    position: fixed;
    left: 0;
    right: 0;
    position: fixed;
    background: rgba($black,0.8);
    width: 100%;
    height: 100%;
    z-index: 99;
    top: 0;
}
.wk-profile-video-upload {
    a {
        img {
            background: #f4f3ea;
            border: 1px solid #dee2e6;
            margin-bottom: 8px;
            padding: 0.25rem !important;
            width: 100% !important;
        }
        position: relative;
        display: block;
        &:after {
            content: '';
            position: absolute;
            top: calc(50% - 30px);
            left: calc(50% - 30px);
            width: 60px;
            height: 60px;
            margin: 0;
            // background-color: rgba($color: $black, $alpha: 0.3);
            background-image: url('../../assets/images/sprite.png');
            background-position: -296px -122px;
        }
    }
}
.wk-upload-profile-photos {
    .uploaded-img-thumbnail {
        width: 25% !important;
        min-width: 0;
    }
}
.wk-profile-video-upload {
    div {
        width: 25%;
        padding: 0 0.25rem;
    }
}
.wk-profile-video-upload div:nth-child(4n+1) {
    padding-left: 0;
}
.wk-search-profile-friend {
    .bg-search {
        background-image: none;
    }
    .wk-friendprofile-search-button {
        background: none;
        border: none;
        position: absolute;
        top: 0;
        right: 8px;
        height: 100%;
        padding: 0;
        display: flex;
        align-items: center;
        &:focus 
        {
            outline: none;
            box-shadow: none;
            background: none;
        }
        svg {
            path {
                fill: #726647;
            }
        }
    }
}
.connection-container{
    max-height: 235px;
    overflow-y: scroll;
}
.wk-friends-search-box {
    background-color: #eaeaf2;
    .wk-friends-search-image {
        width: 50px;
        height: 50px;
        border-radius: 25px;
    }
}
.wk-privacy-setting-content {
    border-bottom: 1px solid $gray-400;
}
.wk-friends-google-btn {
    border: none !important;
    border-radius: 0 !important;
    padding: 0.5rem 1rem !important;
    span {
        padding: 0 !important;
        font-weight: 700 !important;
    }
}
.category-collapse-holder {
    .btn-active {
        font-weight: bold !important;
        text-decoration: none;
    }
}
.wk-business-sort-dropdown {
    .dropdown-item {
        &:hover {
          color: #325271 !important;
          font-weight: bold;
        }
    }
}
.main-post-formgroup  {
    .wk-client-write-review {
      padding: 20px 8px;
      line-height: 1.2em;
    }
}
.wk-carousel-close-btn {
    position: absolute;
    top: -8px;
    right: 0;
    svg {
        path {
            fill:#333;
        }
    }
}
.wk-carousel-delte-btn {
    position: absolute;
    top: -8px;
    left: 0;
}
.wk-project-other-images {
    display: block;
    .col {
        width: 33.33%;
        float: left;
        padding: 15px;
    }
}
.wk-signup-terms-check {
    .required-mark {
      vertical-align: middle;
      position: relative;
      top: 2px;
    }
}
.form-row  {
    .wk-profile-small-images {
        padding: 0px 8px;
        .fixed-height-img {
            border: 1px solid #ddd;
            padding: 4px;
        }
    }
}
.wk-signup-new-btn  {
    .btn {
        border-radius: 5px;
    }
}
.post-placeholder-static.wk-profile-post-static {
    color: #00A0B1;
    font-size: 15px;
    line-height: 1.8;
    div:last-child {
        font-size: 14px;
        font-weight: 600;
    }
}
.wk-create-review-btn {
    margin-top: 20px;
}
.wk-compose-new-messagebody  {
    .form-control::placeholder {
        color: #D5D6E5;
    }
}
.wk-my-friends-search::placeholder {
   font-weight: 400;
}
.wk-project-type-category {
   select  {
    color: #879ab5;
    font-weight: bold;
   }
}
.wk-ps-upload-image {
    img {
       height: 70px;
       width: 100%;
    }
}
