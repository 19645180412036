.stars-rating-input {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    line-height: 1;
    .zero-rating-input {
        margin-right: 0.25rem;
        color: #d7d8e2;
        font-size: 1rem;
        position: relative;
        .svg-inline--fa {
            vertical-align: middle;
            transform: rotate(90deg);
            margin-right: 6px;
        }
        &::after {
            content: '';
            position: absolute;
            right: 0;
            top: 0;
            display: inline-block;
            height: 100%;
            width: 1px;
            background-color: #d7d8e2;
        }
        &:hover, &.active {
            color: $primary;
        }
    }
    &._sm {
        .zero-rating-input {
            font-size: 0.75rem;
        }
    }
    &._lg {
        .zero-rating-input {
            font-size: 1.5rem;
        }
    }
    .clear-rating {
        display: block;
        margin-left: 2px;
        padding: 0px 4px 8px;
    }
}