.contribution-time-stamp {
    font-size: 0.8rem;
    color: grey;
}

.attachment-media-star-rating {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 16px;
    cursor: pointer;
}

.attachment-media-star-rating * {
    cursor: pointer !important;
}

.attachment-media-star-popover-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.attachment-media-status {
    display: flex;
    justify-content: center;
    padding-right: 16px;
    font-weight: bold;
    font-size: 0.85rem;
}

.color-approved {
    color: #15c3d4;
}

.color-pending {
    color: grey;
}

.color-reversed {
    color: red;
}

.reversed-status-header {
    font-family: "Museo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.reversed-status-cursor-pointer {
    cursor: pointer;
}

.z-1000 {
    z-index: 1000;
}