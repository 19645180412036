.Toastify__toast-container--top-right {
   top: unset !important;
   right: unset !important;
}

.Toastify__toast-container {
   width: 100% !important;
}

.Toastify__toast-body {
   text-align: center !important;
}

.Toastify__toast {
   max-height: 86px !important;
}

.Toastify__toast--default {
   background: #16c3d4 !important;
   color: #fff !important;
   font-size: 16px !important;
   font-weight: 600 !important;
}

.inner-loader {
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   /* background: #fff; */
   opacity: 1;
   z-index: 10;
}

.loader-shadow {
   background-color: #fff;
   height: 100%;
   width: 100%;
   position: absolute;
   z-index: 5;
   top: 0;
   left: 0;
   opacity: 0.8;
   cursor: pointer;
}

.loader_div {
   display: block;
   width: 100%;
   height: 100%;
   background: #fff;
   position: fixed;
   z-index: 9999;
   opacity: 0.6;
   left: 0;
   top: 0;
}

/* @media screen and (max-width: 320px) and (max-width: 767px) and (orientation: landscape) {
   html {
      transform: rotate(-90deg);
      transform-origin: left top;
      width: 100vh;
      overflow-x: hidden;
      position: absolute;
      top: 100%;
      left: 0;
   }
} */

.round-display {
   border-radius: 50% !important;
   height: 150px;
   width: 150px;
   margin-left: auto;
   margin-right: auto;
   margin-top: 25px;
   object-fit: cover;
}

.flip-card {
   background-color: transparent;
   width: 100%;
   padding-left: 12px;
   padding-right: 12px;
   height: 200px;
   perspective: 1000px;
}

.flip-card-inner {
   position: relative;
   width: 100%;
   height: 100%;
   text-align: center;
   transition: transform 0.6s;
   transform-style: preserve-3d;
   // box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.flip-card:hover .flip-card-inner {
   transform: rotateX(180deg);
}

.flip-card-front,
.flip-card-back {
   position: absolute;
   width: 100%;
   height: 100%;
   -webkit-backface-visibility: hidden;
   backface-visibility: hidden;
}

.flip-card-front {
   background-color: #fff;
   color: black;
}

.flip-card-back {
   background-color: #fff;
   color: blue;
   transform: rotateX(180deg);
}

.custom-hover-search {
   background: #fff;
   border: 1px solid #ddd;
   cursor: pointer;
}

.custom-hover-search:hover {
   background-color: #0ec1d3;
   color: #e9ecef;
   font-weight: 600;
   cursor: pointer;
}

.sm-img-search {
   height: 50px;
   width: 50px;
   border-radius: 50%;
   object-fit: cover;
}

.cursor-pointer {
   cursor: pointer;
}

.mt-10 {
   margin-top: 10px;
}

.days-ago {
   color: #fff;
   padding-left: 160px;
}

.no-records {
   font-size: 30px;
   text-transform: capitalize;
}

.span_circle_50x50 {
   background: #fff;
   padding: 15px;
   border-radius: 50%;
   border:1px solid #ddd;
   
   &-home{
      color: #325271 !important;
      text-align: center;
      line-height: 50px !important;
      border: 1px solid #325271 !important;
      border-radius: 50%;
      height: 50px;
      width: 50px;
   }
}

.mt-12-fixed { 
   margin-top: 12px !important;
}

.custom-date-picker .react-datepicker-wrapper {
   display: block !important;
}

.product-buyers-guide {
   background-color: transparent;
}

.item-outer-cat {
   padding: 15px;
}

.item-set-category {
   background: #000;
   padding: 15px;
   color: #fff;
   cursor: pointer;
}

.item-set-category-primary {
   background: #15c3d4;
   padding: 15px;
   color: #fff;
   cursor: pointer;
}

.item-set-category-middle {
   background: #000;
   padding: 15px 100px;
   color: #fff;
   cursor: pointer;
}

// .product-buyers-guide {
//    bottom: 3px;
//    left: 10%;
//    margin: 0 auto;
//    // position: absolute;
//    width: 80%;
// }

// .back-cate-btn {
//    background: none repeat scroll 0 0 rgba(0, 0, 0, 0.8);
//    color: #fff;
//    float: left;
//    font-family: 'museo_sans900';
//    font-size: 16px;
//    height: 40px;
//    line-height: 40px;
//    margin-top: 11px;
//    padding: 0 14.5px;
// }

// .product-name {
//    background: none repeat scroll 0 0 rgba(0, 0, 0, 0.8);
//    color: #fff;
//    font-family: 'LeagueGothicRegular';
//    font-size: 30px;
//    left: 0;
//    line-height: 40px;
//    margin: 0 auto;
//    position: absolute;
//    right: 0;
//    text-align: center;
//    width: 50%;
//    max-width: 500px;
//    max-width: 200px;
//    bottom: 0;
// }

// .buyer-btn {
//    background: none repeat scroll 0 0 #15c3d4;
//    color: #fff;
//    float: right;
//    font-size: 14px;
//    height: 40px;
//    line-height: 40px;
//    margin-top: 11px;
//    padding: 0 14.5px;
//    text-align: center;
// }

.outer-link {
   // border: 1px solid #ddd;
   // margin-bottom: 10px;
   padding: 0.25rem;
    background-color: #f4f3ea;
    border: 1px solid #dee2e6;
   iframe,img{
      width: 100%;
      height: auto;
      aspect-ratio: 16/9;
      // max-height: 150px;
   }
}


.embdscl0.embd-active {
    opacity: 0 !important;
 }

 .set-item-embed {
   width: calc(75% - 15px);
   margin-left: auto;
   margin-top: 20px;
   word-break: break-word;
}


.uploaded-img-video-thumbnail {
   height: 150px;
   width: 250px;
   max-width: 250px;
}

span.label-dynamic-rating {
   margin: 5px 0 0 10px;
   font-weight: normal;
   font-size: 13px;
   color: #879AB5;
   font-style: italic;
   font-family: Arial, Helvetica, sans-serif;
   text-align: left;
   padding: 0 0 0 10px;
   width: auto;
   float: left;
}

.post-placeholder-static {
   position: absolute;
   z-index: 2;
   text-align: center;
   line-height: 1.8;
   color: #15c3d4;
   width: 100%;
   font-weight: 700;
   margin-top: -120px;
   font-size: 14px;
   > div:last-child {
      font-weight: normal;
   }
}

#container-video {
   width: 120px;
   background-color: red;
   /*border-radius: 25px;*/
   border: 2px solid Black;
   padding: 15px 15px 15px 15px;
   margin: 20px 20px 20px 20px;
   background: #A4D3EE;
   overflow: visible;
   box-shadow: 5px 5px 2px #888888;
   position: relative;
 }
 
 #x {
   position: absolute;
   background: red;
   color: white;
   left: 175px;
 }

 .blink_me {
   animation: blinker 1s linear infinite;
 }
 
 @keyframes blinker {
   50% {
     opacity: 0;
   }
 }

 .black-box {
    width: 100%;
    height: 160px;
    background-color: #000;
}

.overlap-checkbox {
   padding-left: 20px;
   background-color:transparent;
   left: 0px;
   position: absolute;
}

.times-1 {
   background: transparent;
   padding: 0px 5px;
}

textarea.mb-2.glow-input.form-control-sm.form-control::placeholder {
   width: calc(100% - 4.7em);
   font-size: 14px;
   padding: 10px;
   color: #15c3d4;
   font-weight: 800;
}

.cursor-default {
   cursor: default
}

.error-text {
   font-size: 14px;
   color: #ec0606;
   font-weight: 500;
   margin-top: 15px;
}

.masked_entity {
   filter: blur(4px);
}