// Card scss
.card {
   @include border-radius(0);
   border: none;
   %title{
    @for $i from 2 through 6 {
        h#{$i} {
            color: $khakhi;
            font-weight: bold;
        }
      }
   }
   &-title{
       color: $khakhi;
       @extend %title;
   }
   &-subtitle{
    color: $khakhi;
    font-weight: bold;
    @extend %title;
   }

   
}