.modal{
    &-header{
        align-items: center;
        color: $white;
        .close{
            color: $white;
            opacity: 1;
        }
    }
    &-title{
        font-size: 36px;
    }
    &-body{
        background: $white;
    }
    .modal-header{
        .close {
            font-weight: 100;
            &:before {
                content: 'Close ';
                display: inline;
                font-family: $font-family-base;
                font-weight: $font-weight-normal;
                font-size: 14px;
            }
            &:after {
                content: '';
                background-image: url("../../assets/images/sprite.png");
                background-repeat: no-repeat;
                display: inline-block;
                height: 12px;
                line-height: 14px;
                margin-top: 1px;
                vertical-align: text-top;
                width: 13px;
                background-position: -228px -289px;
                margin-left: 10px;
                margin-top: 2px;
                vertical-align: middle;
            }
            span {
                display: none;
            }
            @include hover-focus() {
                outline: 0;
            }
        }
    }
}
.modal-backdrop {
    background-color: $darker;
}

.top-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9;
    box-shadow: $box-shadow;
    // display: none;
    transform: translateY(-100%);
    transition: all 0.2s;
    z-index: 1031;
    &._fixed-header {
        top: 45px;
        transform: translateY(calc(-100% - 45px));
    }
    &.show {
        // display: block;
        transform: translateY(0);
    }
}

.modal.backdrop-opaque {
    + .modal-backdrop.show {
        opacity: 1;
    }
}

.modal.transition-none {
    transition: none;
    .modal-dialog {
        transition: none;
    }
}

.modal.top-shifted {
    .modal-dialog {
        top: 100px;
    }
}

/* @include media-breakpoint-up(sm) {
    .modal-sm {
        max-width: 400px;
    }
} */
// @include media-breakpoint-up(md) {
//     .modal-content {
//         box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
//     }
// }

#tour1.modal-dialog {
    max-width: 500px;
    .modal-content {
        border-radius: 0px;
        color: #fff;
        background-color: $text-dark;
        background-image: url("../../assets/images/website-tour/t1-home.jpg");
        background-size: cover;
        line-height: 1.3;
    }
    .modal-title {
        font-size: 46px;
        line-height: normal;
    }
    .modal-title-login-prompt{
        font-size:40px
    }
    .modal-header {
        padding: 15px;
    }
    .modal-footer {
        padding: 0 0 15px 0;
    }
    .modal-header,
    .modal-footer {
        text-align: center;
        justify-content: center;
    }
    .modal-body {
        text-align: center;
        background-color: transparent;
        padding: 0px 15px;
    }
    .tourbtn {
        margin-bottom: 10px;
        font-size: 18px;
        padding: 10px 0;
        height: auto;
        width: 170px;
    }
    p {
        font-family: $font-family-base;
        font-size: 20px;
        font-weight: 500;
    }
    a {
        font-size: 13px;
        color: #fff;
        font-weight: 500;
        margin: 10px 0 0 0;
        display: inline-block;
    }
}