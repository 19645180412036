.dropdown-header {
    font-family: $font-family-base;
    font-weight: $font-weight-medium;
}

.dropdown * {
    &:focus {
        outline: 0;
    }
}
.dropdown-item, .dropdown-toggle {
    .count {
        font-family: $font-family-alt !important;
        font-size: 12px !important;
        font-weight: $font-weight-normal !important;
        padding: 3px 6px !important;
        margin-left: 6px;
        min-width: 20px;
        height: 20px;
        background-color: $primary !important;
        color: $white !important;
        align-self: center;
        text-align: center;
        line-height: 14px !important;
        border-radius: 10px;
    }
}
.dropdown {
    > .btn-transparent {
        @include hover-focus {
            text-decoration: none;
        }
        &:focus {
            box-shadow: none;
        }
    }
    &.hoverable {
        display: inline-block;
        &:hover {
            > .dropdown-toggle {
                + .dropdown-menu {
                    display: block;
                    margin-top: 0;
                }
            }
        }   
    }
}

.dropdown-toggle.type-transparent {
    background-color: transparent !important;
    color: $white !important;
    border: 0 !important;
    margin-top: -2px;
    @include hover-focus {
        box-shadow: none !important;
    }
}


.dropdown-sm {
    .dropdown-menu {
        font-size: 14px;
    }
    .dropdown-divider {
        margin: 0.25rem 0;
    }
}
.dropdown-menu.type-secondary {
    background-color: fade-out($color: $secondary, $amount: 0.05);
    color: $white;
    margin-top: -2px;
    .dropdown-item{
        @include hover-focus() {
            color: $white;
            background: transparent;
        }
    }
    &._bold {
        .dropdown-item {
            font-weight: $font-weight-bold;
        }
    }
    &._alt {
        .dropdown-item {
            color: $white;
            @include hover-focus() {
                color: $secondary-dark;
            }
        }
    }
}

.dropdown-menu.type-tertiary2 {
    background-color: fade-out($color: #879ab5, $amount: 0.05);
    color: $white;
    margin-top: -2px;
    .dropdown-item{
        @include hover-focus() {
            color: $white;
            background: transparent;
        }
    }
    &._bold {
        .dropdown-item {
            font-weight: $font-weight-bold;
        }
    }
    &._alt {
        .dropdown-item {
            color: $white;
            @include hover-focus() {
                color: rgb(0, 22, 43);
            }
        }
    }
}



.dropdown-menu.type-tertiary {
    background-color: fade-out($color: $tertiary, $amount: 0.05);
    color: $white;
    margin-top: -2px;
    .dropdown-item{
        color: $white;
        @include hover-focus() {
            color: $black;
            background: transparent;
        }
    }
}
.dropdown-menu.type-tertiary-alt {
    background-color: fade-out($color: $tertiary, $amount: 0.13);
    color: $white;
    min-width: 180px;
    padding: 0;
    border: 0;
    margin-top: -2px;
    .dropdown-item{
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        font-size: 13px;
        color: $white;
        font-weight: $font-weight-bold;
        margin-bottom: 3px;
        @include hover-focus() {
            // color: $black;
            background: transparent;
            text-decoration: underline;
        }
        &.has-submenu {
            &.to-left {
                // padding-left: 1rem;
                &:before {
                    right: 0;
                }
            }
            .dropdown-submenu {
                background-color: fade-out($color: $tertiary, $amount: 0.13);
                border: 0;
            }
        }
    }
}

li.dropdown-item {
    position: relative;
    &.has-submenu {
        display: flex;
        cursor: pointer;
        &:after {
            position: relative;
            left: 0.5rem;
            right: unset;
            display: inline-block;
            margin-left: auto;
            align-self: center;
            vertical-align: 0;
            content: "";
            border-top: 0.3em solid transparent;
            border-left: 0.3em solid;
            border-bottom: 0.3em solid transparent;
        }
    }
    
    > .dropdown-submenu {
        @extend .dropdown-menu;
        position: absolute;
        left: 100%;
        top: 0;
        display: none;
    }
    
    &.to-left {
        &.has-submenu {
            &:before {
                position: relative;
                right: 0.5rem;
                left: unset;
                display: inline-block;
                vertical-align: 0;
                content: "";
                align-self: center;
                margin-right: auto;
                border-top: 0.3em solid transparent;
                border-right: 0.3em solid;
                border-bottom: 0.3em solid transparent;
            }
            &:after {
                display: none;
            }
        }
        > .dropdown-submenu {
            right: 100%;
            left: initial;
        }
    }
    &:hover {
        > .dropdown-submenu {
            display: block;
        }
    }
}

.share-it-icon {
    background: url("../../assets/images/icons/share_feed.png") no-repeat;
    width: 20px;
    height: 20px;
    padding: 0;
    background-position: center center;
}
.thumbpin-icon {
    background: url("../../assets/images/sprite.png") no-repeat -360px -12px;
    width: 20px;
    height: 20px;
    padding: 0;
}
.downfeed-icon {
    background: url("../../assets/images/icons/down_feed.png") no-repeat;
    width: 20px;
    height: 20px;
    padding: 0;
    background-position: center center;
}
.addto-icon {
    background-repeat: no-repeat;
    background-size: contain;
    width: 14px;
    height: 14px;
    display: inline-block;
    margin-right: 0.25rem;
    vertical-align: middle;
    &.want-list {
        background-image: url("../../assets/images/icons/want-list.png");
    }
    &.other-want-list {
        background-image: url("../../assets/images/icons/want-list-other.png");
    }
    &.favorite-list {
        background-image: url("../../assets/images/icons/favoritelist.png");
    }
    &.other-favorite-list {
        background-image: url("../../assets/images/icons/favoritelist-other.png");
    }
    &.watch-list {
        background-image: url("../../assets/images/icons/watchlist.png");
    }
    &.other-watch-list {
        background-image: url("../../assets/images/icons/watchlist-other.png");
    }
}

.dropdown-menu.results-dropdown {
    background-color: $white;
    padding: 0.5rem 1rem;
    box-shadow: 0px 1px 5px #ddd;
    .dropdown-item {
        padding: 5px 10px 5px 30px;
        color: $text-dark;
        font-size: 14px;
        // margin-top: -4px;
        margin-bottom: 6px;
        border-bottom: 1px solid #d5d6e5;
        position: relative;
        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            display: inline-block;
            background-image: url("../../assets/images/icons/top-bar-search.png");
            background-repeat: no-repeat;
            background-size: 21px;
            height: 25px;
            width: 21px;
        }
        &[type="all"] {
            &:before {
                background-position: 0 4px;
            }
            color: $darker;
        }
        &[type="business"] {
            &::before {
                background-position: 0 -47px;
            }
        }
        &[type="products"] {
            &::before {
                background-position: 0 -93px;
            }
        }
        &[type="movies"] {
            &::before {
                background-position: 0 -135px;
            }
        }
        &[type="professionals"] {
            &::before {
                background-position: 0 -185px;
            }
        }
        &[type="actors"] {
            &::before {
                background-image: url("../../assets/images/icons/actors.png");
                background-position: 0 0;
            }
        }
        &[type="crp"] {
            &::before {
                background-image: url("../../assets/images/icons/crp.png");
                background-position: 0 3px;
            }
        }
        &[type="people"] {
            &::before {
                background-position: 0 -234px;
            }
        }
        
        @include hover-focus  {
            background-color: transparent;
            color: $darker;
            &[type="all"] {
                &:before {
                    background-position: 0 -22px;
                }
            }
            &[type="business"] {
                &::before {
                    background-position: 0 -70px;
                }
            }
            &[type="products"] {
                &::before {
                    background-position: 0 -112px;
                }
            }
            &[type="movies"] {
                &::before {
                    background-position: 0 -161px;
                }
            }
            &[type="professionals"] {
                &::before {
                    background-position: 0 -208px;
                }
            }
            &[type="actors"] {
                &::before {
                    background-image: url("../../assets/images/icons/actors.png");
                    background-position: 0 -25px;
                }
            }
            &[type="crp"] {
                &::before {
                    background-image: url("../../assets/images/icons/crp-hover.png");
                    background-position: 0 3px;
                }
            }
            &[type="people"] {
                &::before {
                    background-position: 0 -257px;
                }
            }
        }
    }
}
.wk-sort-dropdown-new {
    right: 0 !important;
    left: auto !important;
    top: 110% !important;
    transform: none !important;
}

.force-down.dropdown-menu{
    transform: translate3d(5px, 35px, 0px)!important;
}
