#appNav {
    font-family: $font-family-base;
    background-color: transparent;
    background: url('../../assets/images/icons/topbar-norep.png') repeat-x center top fixed;
    height: 62px;
    color: $white;
    padding: 0.25rem 0.5rem;
    z-index: 1040;
    > .container-fluid, > .container {
        margin-bottom: 16px;
    }
    .nav-item {
        font-family: inherit;
        &:not(:last-child) {
            margin-right: 1rem;
        }
    }
    .nav-link {
        font-size: 14px;
        color: $white;
        font-weight: $font-weight-bold;
    }
    &.fixed-top {
        + .user-main {
            position: relative;
            top: 70px;
            @include media-breakpoint-up(md) {
                top: 40px;
            }
        }
    }
    .navbar-brand {
        [data-type='text'] {
            display: none;
        }
    }
    .form-control {
        background-color: $white;
        border-color: $white;
        color: $primary;
        font-weight: $font-weight-bold;
        &::placeholder {
            color: $text-dark;
            text-transform: uppercase;
            font-weight: $font-weight-bold;
        }
    }
    .input-group-append {
        .btn {
            border-color: $white;
            @include hover-focus() {
                border-color: $gray-300;
                box-shadow: none;
                background-color: $gray-300;
            }
        }
    }
    .dropdown-menu:not(.dropdown-submenu) {
        top: calc(100% - 30px);
        margin-top: 45px;
    }
    .dropdown-menu {
        background-color: rgba($color: $darker, $alpha: 0.9);
        color: $white;
        border-radius: $border-radius-sm;
        &.profile-dropdown-menu {
            >.dropdown-item, .main-category-list >.dropdown-item {
                border-top: 1px solid $darker;
                white-space: normal;
                margin-bottom: 5px;
                white-space: normal;
                font-size: 13px;
                line-height: 1.2;
            }
            .main-category-list {
                >.dropdown-item {
                    font-size: 16px;
                    margin-bottom: 0;
                    .dropdown-link {
                        padding-top: 0.25rem;
                        padding-bottom: 0.25rem;
                    }
                }
            }
            .main-category-list-link {
                font-size: 16px;
                padding-top: 0.5rem;
                padding-bottom: 0.5rem;
            }
        }
        &.slidable-dropdown {
            .dropdown-item {
                font-size: 13px;
                line-height: 1.2;

                a {
                    color: $white !important;
                    text-decoration: none !important;
                }
                
                @include hover-focus() {
                    a {
                        color: #15c3d4 !important;
                        text-decoration: none !important;
                    }

                    color: #15c3d4 !important;
                    background-color: initial;
                    transform: translateX(10px);
                    transition: all 0.5s;
                }
            }
        }
        &:before {
            content: "";
            position: absolute;
            left: 3px;
            top: -24px;
            border-width: 12px;
            border-style: solid;
            border-color: transparent transparent rgba($color: $darker, $alpha: 0.9) transparent;
        }
        &:after {
            content: "";
            position: absolute;
            left: 0;
            top: -20px;
            width: 100%;
            height: 20px;
        }
        &-right {
            &:before {
                right: 3px;
                left: initial;
            }
        }
        @include plain-hover-focus {
            outline: none;
        }
        &.menu-white {
            background-color: $white;
            margin: 0.125rem 0 0;
            .dropdown-item {
                color: $text-dark;
                @include hover-focus  {
                    background-color: transparent;
                    color: $darker;
                }
            }
            &:after {
                display: none;
            }
        }
        &.no-caret {
            &:before {
                display: none;
            }
        }
        &.menu-item-hover-underline {
            .dropdown-item {
                @include hover-focus() {
                    text-decoration: underline;
                }
            }
        }
    }
    .dropdown-submenu {
        &:before {
            display: none;
        }
        > .dropdown-item {
            color: $primary;
            font-size: 12px;
            padding-left: 0.75rem;
            padding-right: 0.75rem;
            font-family: $font-family-base;
            transition: all 0.5s;
            @include hover-focus {
                background-color: initial;
                text-decoration: underline;
                transform: translateX(10px);
            }
        }
    }
    .dropdown-header {
        color: fade-out($white, 0.2);
    }
    .dropdown-item {
        font-size: 14px;
        color: fade-out($white, .1);
        @include hover-focus {
            color: $white;
            background-color: $darker;
        }
        @include plain-hover-focus {
            outline: none;
        }
    }
    .dropdown-link {
        color: inherit;
        text-decoration: none;
        display: block;
        font-family: $font-family-base;
    }
    
    .dropdown-list {
        width: 350px;
         .dropdown-item {
            white-space: normal;
        }
        .nav-tabs {
            border-bottom: 0;
            .nav-item {
                margin-right: 0;
            }
            .nav-link {
                .notification-count {
                    position: absolute;
                    top: 0;
                    right: 0;
                    margin: 2px;
                }
                &.active {
                    background-color: rgba($color: $black, $alpha: .6);
                }
            }
        }
    }
    .dropdown-toggle::after {
        margin-left: 0.5rem;
    }

    [data-type="notif"] {
        font-family: $font-family-base;
        position: relative;
        padding: 0.5rem 1rem;
        .img-circle {
            width: 35px;
            height: 35px;
            border-radius: 50%;
            overflow: hidden;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center center;
            }
        }
        a {
            color: $white;
        }
    }
    
    .has-caret {
        &::after {
            display: inline-block;
            margin-left: 0.5rem;
            vertical-align: 0.255em;
            content: "";
            border-top: 0.3em solid;
            border-right: 0.3em solid transparent;
            border-bottom: 0;
            border-left: 0.3em solid transparent;
        }
        &._custom {
            &::after {
                border: none;
                background: url("../../assets/images/icons/blue-down-arrow.png") right no-repeat;
                width: 8px;
                height: 6px;
                vertical-align: middle;
            }
        }
    }

    .location-holder {
        font-size: 14px;
        color: fade-out($color: $white, $amount: 0.5);
        font-weight: $font-weight-bold;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    /* @include media-breakpoint-down(sm) {
        .dropdown, .col {
            position: static;
        }
        .dropdown-menu {
            // border: 1px solid white;
            background-color: $white;
            color: $dark;
            width: calc(100% - 10px);
            left: 5px;
            right: initial;
            box-shadow: $box-shadow-lg;
            &::before {
                display: none;
            }
        }
        
        .dropdown-item {
            color: fade-out($black, 0.1);
        }
        .dropdown-header {
            color: fade-out($black, 0.1);
        }
    } */
}

@include media-breakpoint-down(sm) {
    #appNav {
        padding-top: 0;
        padding-bottom: 0;
        height: unset;
        background-image: unset;
        background-color: $darker;
        padding-left: 0;
        padding-right: 0;
        > .container-fluid, > .container {
            position: relative;
            margin-bottom: 0;
            padding-left: 0;
            padding-right: 0;
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
            .row {
                margin-left: 0;
                margin-right: 0;
            }
        }
        .nav-item {
            &:not(:last-child) {
                margin-right: 0.5rem;
            }
        }
        
        .dropdown, [class*='col'] {
            position: static !important;
        }
        .dropdown-menu {
            width: 100%;
            height: calc(100vh - 47px);
            overflow: auto;
            transform: translateX(-100%);
            display: block;
            margin: 0 !important;
            border: none;
            border-radius: 0;
            transition: all 0.2s ease-in;
            background-color: rgba($color: $dark, $alpha: .1);
            
            &.dropdown-menu-right {
                transform: translateX(100%);
            }
            &:before {
                display: none;
            }
            &.show {
                transform: translateX(0);
            }
        }
        
        .dropdown-submenu {
            transform: none !important;
            display: none !important;
            left: 0;
            top: 100%;
            height: unset !important;
            background-color: $darker !important;
            > .dropdown-item {
                padding: 0.5rem 2rem;
                font-size: 14px;
            }
        }
        .dropdown-item {
            padding: 1rem 2rem;
            font-size: 1rem;
            @include hover-focus {
                &.has-submenu {
                    &::after {
                        transform: rotate(90deg);
                    }
                }
                > .dropdown-submenu {
                    display: block !important;
                }
            }
        }
        .form-control {
            background-color: lighten($color: $dark, $amount: 4%);
            border-color:  lighten($color: $dark, $amount: 4%);
            color: $white;
            &::placeholder {
                color: fade-out($color: $white, $amount: 0.3);
            }
        }
        .mobile-input {
            position: relative;
            input {
                padding-right: 24px;
            }
            &:after {
                content: '\f002';
                top: 8px;
                right: 8px;
                position: absolute;
                display: inline-block;
                font-family: "Font Awesome 5 Free";
                font-weight: 900;
                font-size: 14px;
                -moz-osx-font-smoothing: grayscale;
                -webkit-font-smoothing: antialiased;
                font-style: normal;
                font-variant: normal;
                text-rendering: auto;
                line-height: 1;
                color: fade-out($color: $white, $amount: 0.3);
            }
        }
    }
}

@include media-breakpoint-between(sm, md) {
    #appNav {
        .main-search-holder {
            max-width: 60px;
        }
    }
}

@include media-breakpoint-up(lg) {
    #appNav {
        .navbar-brand {
            [data-type='text'] {
                display: none;
            }
            &:hover {
                [data-type='text'] {
                    display: block;
                }
                [data-type='img'] {
                    display: none;
                }
            }
        }
    }
}

.search-mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
}
.goback-link {
    background: #879ab5;
    color: #0e0e0e;
    padding: 5px;
    text-align: left;
    display: inline-block;
    max-width: 350px;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
    white-space: nowrap;
    font: 13px/1 $font-family-base, Arial;
    @include hover-focus {
        text-decoration: none;
        color: #0e0e0e;
    }
}