// Styling for the masthead

header.masthead {
    position: relative;
    padding-top: calc(3rem + #{$navbar-height});
    padding-bottom: 3rem;
  // replace the masthead.jpg image in the /assets/img directory to change the image
  // if the image name change, make sure to update the url path below!
  background-image: url("../../assets/images/masthead-video-bg.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  .masthead-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    max-height: unset;
  }
  h1 {
    font-size: 2rem;
    // text-shadow: 1px 1px 4px $black;
  }
  h2 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
  .masthead-container {
    position: relative;
    z-index: 1029;
    padding-top: 2rem;
  }

  .form-control {
    background-color: #f6f6f6;
    border-color: #f6f6f6;
    font-weight: $font-weight-bold;
    color: $dark;
    &:focus {
        background-color: $white;
        &::placeholder {
            opacity: 0.6;
        }
    }
    &::placeholder {
        color: $dark;
    }
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color: #000000, $alpha: 0.3);
    z-index: 1;
  }

  
  @include media-breakpoint-up(lg) {
    min-height: 80vh;
    h1 {
      font-size: 3rem;
    }
    h2 {
      font-size: 2rem;
      margin-bottom: 0.5rem;
    }
  }
  @media screen and (max-height:500px){
    min-height: 100vh;
  }
}