.badge-empty {
    width: 1.25rem;
    height: 1.25rem;
    display: inline-block !important;
}

.badge {
    &.fixed-width {
        width: 1.75rem;
        height: 1.75rem;
        line-height: 1.75rem;
        padding: 0;
    }
    &.badge-counter {
        padding: 5px;
        color: rgb(50, 82, 113);
        background: #00a0b1;
        font: 12px / 10px arial;
        height: 20px;
        border-radius: 10px;
    }
}

.hide-member-card-content {
    background: #d5d6e5;
    padding: 5px;
    margin: 4px;
    font: 12px / 1.5 arial;
    color: $text-dark;
    min-width: 70px;
    .hide-member-card-close {
        // float: right;
        padding: 0px 5px 0px 7px;
        color: $text-dark;
        border-left: 1px solid $text-dark;
        font: 14px / 1.5 arial;
        margin-left: 20px;
        display: inline-block;
        cursor: pointer;
    }
}