.table-chart {
    > thead, > tbody {
        border: 0;
        > tr {
            border: 0;
            > th, > td {
                border-color: $white;
                border-width: 2px;
                border-style: solid;
            }
        }
    }
}
.table {
    &._cols-4 {
        > thead, > tbody {
            > tr {
                > th, > td {
                    width: 25%;
                }
            }
        }         
    }
    &._cols-3 {
        > thead, > tbody {
            > tr {
                > th, > td {
                    width: 33.33%;
                }
            }
        }         
    }
}

.compare-product-list .table.products-list {
    td {
        border-right: 1px solid #d5dce5;
        padding: 10px;
        text-align: center;
        word-break: break-all;
        width: 300px;
        &:last-child {
            border-right: 0;
        }
    }
}

.products-title, .products-title th{
    background: #78838c;
    color: #fff;
    padding: 0px;
    /*height: 58px;*/
    border-bottom: 0;
}
.compare-product-list table.products-list tr {
    // border-bottom: 1px solid #d5dce5;
}
.compare-product-list table.products-list th {
    border-right: 1px solid #d5dce5;
    border-bottom: 1px solid #d5dce5;
    padding: 15px;
    text-align: left;
    vertical-align: top;
    font-family: 'museo-sans', Arial;
    font-weight:900;
    color: #879ab5;
    font-size: 13px;
}
.compare-product-list table.products-list td {
    border-right: 1px solid #d5dce5;
    border-bottom: 1px solid #d5dce5;
    // padding: 5px;
    text-align: center;
    word-break: break-all;
    
}
.product-title {
    color: #325271;
}
.compare-product-list {
    background: #fff;
    /* padding: 9px; */
    margin: 5px 0;
}
.product-title a{
	font-family:arial, helvetica; 
	font-size:13px; 
	color: #428bca;
    text-decoration: none;
    text-align: center;
    word-break: break-all;
}
  
.compare-product-list table.products-list, .product-price span{
	text-align:center;
	color:#879ab5;
	 font-family:arial, helvetica, clean, sans-serif;
    font-size:13px;
}

.compare-product-list table.products-list td span,.compare-product-list table.products-list td a{
	font-family:Arial, Helvetica, sans-serif;
	font-size:13px;
	word-break: initial;
	color: #879ab5;
}  
.compare-product-list table.products-list tr.product-title th {color:#fff;}

.product-img {
    width: 100%;
    height: auto;
    margin: auto;
    // background: #dcdcdc;
    text-align: center;
}
.product-img img{max-width:100%;height:auto;}
.compare-title {
    background: #fff;
    width: 100%;
    padding: 10px;
    color: #325271;
    border-bottom: 0;
    margin:0;
}
.compare-product-list table thead .products-title td a,
.compare-product-list table thead .products-title th,
.compare-product-list table .products-title td a,
.compare-product-list table .products-title th
{color:#fff;}

.male-female-table td{
    border: 2px solid #d1bb78; 
    img{
        width: 60px;
        height: auto;
    }   
}

.male-female-table-tertiary td{
    border: 2px solid white; 
    img{
        width: 60px;
        height: auto;
    }   
}

@media screen and (max-width: 768px) {
  .compare-product-list .table.products-list {
      tr {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-around;
    }

    td, th {
        display: block;
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    }

    th:first-child,
    td:first-child {
        width: 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
    
    tbody {
        display: block;
        overflow: auto;
        max-height: 400px;
    }
  }
}