// Base styling for the navbar
#siteNav {
  box-shadow: $box-shadow;
  background-color: $dark;
  transition: background-color 0.2s ease;
  .navbar-brand {
    font-family: $font-family-sans-serif;
    font-weight: $font-weight-lighter;
    // color: $gray-900;
  }
  .navbar-nav {
    .nav-item {
      .nav-link {
        color: fade-out($white, .3);
        font-family: $font-family-sans-serif;
        font-weight: $font-weight-lighter;
        font-size: 1rem;
        padding: 0.75rem 0;
        &:hover,
        &:active {
          color: $white;
        }
        &.active {
          color: $white !important;
        }
      }
    }
  }
  @include media-breakpoint-up(md) {
    // Base styling for the navbar - screen sizes greater than the large breakpoint
    box-shadow: none;
    background-color: transparent;
    .navbar-brand {
      color: fade-out($white, .1);
      // color: $white;
      // &:hover {
      //   color: $white;
      // }
    }
    .navbar-nav {
      .nav-item {
        .nav-link {
          color: fade-out($white, .1);
          padding: 0 1rem;
          &:hover {
            color: $white;
          }
        }
        &:last-child {
          .nav-link {
            padding-right: 0;
          }
        }
      }
    }
    // Navbar styling applied when the page is scrolled
    &.navbar-scrolled, &.colored {
      box-shadow: $box-shadow;
      background-color: fade-out($color: $dark, $amount: 0.1);
      backdrop-filter: blur(10px);
      color: $white;
      // .navbar-brand {
      //   color: $gray-900;
      //   &:hover {
      //     color: $primary;
      //   }
      // }
      // .navbar-nav {
      //   .nav-item {
      //     .nav-link {
      //       color: $gray-900;
      //       &:hover {
      //         color: $primary;
      //       }
      //     }
      //   }
      // }
      .nav-link-header {
        @include hover-focus {
          color: $primary;
        }
      }
    }
  }
}
.nav-link-header {
  font-size: 14px;
  color: $white;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  text-transform: uppercase;
  text-decoration: none;

  @include hover-focus {
    text-decoration: none;
    color: $royal-blue;
  }
}