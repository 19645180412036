/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.form-group {
  label {
    font-weight: $font-weight-semi-bold;
    // color: $secondary;
  }
  @each $color, $value in $theme-colors {
    .#{$color} {
      label {
        color: $value;
      }
    }
  }
}

.is-empty {
  &:hover {
    font-style: italic;
    cursor: pointer;
  }
}

/* .form-control {
  font-size: 14px;
}
 */

select.form-control {
  transition: none;
  &:not(:disabled):hover {
    cursor: pointer;
  }
  &:focus {
    option {
      background-color: $white !important;
    }
  }
}
// should be used when background is light. use .standalone-light below instead
.standalone {
  border-color: $gray-400;
  background-color: $white;
  color: $gray-700;
  // font-weight: bold;
  transition: all 0.2s ease-out;
}
.form-control {
  @extend .standalone;
  &:focus {
    box-shadow: none;
    border-color: $secondary;
    background-color: $white;
    outline: 0;
  }
}

.input-group-text {
  @extend .standalone;
}

.input-group {
  > .form-control:first-child {
    border-right: 0;
  }
  > .form-control:last-child {
    border-left: 0;
  }

  .input-group-append, .input-group-prepend {
    .btn {
      @extend .standalone;
    }
  }
  
  &:focus-within {
    .input-group-text {
      border-color: $input-focus-border-color;
      &.primary {
        border-color: $primary;
      }
    }
    
    .input-group-append, .input-group-prepend {
      .btn {
        border-color: $secondary;
      }
    }
  }
}

// below input should not be used when background is white
.standalone-light {
  border-color: $white;
  background-color: $white;
  color: $gray-700;
  transition: all 0.2s ease-out;
}

.form-control.light {
  @extend .standalone-light;
  &:hover {
    background-color: $white;
  }
  &:focus {
    background-color: $white;
    border-color: $input-focus-border-color-light;
    outline: 0;
    box-shadow: none;
  }
}

.form-control.transparent {
  color: #8791b5;
  border-color: $tertiary;
  background-color: transparent;
  font-family: $font-family-base;
  &::placeholder {
    color: inherit;
  }
}

.bg-white {
  .form-control.transparent {
    background-color: $white;
  }
}

.bg-dark {
  .form-control.transparent{
    color: #94a6bf;
    border-color: #94a6bf;
    background-color: $dark;
  }
}

.input-group-text.light {
  @extend .standalone-light;
}
.input-group {
  &:focus-within {
    .input-group-text.light {
      border-color: $input-focus-border-color-light;
    }
  }
}

.search-label {
  display: block;
}

.form-control.primary {
  &:focus {
    border-color: $primary;
  }
}

.main-post-formgroup {
  .input-label-block {
    position: relative;
    margin: 30px 0px;
    &::before {
      content: '';
      top: -14px;
      left: 0;
      background-image: url("../../assets/images/icons/q-img.jpg");
    }
    &::after {
      content: '';
      right: 0;
      bottom: -14px;
      background-image: url("../../assets/images/icons/q-img1.jpg");
    }
    &::before, &::after {
      width: 45px;
      height: 30px;
      position: absolute;
      display: inline-block;
      line-height: 1;
      z-index: 1;
    }
  }
  label {
    display: block;
    cursor: text;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
  }
  .form-control {
    min-height: 100px;
    margin-bottom: 0;
    padding: 20px;
    border-color: $primary;
    color: $primary;
    font-size: 14px;
    line-height: 16.8px;
    &::placeholder {
      color: $primary;
    }
    &:focus, &:not(:placeholder-shown) {
      + label {
        display: none;
      }
    }
  }
  .btn {
    font-weight: $font-weight-semi-bold;
  }
}

.bg-white {
  .main-post-formgroup {
    .input-label-block {
      &::before {
        background-image: url("../../assets/images/icons/mv-quote.png");
        background-color: $white;
        left: -10px;
      }
      &::after {
        background-image: url("../../assets/images/icons/mv-quote-flip.png");
        background-color: $white;
        right: -10px;
      }
    }
  }
}

%floating-label {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  margin-bottom: 0; /* Override default `<label>` margin */
  line-height: 1.5;
  color: #495057;
  pointer-events: none;
  cursor: text; /* Match the input under the label */
  border: 1px solid transparent;
  border-radius: .25rem;
  transition: all .1s ease-in-out;
}

.checked-bold:checked {
  ~ span {
    font-weight: $font-weight-bold;
  }
}

// Multi Select
.multi-select {
  --rmsc-primary: var(--light) !important;
  --rmsc-gray: #000 !important;
  .dropdown-container {
    position: relative;
    @extend .form-control;
    @extend select.form-control;
    $bg-size: 1rem;
    $bg-offset: .5rem;
    transition: none;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right $bg-offset center;
    background-size: $bg-size;
    padding-right: 2 * $bg-offset + $bg-size;
    appearance: none;
    // background-image: none !important;
    &[aria-expanded="true"] {
      @extend .form-control:focus;
    }
  }
  .dropdown-heading {
    height: initial;
    padding: 0;
    cursor: pointer;
    position: static;
    @extend .stretched-link;
    &:after {
      z-index: 0 !important;
    }
  }
  .dropdown-heading-dropdown-arrow {
    display: none;
  }
  .dropdown-content {
    left: 0;
    margin-bottom: 2rem;
  }
  .panel-content {
    // padding: 10px;
    .go2885254090 {
      @extend .position-sticky;
      background-color: $white;
      top: 0;
      z-index: 1;
    }
  }
  .select-item {
    margin: 0;
    padding-left: 2.5rem;
    font-family: $font-family-base;
    @extend .custom-control;
    @extend .custom-checkbox;
    font-weight: $font-weight-normal;
    input[type="checkbox"] {
      @extend .custom-control-input;
    }
    span {
      @extend .custom-control-label;
    }
  }

  &.has-label {
    .dropdown-container {
      height: initial;
      padding-top: 1.25rem;
      padding-bottom: .25rem;
      &:before {
        content: attr(aria-labelledby);
        @extend %floating-label;
        height: 3.125rem;
        padding: .25rem .75rem;
        font-size: 12px;
        color: #777;
      }
    }
  }

  &.bg-white {
    .dropdown-container {
      background-color: $white;
    }
  }
}

.type-file-block {
  position: relative;
  label {
    min-height: 360px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 3rem 2rem;
    text-align: center;
    font-family: $headings-font-family;
    font-size: 1.5rem;
    // text-transform: uppercase;
    // border: 2px dashed gray;
    color: $tertiary;
    cursor: pointer;
    transition: all 0.2s;
    .select-text {
      background-color: lighten($color: $tertiary, $amount: 10%);
      padding: 4px 10px;
    }
    &:hover {
      background-color: $light;
      border-color: $primary;
    }
    .type-bg {
      background-image: url("../../assets/images/spritemap.png");
      background-repeat: no-repeat;
      background-position: 0 0;
      width: 428px;
      height: 123px;
    }
  }
  input {
    position: absolute;
    opacity: 0;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    cursor: pointer;
    &:hover {
      + label {
        background-color: $light;
        border-color: $primary;
      }
    }
  }
}

.form-control.bg-search {
  background-image: url('../../assets/images/icons/search.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-size: 16px;
  background-position: center right 5px;
  padding-right: 30px;
  &-secondary {
    background-image: url('../../assets/images/icons/search-icon-buyers.png');
  }
}

.dashed-spread-input {
  .form-control {
    border: 1px dashed grey;
    min-height: 200px;
    text-align: center;
  }
}

.fg-mb-0 {
  .form-group {
    margin-bottom: 0;
  }
}

// jfsaru-Switch
.css-jfsaru-Switch {
  background-color: #15c3d4 !important;
}
.css-toggle-switch input[value="1"] + span {
  background-color: #15c3d4 !important;
}


/*Css For Social site account*/
.onoffswitch {
    position: relative; width: 90px;
    -webkit-user-select:none; -moz-user-select:none; -ms-user-select: none;
    float:right;
}
.onoffswitch-checkbox {
    display: none;
}
.onoffswitch-label {
    display: block; overflow: hidden; cursor: pointer;
     border-radius: 20px;
    margin-bottom:0;
}
.onoffswitch-inner {
    display: block; width: 200%; margin-left: -100%;
    transition: margin 0.3s ease-in 0s;
}

.onoffswitch-inner:before, .onoffswitch-inner:after {
    display: block; float: left; width: 50%; height: 23px; padding: 0; line-height: 23px;
    font-size: 14px; color: white; font-family: museo_sans900, Arial, sans-serif; font-weight: bold;
    box-sizing: border-box;
}
.onoffswitch-inner:before {
    content: "ON";
    padding-left: 10px;
    background-color: #15c3d4; color: #FFFFFF;
}
.onoffswitch-inner:after {
    content: "OFF";
    padding-right: 10px;
    background-color: #EEEEEE; color: #999999;
    text-align: right;
}
.onoffswitch-switch {
    display: block; width: 18px; margin: 6px;
    background: #FFFFFF;
    position: absolute; top: 0; bottom: 0;
    right: 56px;
    border-radius: 20px;
    transition: all 0.3s ease-in 0s; 
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
    margin-left: 0;
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
    right: 0px; 
}

.form-control.comment-form-control {
  border-color: $primary;
  color: $primary;
  background-color: transparent;
  font-size: 14px;
  font-weight: $font-weight-bold;
  &::placeholder {
    color: inherit;
    font-weight: $font-weight-normal;
  }
}

.form-control.glowy-form-control {
  background-color: transparent;
  // border-color: $gray-300;
  color: $dark;
  font-weight: $font-weight-bold;
  &::placeholder {
    color: #333;
    font-weight: $font-weight-normal;
    font-family: $font-family-alt;
  }
  &:focus {
    // border-color: $text-dark;
    border-color: #66afe9;
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102, 175, 233, .6) !important;
  }
}
.form-control.glowy-form-control-alt {
  font-family: $font-family-base;
  color: $black;
  &::placeholder {
    color: #D5D6E5;
    opacity: 1;
  }
  &:focus {
    // border-color: $text-dark;
    border-color: #66afe9;
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102, 175, 233, .6) !important;
  }
}
.create-review-search-input {
  border-color: $tertiary;
  color: $dark;
  &::placeholder {
    color: $gray-400;
    font-weight: $font-weight-bold;
  }
}
.create-review-place-input {
  // border-color: $tertiary;
  color: $dark;
  &::placeholder {
    color: $tertiary;
    font-weight: $font-weight-bold;
  }
  &:focus {
    border-color: $dark;
  }
}

.login-form-group {
  .form-control {
    color: $text-dark;
    font-size: 14px;
    border-color: #d5d6e5;
    height: 40px;
    font-weight: $font-weight-bold;
    &:focus {
      border-color: $primary;
    }
    &::-webkit-input-placeholder {
      color: #D5D6E5;
      opacity: 1;
      font-weight: $font-weight-bold;
    }
  }
}

.add-comment-input {
  border: 1px solid $primary;
  color: $primary;
  padding: 4px 10px;
  font-size: 16px;
  &:focus {
    color: $primary;
  }
  &::placeholder {
    color: #D5D6E5;
    opacity: 1;
    font-size: 14px;
  }
}
.search-block-input {
  border: 1px solid #e4e3da;
    border-top: 1px solid #afaea8;
    color: #325271;
    // font-family: 'museo_sans500';
    padding: 0 30px 0 6px;
    font-size: 14px;
    height: 30px;
    line-height: 30px;
    width: 150px;
    background-color: #E8E7DF;
    // background: linear-gradient(#E8E7DF, #edebe3);
    &:focus {
      background-color: #E8E7DF;
      // background: linear-gradient(#E8E7DF, #edebe3);
      border: 1px solid #e4e3da;
    border-top: 1px solid #afaea8;
    }
}

.ph-grey {
  &::placeholder {
    color: #d5dce5;
  }
}
.ph-primary {
  &::placeholder {
    color: $primary;
  }
}
.ph-secondary {
  &::placeholder {
    color: $secondary;
  }
}
.ph-bold {
  &::placeholder {
    font-weight: $font-weight-bold !important;
  }
}
.ph-normal {
  &::placeholder {
    font-weight: $font-weight-normal !important;
  }
}
.ph-brown {
  &::placeholder {
    color: $secondary-dark;
  }
}
.ph-14 {
  &::placeholder {
    font-size: 14px;
  }
}
.ph-text-form {
  &::placeholder {
    color: #333;
    font-family: arial;
    font-weight: normal;
  }
}

.form-control.category-select {
    background: #fff;
    border: 1px solid #ddd;
    font-family: $font-family-base;
    font-weight: $font-weight-bold;
    margin: 5px 5px 0 0;
    color: $tertiary;
    height: initial;
    &:focus {
      border-color: #ddd;
    }
    option {
      font-weight: $font-weight-bold;
    }
}

.form-control.select-type-dark {
  color: $text-dark;
  font-weight: $font-weight-bold;
  option {
    font-weight: $font-weight-bold;
  }
}

.embed-link-form-control {
  border: 2px dashed rgb(220, 220, 220);
  margin: 4px 5px;
  min-height: 362px;
  &::placeholder {
    line-height: 1.8;
    color: rgb(153, 153, 153);
  }
  &:focus {
    border-color: rgb(220, 220, 220);
    color: $primary;
  }
}

.login-wrap {
  .form-control {
    border-radius: 0;
    box-shadow: none;
    color: #325271;
    font-size: 15px;
    font-weight: $font-weight-bold;
    &::placeholder {
      font-size: 16px;
      color: #D5D6E5;
    }
  }
  select.form-control {
    option {
      color: inherit;
      font-weight: inherit;
    }
  }
}

.caption-input {
  color: $primary;
  font-weight: $font-weight-bold;
  &::placeholder {
    color: rgb(117, 117, 117);
    font-weight: $font-weight-normal;
  }
  &:focus {
    border-color: $gray-300;
    color: $primary;
  }
}
.form-group-primary {
  .col-form-label {
    color: $primary;
    font-size: 16px;
    font-weight: bold;
    font-family: $font-family-alt;
    text-align: left;
    align-self: flex-start;
    @include media-breakpoint-up(md) {
      text-align: right;
    }
  }
  .form-control {
    font-weight: $font-weight-bold;
    color: $text-dark;
    font-size: 14px;
    &::placeholder {
      font-family: $font-family-alt;
      color: $gray-400;
    }
    &:focus {
      border-color: $primary;
    }
  }
}
.required-mark::after {
  display: inline-block;
  margin: 0 5px 0 0;
  content: "*";
  color: #c51134;
  width: 10px;
  font-family: $font-family-base;
  font-weight: $font-weight-bold;
}
.crp-textarea-wrapper {
  position: relative;
  padding: 10px;
  margin-bottom: 1rem;
  &::before {
    width: 45px;
    height: 30px;
    content: '';
    display: inline-block;
    position: absolute;
    background: url('../../assets/images/sprite.png') -83px -56px no-repeat #fff;
    left: -10px;
    top: -8px;
  }
  &::after {
    width: 45px;
    height: 30px;
    content: '';
    display: inline-block;
    position: absolute;
    background: url('../../assets/images/sprite.png') -146px -58px no-repeat #fff;
    right: -18px;
    bottom: -5px;
  }
  textarea {
    padding: 16px;
    font-size: 14px;
    color: $darker;
    font-family: $font-family-alt;
    &:focus, &.primary:focus {
      border-color: $input-border-color;
    }
  }
}
.friends-select {
  appearance: none;
  background: #D4D6E5;
  padding: 5px;
  color: #325271;
  font-weight: bold;
  // option {
  //   background: #8e9fb9 !important;
  //   color: #fff !important;
  //   font: 14px/1.5 'arial';
  //   font-weight: bold;
  // }
  @include hover-focus() {
    border-color: #D4D6E5;
  }
}