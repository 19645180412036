/*AVATARS*/

.user-info.avatars{
    display:block;
	position:relative;
	width: unset;
	height: unset;
	padding: unset;
}

/*.avatars.indium .circle,
.avatars.silver .circle,
.avatars.rhenium .circle{
	margin: 0 auto;
}*/

.avatars a.indium {
	border:3px solid #325271;
	border-radius: 100%;
    width: 60px;
    height: 60px;
    // display: inline-block;
    padding: 2px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: 7px;
}

.avatars a.silver {
	border:5px double #325271;
	border-radius: 100%;
    width: 60px;
    height:60px;
    // display: inline-block;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: 7px;
   }

.avatars a.gold {
	background:url('../../assets/images/listing/gold.png') no-repeat center;
	margin: 0 0;
    // display:inline-block;
	display: flex;
	justify-content: center;
	align-items: center;
    width:70px;
    height:78px;
    padding:8px;
	}

.avatars a.osmium {
	background:url('../../assets/images/listing/osmium.png') no-repeat center;
	margin: 0 0;
    // display:inline-block;
    width:70px;
    height:67px;
    padding:10px;
	display: flex;
	justify-content: center;
	align-items: center;
	}

.avatars a.iridum {
	background:url('../../assets/images/listing/iridum.png') no-repeat center;
	margin: 0 0;
    // display:inline-block;
    width:70px;
    height:67px;
    padding:10px;
	display: flex;
	justify-content: center;
	align-items: center;
	}


.avatars a.rhenium {
	background:url('../../assets/images/listing/rhenium.png') no-repeat center;
	margin: 0 0;
    // display:inline-block;
    width: 80px;
    height: 80px;
    padding: 15px;
	display: flex;
	justify-content: center;
	align-items: center;
	}


.avatars a.platinum {
	background:url('../../assets/images/listing/platinum.png') no-repeat center;
	margin: 0 0;
    // display:inline-block;
    width:70px;
    height:73px;
    padding:10px;
	display: flex;
	justify-content: center;
	align-items: center;
	}

.avatars a.rhodium {
	background:url('../../assets/images/listing/rhodium.png') no-repeat center;
	margin: 0 0;
    // display:inline-block;
    width:70px;
    height:73px;
    padding:10px;
	display: flex;
	justify-content: center;
	align-items: center;
	}

.avatars a.ruthenium {
	background:url('../../assets/images/listing/ruthenium.png') no-repeat center;
	margin: 0 0;
    // display:inline-block;
    width:70px;
    height:78px;
    padding: 13px 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	}

.avatars a.palladium {	
	background:url('../../assets/images/listing/palladium.png') no-repeat center;
	margin: 0 0;
    // display:inline-block;
    width:80px;
    height:78px;
    padding: 13px;
	display: flex;
	justify-content: center;
	align-items: center;
	}


.avatars .planets-right{
	width:25px;
	height:24px;
	border:2px solid #333;
	border-radius: 100%;
	position: absolute;
    top:0;
    left:-2px;
    background-color: white;
}


.avatars .gems {
	width:25px;
	height:24px;
	border:2px solid #333;
	border-radius: 100%;
	position: absolute;
    top:0;
    left:50px;
    background-color: white;
}



.review-wrapper .superuser-block .user-info.avatars  span.planets-right img{
    width: 100%;
    margin: 0;
    height:auto;
}

.review-wrapper .superuser-block .user-info.avatars span.gems img{
    width: 100%;
    margin: 0;
    height:auto;
}

.avatars .gems small.count
{
	background: #333;
    width: 16px;
    height: 16px;
    border-radius: 100%;
    // display: inline-block;
	display: flex;
	justify-content: center;
	align-items: center;
    position: absolute;
    left: 11px;
    bottom: 10px;
    border: 1px solid white;
    font-size: 12px;
    font-family: arial;
    padding:0 3px;
    text-shadow: none;
    color:#fff;
}

.avatars .planets-right small.count
{
	background: #333;
    width: 16px;
    height: 16px;
    border-radius: 100%;
    // display: inline-block;
	display: flex;
	justify-content: center;
	align-items: center;
    position: absolute;
    left: 11px;
    bottom: 10px;
    border: 1px solid white;
    font-size: 12px;
    font-family: arial;
    padding:0 3px;
    text-shadow: none;
    color:#fff;
}



.avatars.silver   .circle {
	border:8px double #325271;
	border-radius: 100%;}


.circle span.joining-year-profile,
.circle:hover span.joining-year-profile{
	display: inline-block;
    line-height: 20px;
    margin: 0 auto;
    width: 160px;
}


.avatars a .detail-rating2,
.avatars a .detail-rating2:before,
.avatars span.gems .detail-rating,
.avatars span.gems .detail-rating:before
{
display:none;

}

/************hover on circle************/

.avatars a:hover .detail-rating2{
    position: absolute;
	left: -75px;
    top:75px;
	width: 250px;
	height:155px;
	border: 2px solid #d4c084;
	padding:5px;
	background-color:#fff;
	-webkit-transition: all 0.5s ease-in-out;
	display: block;
	z-index: 1;

}

.avatars a:hover .detail-rating2:before {
	content: "";
	top: -18px;
    left: 93px;
	display:block;
	height: 27px;
	position: absolute;
	width: 26px;
	background: url(../../assets/images/pop-down-arrow.png) no-repeat;
	-webkit-transition: all 0.2s ease-in-out;
	z-index: 1;

}

.avatars a:hover .detail-rating2 .avatars-listing li{
	display: inline-block;
    width: 40px;
    margin:10px 2px;
    font-size: 8px;
    height: 40px;
    text-align:center;
    color: #325271;
}

.avatars a:hover .detail-rating2 .avatars-listing li img{
	border-radius:0;
	width:auto;
	height:auto;
}

.avatars-listing img, .planets-right img, .gems img {
	max-width: 100%;
}

/***********hover on gems************/

.avatars span.gems .detail-rating-gems {
    display:none;
}

.avatars span.gems:hover .detail-rating-gems {
	position: absolute;
	left: 40px;
	top: 50%;
	transform: translateY(-50%);
	width: 650px;
	// height: 160px;
	border: 2px solid #d4c084;
	padding: 10px;
	background-color:#fff;
	-webkit-transition: all 0.5s ease-in-out;
	display:block;
	z-index: 9;

}

.avatars span.gems:hover .detail-rating-gems:before {
	content: "";
	top:50%;
	left: -17px;
	height: 26px;
	position: absolute;
	width: 27px;
	transform: translateY(-50%);
	background: url(../../assets/images/pop-up-arrow.png) no-repeat;
	-webkit-transition: all 0.5s ease-in-out;
	display:block;
}

.avatars .detail-rating-gems .avatars-listing li{
	display: inline-block;
	width: 54px;
    // width:54px;
    margin: 10px 0;
    font-size: 8px;
    // height:35px;

}

.review-wrapper .superuser-block .avatars .detail-rating-gems .avatars-listing li img{
	border-radius: 0;
	width: 100%;
    height: auto;
}

/***********hover on planets-right************/

.avatars span.planets-right .detail-rating-planets {
    display:none;
}

.avatars span.planets-right:hover .detail-rating-planets {
	position: absolute;
	right: 35px;
    bottom: -62px;
	width: 470px;
	height: 160px;
	border: 2px solid #d4c084;
	padding: 10px;
	background-color:#fff;
	-webkit-transition: all 0.5s ease-in-out;
	display:block;

}

.avatars span.planets-right:hover .detail-rating-planets:after {
	content: "";
	bottom: 55px;
	right: -18px;
	height: 26px;
	position: absolute;
	width: 27px;
	background: url(../../assets/images/pop-left.png) no-repeat;
	-webkit-transition: all 0.5s ease-in-out;
	display:block;
}

.avatars .detail-rating-planets .avatars-listing li{
	display: inline-block;
    width:40px;
    margin:10px 0 0 0;
    font-size: 8px;
}

.review-wrapper .superuser-block .avatars .detail-rating-planets .avatars-listing li img{
	border-radius: 0;
	width: 100%;
    height: auto;
}

/********hover on planets*********/

.superuser-block span.planets.detail-rating-planets.accordion-content.charts,
 .superuser-block span.planets.detail-rating-planets.accordion-content.charts:before,
.superuser-block span.planets.detail-rating-planets.accordion-content.charts figure.distribution-rate > figcaption {
  display:none;
  }
  
  .superuser-block span.planets .distribution-rate {
  	border:0;
  	display:none;
  }
  
   .superuser-block span.planets:hover .distribution-rate {
  	
  	display:block;
  }

.superuser-block span.planets:hover .detail-rating-planets.accordion-content.charts {
	position: absolute;
	left: -75px;
    top:5px;
	width: 270px;
	height:auto;
	border: 2px solid #d4c084;
	padding:5px;
	background-color: rgb(255,255,255);
	display: block;
	z-index: 1;

}

 .superuser-block span.planets:hover .detail-rating-planets:before {
	content: "";
	top: -18px;
    left: 93px;
	display:block;
	height: 27px;
	position: absolute;
	width: 26px;
	background: url(../../assets/images/pop-down-arrow.png) no-repeat;
	-webkit-transition: all 0.2s ease-in-out;
	z-index: 1;
}

.superuser-block span.planets {position:relative;}

.superuser-block span.planets a{color:#325271;}


.user-info.avatars  span.gems a.small-gems-img img{
    width: 9px;
    margin: 1px 6px;
}
.awards_list{
    margin: 10px 0 0 0;
}
.awards_list li{
    display: inline-block;
    height: 40px;
    margin: 0 5px 0 0;
    vertical-align: top;
    width: 15px;
    float: left;
}

[class^="icon-"] {
    background-image: url("../../assets/images/sprite.png");
    background-position: 0 0;
    background-repeat: no-repeat;
    display: inline-block;
    height: 12px;
    line-height: 14px;
    margin-top: 1px;
    vertical-align: text-top;
    width: 13px;
}

.verify-user .icon-v {
    background-position: -300px -57px;
    height: 13px;
}
.icon-comma-left {
	height: 28px;
    width: 35px;
	background-position: -95px -61px;
	background-size: auto;
	vertical-align: text-bottom;
	margin: 0 4px 0 0;
	&._quote {
		background-image: url("../../assets/images/icons/quote1.png");
		background-position: 0 0;
	}
	&._sm {
		transform: scale(0.8);
	}
	&._dark {
		background-position: -300px -191px;
	}
}
.icon-comma-right {
	height: 28px;
    width: 35px;
	background-position: -95px -61px;
	background-size: auto;
	vertical-align: text-top;
	margin: 0 4px 0 0;
	transform: rotate(180deg);
	&._sm {
		transform: rotate(180deg) scale(0.8);
	}
}
[class^="icon-quote-"] {
	background-repeat: no-repeat;
	background-position: contain;
	height: 24px;
	width: 19px;
}
.icon-quote-left {
	margin: 0 5px 0 0;
	text-align: left;
	vertical-align: text-bottom;
	background-image: url("../../assets/images/icons/quote1.png");
}
.icon-quote-right {
	margin: 0 0 0 5px;
	text-align: right;
	vertical-align: text-top;
	background-image: url("../../assets/images/icons/quote2.png");
}
.icon-plus {
    background-position: -538px -170px;
	cursor: pointer;
	height: 25px;
	width: 25px;
}
.icon-minus {
    background-position: -623px -67px;
	cursor: pointer;
	height: 25px;
	width: 25px;
}
.icon-plus2 {
    background-position: -57px 0;
	cursor: pointer;
	height: 25px;
	width: 25px;
}
.icon-minus2 {
    background-position: -90px 0;
	cursor: pointer;
	height: 25px;
	width: 25px;
}

.icon-plus-cat {
	background-image: url("../../assets/images/icons/plus_15.png");
	background-repeat: no-repeat;
	width: 15px;
	height: 15px;
	/* &._sm {
		width: 12px;
		height: 12px;
	} */
}
.icon-minus-cat {
	background-image: url("../../assets/images/icons/minus15.png");
	background-repeat: no-repeat;
	width: 15px;
	height: 15px;
	/* &._sm {
		width: 12px;
		height: 12px;
	} */
}

.icon-yahoo {
	background: url("../../assets/images/icons/yahoo-live.png") no-repeat 0 0;
    width: 23px;
    height: 20px;
}
.icon-live {
	background: url("../../assets/images/icons/yahoo-live.png") no-repeat 0 -23px;
    width: 23px;
    height: 20px;
}
.icon-camera {
	background-position: 0 -30px;
    width: 18px;
    height: 14px;
}
.icon-camera-white {
	background-image: url("../../assets/images/icons/camera_icon.png");
	background-repeat: no-repeat;
	width: 28px;
	height: 24px;
	vertical-align: middle;
}
.icon-settings {
	background-position: 0 -55px;
    width: 18px;
    height: 14px;
}
.icon-camera-gold {
	background-position: -23px -30px;
    width: 16px;
    height: 13px;
    top: 1px;
}

.icon-arrow-left {
	background-image: url("../../assets/images/sprite1.png");
    background-position: left -101px;
	height: 38px;
	width: 41px;
	margin-left: 10px;
	margin-top: 0;
}
.icon-arrow-right {
	background-image: url("../../assets/images/sprite1.png");
    background-position: left -48px;
    width: 41px;
    height: 38px;
	margin-left: 10px;
	margin-top: 0;
}

.icon-add-new {
	width: 16px;
    height: 15px;
	background-position: -33px 0;
	background-repeat: no-repeat;
	margin-right: 10px;
}
.icon-cross {
	width: 9px;
	background-image: url("../../assets/images/icons/cross-icon.png");
	background-repeat: no-repeat;
	background-position: 0 2px;
	background-size: contain;
	vertical-align: middle;

}
.icon-bell {
	background: url("../../assets/images/icons/bell-white.png") no-repeat;
	// margin: 5px -7px;
	width: 18px;
	height: 20px;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: contain;
	vertical-align: middle;
}

.tick-mark {
	&::after {
		content: "\2713";
		margin: 0 0 0 5px;
		font-size: 17px;
	}
}

.imported-contacts {
	display: inline-block;
	background: url("../../assets/images/icons/contact-updates.png") no-repeat 0 0;
	width: 14px;
	height: 14px;
}
.sync-contacts {
	display: inline-block;
	background: url("../../assets/images/icons/contact-updates.png") no-repeat -20px 0;
	width: 14px;
	height: 14px;
}