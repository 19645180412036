@import "./styles/index";
/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
 */
 body {
   background: $body-bg url('./assets/images/body-bg.png') left top repeat;
 }

.styles_playButton__1Q3EF {
  display: none !important;
}

.styles_poster__1JohY {
  width: 150px !important;
  padding: 5px !important;
}

.outer-preview {
  background: #8080808c;
  text-align: center;
  height: 130px;
  margin: 5px;
}

span.remove-image-icon {
  float: right;
  position: absolute;
  top: 30px;
  right: 0;
  border: 1px solid grey;
  padding: 5px;
}

.render-delete-images {
  overflow-y: scroll;
  min-height: 140px;
  max-height: 200px;
  overflow-x: hidden;
}

.render-delete-videos {
  overflow-y: scroll;
  min-height: 140px;
  max-height: 200px;
  overflow-x: hidden;
}

.displayHover {
  position: absolute;
  background: #fff;
  padding: 30px;
  border: 1px solid #ddd;
}

.mb-200 {
  margin-bottom: 200px;
}

.bottom-heading {
  padding-left: 100px;
  color: #fff;
}
.text-error{
 color:red;
}

.text-image-error{
 color:red;
 text-align:center
}

.button-color-reset{
  background-color: #3071a9 !important;
  border-color: #3071a9 !important;
}

.toast-success{
 color: #808080 !important;
   
}

.dragImage{
	width: 320px;
    max-width: 320px;
    height: 240px;
    position: absolute;
    left: -85px;
    top: -45px;
}

.flashing {
  animation: flashing 1.2s infinite;
}

@keyframes flashing {
  0% {
    opacity: 1;
  }
  49% {
    opacity: 1;
  }
  60% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}