
html {
  scroll-behavior: smooth;

  -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    text-rendering: geometricPrecision;
    font-smooth: always;

    @media (max-width: 1024px) {
        -webkit-overflow-scrolling: touch;
    }
}
ul{
  list-style-type: none;
  margin: 0;
  padding: 0;
  li{
    list-style-type: none;
  }
}
li {
  strong {
    font-weight: 700;
  }
  .number-count {
    float: left;
    width: 25px;
  }
}
ul.list-normal {
  list-style-type: disc;
  padding-inline-start: 30px;
  margin-bottom: $spacer;
  li {
    list-style-type: inherit;
  }
}
ol.list-style-number {
  list-style-type: decimal;
  padding-inline-start: 15px;
  li {
    list-style-type: inherit;
  }
}

li, p {
  font-family: $font-family-alt;
}
.ff-alt {
  font-family: $font-family-alt !important;
}
.ff-base {
  font-family: $font-family-sans-serif !important;
}
.ff-headings {
  font-family: $headings-font-family !important;
}
img {
  word-break: break-all;
  font-size: 14px;
}

img, video {
  max-height: 50vh;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  margin: 0;
  font-family: $headings-font-family;
}

hr.divider {
  max-width: 3.25rem;
  border-width: 0.2rem;
  border-color: $primary;
}

hr.light {
  border-color: $white;
}

.page-section {
  padding: 2rem 0;
}

.section-title {
  font-family: $font-family-base;
  font-size: 20px;
  color: $text-dark;
  text-transform: uppercase;
  font-weight: $font-weight-bold;
  margin-bottom: 1.5rem;
}

.section-subtitle {
  font-family: $font-family-base;
  font-size: 20px;
  color: $secondary;
  text-transform: uppercase;
  font-weight: $font-weight-bold;
  margin-bottom: 1rem;
  letter-spacing: -0.48px;
}

.caption-block {
  position: relative;
  height: 100%;
  &.filter {
    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: rgba($color: $black, $alpha: 0.3);
    }
  }
  .stretched-link {
    &:after {
      transition: all .2s;
    }
    &:hover {
      &:after {
        background-color: rgba($color: $black, $alpha: 0.3);
      }
    }
  }
}
.caption-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
  @include media-breakpoint-up(md) {
    max-height: 300px;
  }
}

.caption-heading {
  position: absolute;
  z-index: 2;
  display: block;
  width: 100%;
  text-align: center;
  color: $white;
  font-family: $font-family-base;
  font-size: 32px;
  padding: 1rem;
  &.bottom {
    bottom: 0;
  }
  &.center {
    top: 50%;
    transform: translateY(-50%);
  }
  &.filter {
    background-color: rgba($color: $black, $alpha: 0.35);
  }
  @include media-breakpoint-up(md) {
    font-size: 18px;
    padding: 0.5rem;
  }
}

.fixed-height-img {
  height: 100px;
  width: auto;
  max-width: 100%;
  max-height: unset;
  &.is-50 {
    height: 50px;
  }
  &.is-120 {
    height: 120px;
  }
  &.is-160 {
    height: 160px;
  }
  &.is-200 {
    height: 200px;
  }
  &.is-300 {
    height: 300px;
  }
  &.full-width {
    width: 100%;
    object-fit: cover;
    object-position: center center;
  }
}
.project-thumbnail {
  img {
    width: 102px;
    height: 85px;
    object-fit: cover;
  }
}

.review-heading {
  color: $text-dark;
  font-size: 16px;
  text-transform: uppercase;
  font-family: $font-family-base;
  font-weight: $font-weight-bold;
  letter-spacing: 1.7px;
  margin-bottom: 23px;
}

.review-desc {
  font-family: $font-family-base;
  font-size: 16px;
  color: $secondary-dark;
}

.bg-light-blue {
  background-color: #e9f1f9;
}

.horizontal-bar {
  display: flex;
  flex-direction: column;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: $gray-400;
    z-index: 0;
  }
  > * {
    position: relative;
    z-index: 1;
  }
}

.filterable {
  font-family: $font-family-base;
  color: $tertiary;
  font-weight: $font-weight-bold;
  font-size: 18px;
  text-decoration: none;
  @include hover-focus {
    text-decoration: none;
    color: $text-dark;
  }
  &.active {
    text-decoration: none;
    color: $text-dark;
  }
}

.user-avatar {
  width: 56px;
  height: 56px;
  object-position: center center;
  object-fit: cover;
  border-radius: 50%;
}

.review-img {
  @include media-breakpoint-up(md) {
    width: 100%;
    height: 150px;
    object-fit: cover;
    object-position: center center;
  }
}

.category-heading {
  font-size: 20px;
  color: $white;
  text-transform: uppercase;
  font-weight: $font-weight-bold;
  padding: 3rem 0;
  letter-spacing: 1px;
  margin: 0;
  background-color: $dark;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top left;
}

.wikiFactor {
  background-image: url('../assets/images/factor-bg.jpg');
  background-size: cover;
}
.white-separators {
  display: flex;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 30px;
}
.white-separators:before, .white-separators:after {
  content: "";
  height: 2px;
  width: calc(50% - 55px);
  background-color: $white;
}
.repeat-star {
  display: block;
  background: url('../assets/images/icons/repeat-star.png') repeat-x;
  background-position: center left;
  position: relative;
  height: 10px;
  width: 100px;
  margin-left: auto;
  margin-right: auto;
}

.main-site-carousel {
  padding-bottom: 3rem;
  margin-top: 3rem;    
  margin-bottom: 3rem;
  .react-multi-carousel-item {
    .item {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
  @include media-breakpoint-down(sm) {
    /* .react-multi-carousel-item:not(.react-multi-carousel-item--active) {
      height: 1px !important;
    } */
    margin-top: 1rem;
    margin-bottom: 1rem;
    .react-multi-carousel-item {
      > .item {
        height: 350px;
        overflow-y: auto;
        justify-content: flex-start;
        video {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center center;
        }
      }
    }
  }
}
.counter-circle {
  height: 2.5rem;
    width: 2.5rem;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 2px;
    border-style: solid;
    border-color: $dark;
    font-weight: $font-weight-bold;
    &.brown {
      border-color: $brown;
      color: $brown;
    }
    &.blue {
      border-color: $blue;
      color: $blue;
    }
}

.project-review-heading {
  font-family: $font-family-base;
  font-weight: $font-weight-bold;
  letter-spacing: 2px;
  line-height: 1.2;
}
.custom-tooltip {
  bottom: calc(100% + 20px);
  left: 5%;
  position: absolute;
  display: none;
  width: 90%;
  opacity: 0;
  padding: 10px;
  text-align: center;
  background-color: $white;
  border-radius: 4px;
  border: 2px solid $secondary;
  font-size: 12px;
  box-shadow: $box-shadow;
  transition: all 0.2s;
  &::after {
    content: '';
    position: absolute;
    background: url('../assets/images/sprite.png') no-repeat -56px -97px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -18px;
    width: 30px;
    height: 20px;
  }
  &._bottom {
    top: calc(100% + 10px);
    bottom: unset;
    &::after {
      bottom: unset;
      top: -18px;
      transform: translateX(-50%) rotate(180deg);
    }
  }
  &._fixed-width {
    width: 240px;
    left: 50%;
    transform: translateX(-50%);
  }
}
.custom-tooltip-container {
  position: relative;
  &:hover {
    .custom-tooltip {
      display: block;
      opacity: 1;
      z-index: 1;
    }
  }
}

[data-category="businesses"] {
  background-image: url('../assets/images/businesses-bg.png');
}
[data-category="movies"] {
  background-image: url('../assets/images/movies-bg.png');
}
[data-category="products"] {
  background-image: url('../assets/images/products-bg.png');
}
[data-category="professionals"] {
  background-image: url('../assets/images/professionals-bg.png');
}
[data-category="actors"] {
  background-image: url('../assets/images/actors-bg.png');
}

.interactive {
  position: relative;
  overflow-x: hidden;
  .interactive-appear {
    position: relative;
    z-index: 1;
    right: -100%;
    opacity: 0;
    transition: none;
    transition: all 0.2s;
  }
  &::after {
    content: '\f142';
    top: 6px;
    right: 4px;
    opacity: 0.7;
    position: absolute;
    display: inline-block;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    font-size: 12px;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
  }
  
  @include hover {
    &::after {
      opacity: 0;
    }
    .interactive-appear {
      opacity: 1;
      right: 0;
    }
  }
}

[role="button"] {
  cursor: pointer;
}
button,
[type="button"],
[type="reset"],
[type="submit"]{
  &.disabled, &:disabled {
    cursor: not-allowed;
  }
}

.under-link{
  text-decoration: underline;

  &-hover {
    &:hover {
      text-decoration: underline;
    }
  }
}



#mapContainer {
  height: 350px !important;
  width: 100% !important;
}

.marker {
  background-image: url('https://stagingdatawikireviews.s3.amazonaws.com/media/content/cvs_pharmacy_logo.9ce3d5dfa5188f38ad1b6ec5a167d5d683952a8f.jpg');
  background-size: cover;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
}


.mapboxgl-popup {
max-width: 200px;
}

.mapboxgl-popup-content {
text-align: center;
font-family: 'Open Sans', sans-serif;
}

  .videoModal.modal-dialog{
    max-width: 80%;
  }

.item-selectable {
  position: relative;
  .overlay {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba($color: $black, $alpha: 0.8);
    color: $white;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all 0.2s;
    &.selected {
      opacity: 1;
      background-color: rgba($color: $dark, $alpha: 0.8);
    }
  }
  @include hover-focus {
    .overlay {
      opacity: 1;
    }
  }
}

.object-fit-cover {
  object-fit: cover !important;
}

.object-fit-contain {
  object-fit: contain !important;
}

.object-position-top {
  object-position: center top !important;
}
.object-position-bottom {
  object-position: center bottom !important;
}
.object-position-center {
  object-position: center center !important;
}

.uploaded-img-thumbnail {
  height: 105px;
  width: 125px;
  min-width: 125px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
}

.video-holder, .audio-holder {
  position: relative;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    margin: 10px;
    // background-color: rgba($color: $black, $alpha: 0.3);
    background-color: transparent;
    background-image: url('../assets/images/sprite.png');
    background-position: -289px -116px;
  }
  &.play-icon {
    &:after {
      margin: 0;
      width: 100%;
      height: 100%;
      background-color: rgba($color: $black, $alpha: 0.3);
      background-size: 50% 50%;
      background-repeat: no-repeat;
      background-position: center center;
      background-image: url('../assets/images/icons/play-button.svg');
    }
  }
}

.audio-holder {
  position: relative;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("../assets/images/icons/sound-icon.svg");
    background-position: center center;
    background-size: 50%;
    background-repeat: no-repeat;
    background-color: $black;
  }
}

.cursor-default {
  cursor: default !important;
}
.react-draggable, .cursor {
      cursor: move;
    }
.no-cursor {
  cursor: auto;
}
.cursor-y {
  cursor: ns-resize;
}
.cursor-x {
  cursor: ew-resize;
}

.react-draggable strong {
  background: #ddd;
  border: 1px solid #999;
  border-radius: 3px;
  display: block;
  margin-bottom: 10px;
  padding: 3px 5px;
  text-align: center;
}

.attachment{
   width: auto;
    max-width: 100%;
    text-align: center;
    float: none;
    max-height: 500px;
}
.poster-img {
  width: 100%;
  height: 170px;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: top;
  }
}

.img-circle {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  object-position: center center;
  &._150x150 {
    width: 150px;
    height: 150px;
  }
  &._120x120 {
    width: 120px;
    height: 120px;
  }
  &._50x50 {
    width: 50px;
    height: 50px;
  }
  &._40x40 {
    width: 40px;
    height: 40px;
  }
  &._35x35 {
    width: 35px;
    height: 35px;
  }
  &._30x30 {
    width: 30px;
    height: 30px;
  }
}

.chat-title {
  color: $dark;
  font-family: $font-family-base;
  font-size: 14px;
  font-weight: $font-weight-bold;
}
.chat-room {
  background-color: #eaece9;
  color: $text-dark;
  padding: 10px;
  margin-bottom: 5px;
  .chat-title {
    color: inherit;
  }
  &:hover {
    background-color: darken($color: #eaece9, $amount: 5%);
  }
  &.active {
    background-color: $dark;
    color: $white;
  }
}
.chat-list {
  margin-top: 15px;
}
.chat-room, .chat-list {
  .text-faded {
    color: #879ab5;
  }
}
/* @include media-breakpoint-up(lg) {
  .feature-grid-section {
    height: 600px;
  }
} */

.minh-50 {
  min-height: 50%;
}

.featured-item {
  position: relative;
  color: $white;
  z-index: 3;
  // padding: 5px;
  background-color: $light;
  min-height: 250px;
  overflow: hidden;
  .featured-details {
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 2;
    &.hoverable {
      // transform: translateY(60px);
      transition: all 0.5s;
      .hover-appear {
        opacity: 0;
        height: 0;
        transition: all 0.2s;
      }
      &:hover {
        background-color: rgba($color: $darker, $alpha: 0.7);
        // transform: translateY(0);
        .hover-appear {
          opacity: 1;
          height: 50px;
		      transition: all 0.5s;
          padding: 8px;
        }
      }
    }
  }
  .floating-item {
    position: absolute;
    z-index: 3;
    opacity: 0;
    top: 50%;
    transform: translateY(-25%);
    width: 80%;
    left: 10%;
    transition: all 0.2s;
  }

  &:hover {
    cursor: pointer;
    .floating-item {
      opacity: 1;
      transform: translateY(-50%);
    }
  }

  &:after {
    content: '';
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color: $black, $alpha: 0.1);
  }
  /* + .featured-item {
    .featured-details {
      padding-bottom: 60px;
      height: 100%;
    }
  } */
}
.featured-details {
  max-height: 100%;
  overflow: hidden;
  padding: 10px;
  padding-bottom: 50px;
}
.floating-item {
  padding: 5px;
  background-color: fade-out($color: $darker, $amount: 0.1);
  color: $white;
  text-align: center;
  a {
    text-decoration: none !important;
    color: inherit;
  }
  .item-title {
    font-size: 22px;
    margin-bottom: 12px;
    text-transform: uppercase;
    font-family: $font-family-base;
    font-weight: $font-weight-bold;
  }
  .item-category {
    font-size: 21px;
    font-weight: $font-weight-bold;
    font-family: $font-family-base;
  }
  .item-link {
    font-size: 16px;
    font-family: $font-family-base;
    font-style: italic;
    font-weight: $font-weight-normal;
    color: $white;
    text-decoration: none !important;
    margin-top: 10px;
    display: block;
  }
}
.featured-item-title {
  text-transform: uppercase;
  text-shadow: 2px 2px 5px #333;
  font-size: 35px;
  margin-bottom: 15px;
  background: #000000b8;
  padding: 5px;
  width: fit-content;
}
.featured-item-credit {
  font-size: 14px;
  line-height: 1;
  text-transform: capitalize;
}
h1 {
  text-transform: capitalize;
}
.feature-project-image {
  $standardImgHeight: 500px;
  height: 100%;
  width: 100%;
  max-height: unset;
  object-fit: cover;
  object-position: center center;
  /* &._full {
    + .featured-details {
      height: 50%;
    }
  } */
  @include media-breakpoint-up(lg) {
    &._full {
      height: $standardImgHeight;
    }
    &._half {
      height: $standardImgHeight / 2 - 2px;
    }
  }
}

.tooltiptext {
  width: 120px;
  background-color: #fff;
  text-align: center;
  border: 2px solid #d1bb78;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  transition: opacity 0.3s;
 &.auto-width {
   width: auto;
   white-space: nowrap;
   &:after {
     left: 50%;
     transform: translateX(-50%);
   }
 }
 &.reaction-tooltip {
   width: 130px;
  //  white-space: nowrap;
   padding: 10px;
   bottom: 40px;
   font-weight: normal;
   word-break: break-word;
   font-family: $font-family-alt;
   line-height: 16px;
   color: #353535;
   left: 50%;
   margin-left: 0;
   transform: translateX(-50%);
   p {
     margin-bottom: 0;
     line-height: 1.14;
   }
   &::after {
     left: 50%;
     transform: translateX(-50%);
   }
 }
 &.help-community-tooltip {
   right: 0;
   left: unset;
   margin-left: 0;
   margin-right: -100px;
   &:after {
     left: unset;
     right: 20px;
     transform: translateX(0);
   }
 }
}


div.tooltiptext:after {
    content: "";
    bottom: -26px;
    left: 66px;
    display: block;
    height: 27px;
    position: absolute;
    width: 30px;
    background: url(../assets/images/help-arrow.png) no-repeat;
    z-index: 1;
}


div.container-down-arrow:after {
  content: "";  
  height: 0px;
  width: 0px;
  border: 10px solid transparent;
  border-top-color: #ffc107 ;
  position: absolute;
  bottom: -20px;
  left: 26%;
  z-index: 1;
}

.grey-body {
  background-color: #dadcd9;
}

.featured-blurred-bg {
  background-image: url("../assets/images/empty-bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center center;
  position: absolute;
  width: 100%;
  height: calc(100% - 54px);
  filter: blur(4px);
  background-color: $tertiary;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% + 54px);
    background: rgba(0, 0, 0, 0.25);
  }
  &.no-blurr {
    filter: blur(0);
  }
  &.no-layer.no-blurr {
    height: 100%;
  }
  &.no-layer {
    &:after {
      display: none;
    }
  }
  &.full-height {
    height: 100%;
    &:after {
      height: 100%;
    }
  }
  &.extra-blurr {
    filter: blur(10px);
  }
}
.featured-map {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.hoverable-review {
  position: relative;
  overflow: hidden;
  .review-details {
    transition: all 0.5s;
  }
  .review-mask {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 0;
    transform: scale(0);
    transition: all 0.5s;
    line-height: 1.2;
  }
  &:hover {
    .review-details {
      opacity: 0;
      transform: scale(2);
      z-index: -1;
      transition-delay: 0.2s;
    }
    .review-mask {
      opacity: 1;
      // background-color: $dark;
      transform: scale(1);
      transition-delay: 0.2s;
    }
  }
}

.Demo__map-maker-icon {
  color: #FD6C6C;
}

.Demo__github-icon {
  font-size: 24px;
}

.Demo__github-link {
  color: #262626;
  font-size: 20px;
}

.Demo__github-link:hover,
.Demo__github-link:active {
  color: #4078c0;
  text-decoration: none;
}

.Demo__suggestion-icon {
  margin-right: 8px;
}

.Demo__search-bar-container {
  width: 100%;
  max-width: 500px;
 
}

.Demo__search-input-container {
  position: relative;
}

.Demo__search-input,
.Demo__search-input:focus,
.Demo__search-input:active {
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.16), 0 0 0 1px rgba(0,0,0,0.08);
  border: honeydew;
  display: block;
  width: 100%;
  font-size: 16px;
  border-radius: 2px;
  outline: none;
}

.Demo__clear-button,
.Demo__clear-button:active,
.Demo__clear-button:focus {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  outline: none;
  font-weight: 600;
  color: #999;
}

.Demo__autocomplete-container {
  border-bottom: honeydew;
  border-left: honeydew;
  border-right: honeydew;
  border-top: 1px solid #e6e6e6;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  border-radius: 0 0 2px 2px;
}

.Demo__suggestion-item {
  padding: 8px;
  text-align: left;
  background-color: #fff;
  cursor: pointer;
}
.Demo__search-bar-container.with-map-icon {
  .form-control {
    padding-left: 25px;
    background: url("../assets/images/icons/map-small.png") no-repeat 8px 7px;
  }
}
.autocomplete-dropdown-container {
  width: 100%;
  min-width: 300px;
  box-shadow: $box-shadow;
  background-color: $white;
}
.suggestion-item {
  color: $gray-600;
  .far, .fas, .fab, .fa, svg {
    color: $gray-300;
    fill: $gray-300;
  }
}
.suggestion-item--active {
  color: $gray-700;
  .far, .fas, .fab, .fa, svg {
    color: $danger;
    fill: $danger;
  }
}
.suggestion-item, .suggestion-item--active {
  font-size: 14px;
  padding: 4px 8px;
  font-family: $font-family-alt !important;
  font-weight: $font-weight-normal !important;
  border-bottom: 1px solid $gray-300;
}

.Demo__suggestion-item--active {
  background-color: #fafafa;
}

.Demo__dropdown-footer {
  display: flex;
  justify-content: flex-end;
  padding: 4px;
}

.Demo__dropdown-footer-image {
  display: inline-block;
  width: 150px;
}

.Demo__spinner {
  color: #18bc9c;
  font-size: 30px;
}

.Demo__error-message {
  color: red;
}

.Demo__geocode-result-header {
  font-size: 20px;
  color: #222222;
}
.cover-banner {
  height: 500px;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

[data-blurr="true"] {
  filter: blur(5px);
}
[data-blurr="false"] {
  filter: blur(0);
}

.spoiler-alert {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  &._type-review {
    display: inline-block;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.trending-info-box {
  background: rgba($color: $primary, $alpha: 0.87);
  color: $dark;
  display: block;
  padding: 1rem 1rem 1rem;
  &._top-fix {
    position: fixed;
    width: 100%;
    top: 45px;
    left: 0;
    z-index: 9;
  }
}

.detail-rating-onhover {
  display: none;
  position: absolute;
  left: 100px;
  top: -75px;
  width: 300px;
  border: 2px solid #d4c084;
  padding: 10px 0 25px 20px;
  background-color: rgba(255,255,255,.98);
  line-height: 2;
  z-index: 3;
  &:before {
    content: "";
    top: 68px;
    left: -17px;
    display: inline-block;
    height: 26px;
    position: absolute;
    width: 20px;
    background: url("../assets/images/pop-up-arrow.png") no-repeat;
  }
}

.hoverable-rating {
  position: relative;
  &:hover {
    .detail-rating-onhover {
      display: block;
    }
  }
}

.mv-rating-onhover {
  display: none;
  width: 500px;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
}
.hoverable-mv-rating {
  position: relative;
  cursor: pointer;
  &:hover {
    .mv-rating-onhover {
      display: block;
    }
  }
}

.shade-link {
  background-color: rgba($color: $white, $alpha: 0.7);
  padding: 0.25rem 1rem;
  font-weight: $font-weight-bold;
  color: $white;
  &._black {
    background-color: rgba($color: $black, $alpha: 0.7);
  }
  &._padded {
    padding: 0.5rem 1rem;
  }
  &:hover {
    text-decoration: none;
    color: $white;
    background-color: rgba($color: $black, $alpha: 0.2);
  }
}

.black-shade {
  background-color: rgba($color: $black, $alpha: 0.7);
}

.fixed-map {
  /*position: fixed;*/
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.gallery-filterable-list {
  font-weight: $font-weight-bold;
  color: $gray-300;
  &:hover, &.active {
    cursor: pointer;
    color: $white;
  }
}

// 13-8 gaurav css
.wk-mv-share {
  background-color: rgba($color: $darker, $alpha: 0.8);
  padding: 0px 0px;
  width: 100%;
  // border-left: 2px solid $white;
  text-align: center;
  ul {
    margin: 0;
    padding: 0;
    display: flex;
    li {
      list-style: none;
      position: relative;
      a {
        width: 50px;
        padding: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 54px;
        &:hover {
          background-color: $darker;
        }
      }
      .dropdownbar {
        z-index: 5;
        margin: 0;
        background: none;
        bottom: 54px;
        top: inherit;
        left: 0px;
        width: 180px;
        position: absolute;
        display: none;
        &.dropdownbar-right {
          right: -20px;
          left: auto;
        }
      }
      &:hover {
        a {
          background-color: $darker;
        }
        .dropdownbar {
          display: block;
          a {
            background: rgba($color: $darker, $alpha: 0.8);
            padding: 8px;
            color: $white;
            font-size: 14px;
            display: block;
            // border-bottom: 1px solid $white;
            width: 100%;
            text-align: left;
            height: auto;
            font-weight: bold;
            &:hover {
              background-image: none;
              background: rgba($color: $darker, $alpha: 1);
              text-decoration: none;
            }
            img {
              max-width: 14px;
            }
          }
        }
      }
    }
    .share {
      a {
        background: url('../assets/images/icons/share-alt.png');
        background-size: 16px 16px;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        &:hover {
          background-image: url('../assets/images/icons/share-hover.png');
          background-size: 16px 16px;
        }
      }
    }
   .like {
      a {
        background: url('../assets/images/icons/like.png');
        background-size: 16px 16px;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        &:hover, &.active {
          background-image: url('../assets/images/icons/liked-hover.png');
        }
      }
  }
  .pin {
    a {
      background: url('../assets/images/icons/pin.png');
      background-size: 16px 16px;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      &:hover, &.active {
        background-image: url('../assets/images/icons/pin-hover.png');
      }
    }
  }
  .flag {
    a {
      background: url('../assets/images/icons/flag.png');
      background-size: 16px 16px;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      &:hover {
        background-image: url('../assets/images/icons/flag-hover.png');
      }
    }
   }
  }
}
.featured-tab-content {
  height: 500px;
  > .tab-pane {
    height: 100%;
    padding-bottom: 54px;
  }
}
.wr-movie-top-banner {
  .about-view {
    width: 100%;
    transition: all 0.2s;
    &._mini {
      width: 380px;
    }
    
  }
  .tab-content {
    position: relative;
  }
  padding-top: 44px;
  .wr-movie-top-about {
    height: calc(500px - 54px);
    width: 100%;
    background-color: rgba($color: $white, $alpha: 1);
    position: relative;
    z-index: 2;
    .input-group {
      &:focus-within {
        // border-color: $text-dark;
        .input-group-append {
          .btn, .btn-light {
            // border-color: $text-dark;
            border-color: #66afe9;
            box-shadow: none;
          }
        }
      }
    }
    .form-control:not(.comment-form-control) {
      background-color: transparent;
      // border-color: $gray-300;
      color: inherit;
      &:focus {
        // border-color: $text-dark;
        border-color: #66afe9;
        outline: 0;
        box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102, 175, 233, .6) !important;
      }
    }
    .form-control.is-valid {
      &:focus {
        border-color: $success;
      }
    }
    .form-control.is-invalid {
      &:focus {
        border-color: $danger;
      }
    }
    select {
      &.form-control:focus {
        background-color: transparent !important;
      }
    }

    .input-group-append {
      .btn, .btn-light {
        background-color: transparent;
        // border-color: $text-dark;
        color: inherit;
        &:focus {
          border-color: $primary;
        }
      }
      
      // Temporarily below
      > .btn {
        + .btn {
          margin-left: -1px !important;
        }
      }
      // Temporarily below ended
    }
    .wr-movie-top-about-body {
      height: 100%;
      overflow-y: auto;
      padding-top: 24px;
      padding-left: 15px;
      padding-right: 15px;
      @include custom-scrollbar;
    }
    .wr-movie-product-info-top-left {
      width: 110px;
      margin-right: 15px;
      height: 110px;
      overflow: hidden;
    }
    .wr-brand-image {
      height: 100%;
      object-fit: cover;
      object-position: center center;
    }
    .wr-movie-product-info-top-right {
      width: calc(100% - 125px);
    }
    .wr-movie-product-info-bottom {
      padding-top: 15px;
      text-align: left;
      li {
        list-style: none;
        margin-bottom: 12px;
        .wr-movie-product-info-bottom-left {
          width: 80px;
          min-width: 80px;
          display: inline-block;
          margin-right: 15px;
        }
        .wr-movie-product-info-bottom-right {
          width: calc(100% - 95px);
          display: inline-block;
        }
        span {
          color: $text-dark;
        }
        a {
          color: #428bca;
          &.wr-movie-product-info-bottom-right-black {
            color: $text-dark;
          }
          &.wk-business-add-menu {
            color: $primary;
          }
        }
      }
      ul.list-normal {
        list-style-type: disc;
        margin-left: -14px;
        > li {
          list-style-type: disc;
          margin-bottom: 0.5rem;
        }
      }
    }
    .wr-business-product-info-bottom {
      ul {
        li {
          .wr-movie-product-info-bottom-left {
            width: 25px;
            i {
              font-size: 24px;
            }
          }
          .wr-movie-product-info-bottom-right {
            width: calc(100% - 40px);
          }
        }
      }
    }
  }
  .wr-category-heading {
    text-align: left;
    h4 {
      font-size: 20px;
      color: $text-dark;
      text-transform: uppercase;
      text-align: left;
    }
    .wr-brand-title {
      font-size: 30px;
    }
    p {
      color: $text-dark;
      margin: 4px 0px 0px;
    }
  }
  .wr-movie-product-info-bottom-right-black {
    color: $text-dark;
  }
  .wr-category-list-center {
    li {
      list-style: none;
      display: inline-block;
      width: 50%;
      &:nth-child(2n) {
        padding-left: 15px;
      }
      .wr-movie-product-info-bottom-right-black {
        padding-top: 15px;
        color: #00162B;
      }
    }
  }
  .wr-movie-less-info-about {
    // position: absolute;
    // bottom: 0;
    // z-index: 3;
    // right: 0px;
    // background-color: $white;
    padding-left: 10px;
    a, [role="button"] {
      // float: right;
      display: block;
      color: #00162b;
      font-size: 15.9px;
      font-weight: bold;
      line-height: 1;
      .text {
        vertical-align: middle;
        display: inline-block;
        margin-top: 10px;
      }
      i {
        background-color: $primary;
        display: inline-block;
        width: 35px;
        height: 35px;
        line-height: 35px;
        color: #fff;
        font-size: 20px;
        text-align: center;
        margin-left: 8px;
      }
      svg {
        background-color: $primary;
        display: inline-block;
        width: 35px;
        height: 35px;
        line-height: 35px;
        text-align: center;
        margin-left: 8px;
        vertical-align: middle;
        path {
          fill: $white; 
        }
      }
    }
  }
  .movie-detail-textarea {
    textarea {
      height: 76px;
      border-color: #ced4da;
      width: 80%;
      padding: 8px;
    }
  }
  .wr-movie-detal-date-edit {
    width: 100%;
  }
  .wr-movie-theture-details-left {
    width: 50px;
    margin-right: 15px;
  }
  .wr-movie-theture-details-right {
    width: calc(100% - 75px);
  }
  .wr-home-theature-left {
    h2 {
      color: $white;
      font-size: 40px;
      text-shadow: 1px 1px 4px #000000;
      float: left;
      width: 100%;
    }
    p {
      color: $white;
      float: left;
      width: 100%;
    }
  }
  .wr-home-theature-left {
    select {
      max-width: 200px;
      float: right;
    }
  }
  .wr-sun-image {
    img {
      max-height: 32px;
    }
  }
  .wr-business-new-add {
    a {
      color: $text-dark;
      font-weight: bold;
    }
  }
  .wk-transport {
    text-align: center;
    width: 100%;
    border: solid #879ab5;
    border-width: 1px 0px;
    margin: 10px 0;
    padding: 5px 0;
    li {
      list-style: none;
      display: inline-block;
      margin-left: 40px;
      &:nth-child(1) {
        margin-left: 0px;
      }
      a{
        background-image: url('../assets/images/sprite.png');
        background-repeat: no-repeat;
        width: 25px;
        height: 16px;
        display: block;
        line-height: 14px;
        margin-top: 1px;
        vertical-align: text-top;
        &.icon-car {
          background-position: -425px -22px;
          // height: 25px;
          &:hover, &.active {
            background-position: -459px -20px;
          }
        }
        &.icon-train {
          background-position: -424px -57px;
          height: 25px;
          &:hover, &.active {
            background-position: -459px -57px;
          }
        }
        &.icon-walk {
          background-position: -425px -89px;
          height: 25px;
          &:hover, &.active {
            background-position: -458px -89px;
            height: 25px;
          }
        }
        &.icon-bycl {
          background-position: -425px -127px;
          // height: 25px;
          &:hover, &.active {
            background-position: -459px -126px;
          }
        }
      }
    }
  }
  .wr-business-location-form {
    label {
      color: $text-dark;
      display: block;
    }
    .view-all {
      margin: 5px 0;
      display: inline-block;
      float: right;
      color: $text-dark;
    }
  }
  .floated-info-section {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: 300px;
    left: 400px;
    text-align: left;
    z-index: 1;
    overflow-y: hidden;
  }
  .floated-no-image-section {
    position: absolute;
    left: 415px;
    height: 90%;
    top: 5%;
    width: calc(100% - 450px);
    background-color: rgba($color: $white, $alpha: 0.14);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .image-area-show {
    margin: 10px 30px;
    width: 400px;
    height: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @include media-breakpoint-between(sm, md) {
      width: 200px;
    }
  }
  .image-area-crop {
    margin: 10px 30px;
    width: 200px;
    text-align: center;
    padding: 10px;
    border: 3px dashed $white;
    color: $white;
    line-height: 1.2;
    font-size: 13px;
    font-family: $font-family-alt;
  }
}
.hideShow{
  display:none;
}

.editable-column {
  cursor: url('../assets/images/icons/edit-info-icon.png'), auto;
}

.filterable-item {
  color: $tertiary;
  font-size: 1rem;
  font-family: $font-family-base;
  cursor: pointer;
  &:hover, &.active {
    font-weight: $font-weight-bold;
    color: $primary;
  }
}

.user-comment {
  word-break: break-word;
}
.wb-break-word {
  word-break: break-word;
}

.comment-body-renderer {
  font-family: $font-family-alt;
  font-size: 14px;
  color: $text-dark;
  word-break: break-word;
  p {
    margin-bottom: 0.25rem;
  }
  display: inline;
  > *:first-child {
    display: inline;
  }
  > *:last-child {
    display: inline;
  }
  &._type-collage {
    color: $white;
    font-family: $font-family-base;
    p {
      font-family: inherit;
    }
  }
}
.hide-review-block{
  display: none;
}
.user-profile-img-holder {
  display: inline-block;
  position: relative;
  padding: 5px;
  border: 1px solid $warning;
  border-radius: 50%;
  .user-profile-badge {
    position: absolute;
    right: 2px;
    top: 3px;
  }
}

.attachable-container {
  position: relative;
  > .form-control {
    padding-bottom: 40px;
    /* &.textarea {
      min-height: 200px;
    } */
  }
  .attachable-area {
    background-color: #fff;
    position: absolute;
    bottom: 1px;
    left: 1px;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    width: calc(100% - 1rem - 4px);
  }
}
.bg-vote {
  background-image: url("../assets/images/vote.png");
}
.bg-vote-alt {
  background-image: url("../assets/images/vote-alt.png");
}
.bg-vote,
.bg-vote-alt {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: cennter center;
}
.recomendit {
  background-color: #efe7d0;
  color: $dark;
  padding: 6px 9px 6px 9px;
  display: inline-block;
  position: relative;
  font-size: 14px;
  font-weight: $font-weight-bold;
  line-height: 1;
  &:after {
    border-bottom: 14px solid rgba(0, 0, 0, 0);
    border-left: 14px solid #EFE7D0;
    border-top: 13px solid rgba(0, 0, 0, 0);
    position: absolute;
    right: -14px;
    top: 0px;
    content: '';
    width: 0;
    background: none;
  }
}
.hoverable {
  position: relative;
  &:hover {
    .on-hover-appear {
      display: block;
    }
  }
  .on-hover-appear {
    position: absolute;
    display: none;
    &.invisble-part {
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 15px;
        margin-bottom: -15px;
      }
    }
  }
}
.rating-graph-appear {
  z-index: 1;
  background-color: $white;
  width: 270px;
  height: auto;
  border: 2px solid $secondary;
  padding: 5px;
  color: $text-dark;
}

.wk-view-gallery-content {
  padding: 80px 0px 40px;
  .wk-pagination-gallery {
    width: 100%;
    text-align: center;
    color: #879ab5;
    font-size: 14px;
    margin: 0 5px;
    font-family: $font-family-base;
    a {
      color: #879ab5;
    }
  }
  .wk-pagination-gallery-filter {
    ul {
      margin: 0;
      padding: 0;
      li {
        list-style: none;
        display: block;
        a {
          padding: 0 15.5px 8px;
          display: inline-block;
          font-size: 15px;
          line-height: 1.5;
          font-family: $font-family-base;
          font-weight: bold;
          color: #879ab5;
          &:hover {
            color: $dark;
          }
        }
        &.active {
          a {
            color: $dark;
          }
        }
        &:nth-child(1) {
          a {
            padding-left: 13px;
          }
        }
      }
      &.inner-gallery-nav {
        li {
          a {
            margin: 0 10px;
          }
        }
      }
    }
  }
  .wk-pagination-gallery-box-content {
    margin-bottom: 55px;
    .wk-pagination-gallery-box-image {
      height: 150px;
      display: inline-block;
      overflow: hidden;
      width: 100%;
    }
    .wk-pagination-gallery-box-detail-small-image {
      width: 30px;
      img {
        width: 30px;
        height: 30px;
        border-radius: 25px;
      }
    }
    .wk-pagination-gallery-box-detail-right {
      width: calc(100% - 45px);
      margin-left: 15px;
      .posted-user {
        font-size: 12px;
        color: #000;
        font-family: $font-family-base;
        a {
          font-family: $font-family-base;
          color: #000;
          font-weight: bold;
        }
      }
    }
  }
  .wk-para-for-image {
    font-size: 12px;
    color: #5b5b5b;
  }
}
.wk-view-gallery-banner-main {
  position: relative;
  padding: 100px 50px 40px;
  .wk-view-gallery-banner-bg {
    position: absolute;
    width: 100%;
    height: 100%;
    background-size: cover;
    left: 0;
    top: 0;
    filter: blur(4px);
  }
  .wk-gallery-banner-carousel {
    display: flex;
    // margin-left: -15px;
    // margin-right: -15px;
    // > div {
    //   padding-left: 15px;
    //   padding-right: 15px;
    // }
    .wk-gallery-side-img-wrapper {
      margin-top: 10px;
	  flex-shrink: 0;
	  max-width: calc(calc(100% - 500px) / 2);
      flex-grow: 1;
      position: relative;
      padding-left: 20px;
      padding-right: 20px;
      .img-holder {
        background-color: $black;
        img {
          opacity: 0.4;
          max-height: unset;
          width: 100%;
          height: 300px;
          object-fit: cover;
          object-position: center center;
        }
      }
    }
    .react-multi-carousel-list {
      max-width: 500px;
      flex: 0 0 500px;
    }
     .item {
      // padding: 0px 15px;
      .img-holder {
        overflow: hidden;
      }
       img {
         width: 100%;
         height: 310px;
         max-height: unset;
        //  opacity: 0.4;
          cursor: pointer;
         &:hover {
          transform: scale(1.1);
          transition: all 0.5s ease-in-out;
         }
       }
     }
     /* .react-multi-carousel-item--active {
       .item {
         img {
           opacity: 1;
         }
       }
     } */
  }
  .wk-gallery-banner-carousel-info {
    // margin: 0 auto;
    width: 100%;
    display: block;
    background: rgba(0,0,0,0.65);
    padding: 5px 15px;
    color: $white;
    font-size: 14px;
    position: relative;
    margin-top: 10px;
    font-family: $font-family-base;
    span {
      display: block;
    }
    a {
     color: $white; 
    }
  }
}

.wk-gallery-carousel-items {
     .wk-gallery-image-name {
       text-align: left;
       strong {
         color: $white;
         margin: 0 15px 20px 0;
         display: inline-block;
         font-size: 14px;
         &.wk-image-category {
            float: right;
         }
       }
     }
     .wk-user-name {
       display: inline-block;
       width: 100%;
       text-align: left;
       margin-top: 15px;
       .wk-user-name-image {
        width: 30px;
        display: inline-block;
        vertical-align: top;
        img {
          width: 30px;
          height: 30px;
          border-radius: 15px;
        }
       }
       .wk-user-content {
        display: inline-block;
        color: $white;
        text-align: left;
        margin-left: 15px;
        font-size: 14px;
        span {
          display: block;
        }
      }
     }
     .wk-carouselmodal-main-image {
      border: 2px solid #8791b5;
     }
}

.leaflet-container {
  width: 100%;
  height: 100%;
  background-color:rgba(355,0,0,0.0) !important;
}

.leaflet-top .leaflet-control {
  margin-top: 110px;
  margin-right:80px;
}
.leafletbutton{
  color: rgb(255, 255, 255);
  font-family: $font-family-base;
  font-weight: $font-weight-bold;
  font-size: 14px;
  line-height: 44px;
  padding: 0px 15px;
  background-color: rgb(135, 154, 181);
  border: 0;
}

[id].type-target {
  margin-top: -70px;
  padding-top: 70px;
}
.line-clamp{
  overflow: hidden;
text-overflow: ellipsis;
-webkit-line-clamp: 2;
display: -webkit-box;
-webkit-box-orient: vertical;
}
.clamp-line{
  overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-box-orient: vertical;
&-3 {
  
  -webkit-line-clamp: 3;
  }
&-5 {
  
-webkit-line-clamp: 5;
}
&-8 {
  
  -webkit-line-clamp: 8;
  }
}
.clamp-product-name {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 4;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

@for $i from 8 through 400 {
  .flex-#{$i}0 {
    flex: 0 0 #{$i}0px;
    max-width: #{$i}0px;
  }
  .flex-#{$i}0-rest {
    flex: 0 0 calc(100% - #{$i}0px);
    max-width: calc(100% - #{$i}0px);
  }
  @include media-breakpoint-up(lg) {
    .flex-lg-#{$i}0 {
      flex: 0 0 #{$i}0px;
      max-width: #{$i}0px;
    }
    .flex-lg-#{$i}0-rest {
      flex: 0 0 calc(100% - #{$i}0px);
      max-width: calc(100% - #{$i}0px);
    }
  }
}
.row.project-list-row {
  // margin-left: 0;
  // margin-right: 0;
  // > * {
  //   padding: 2px;
  // }
  .featured-item {
    padding: 2px;
    // + .featured-item {
    //   padding-top: 4px;
    // }
  }
}
.gap-1 {
  gap: 0.25rem;
}
.gap-2 {
  gap: 0.5rem;
}
.gap-3 {
  gap: 1rem;
}

.blurred-content-bg {
  // backdrop-filter: blur(15px);
  /* position: relative;
  background-attachment: fixed;
  background-size: cover;
  background-position: center center;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: inherit;
    filter: blur(15px);
    z-index: 0;
  } */
}

/* .bg-inherit {
  background: inherit;
} */

.absolute-100-100 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  &.flex-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
.hover-bg-light {
  &:hover {
    background-color: $gray-100;
  }
}

.more-count {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid $dark;
  margin: auto;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: $dark;
  font-weight: $font-weight-bold;
  font-family: $font-family-base;
}
.topbar-main-img-holder {
  width: 70px;
  height: 70px;
  overflow: hidden;
  &.shifted {
    margin-bottom: -20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.dual-icon-holder {
  width: 22px;
  height: 25px;
  display: inline-block;
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center 2px;
  }
}
.nav-link {
  &:hover, &.active {
    > .dual-icon-holder {
      img {
        object-position: center bottom;
      }
    }
  }
}
.business-icon {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: auto;
  width: 25px;
  height: 25px;
  display: inline-block;
  &._explore-menu {
    background-image: url("../assets/images/icons/explore-menu-about.png");
  }
  &._email {
    background-image: url("../assets/images/icons/email.png");
  }
  &._phone {
    background-image: url("../assets/images/icons/business-labels.png");
    background-position: 3px -21px;
  }
  &._map {
    background-image: url("../assets/images/icons/business-labels.png");
    background-position: 2px 2px;
  }
}

.carousel .thumb img {
    width: 100% !important;
    height: 100% !important;
}

.carousel .slide img {
    max-height: 440px;  /* change this to whatever you want */
    width: auto;
}
.show-slideDown {
  animation: slideDown 0.4s ease-in 1;
}
@keyframes slideDown {
  from {
    height: 0;
  }
  to {
     height: 100%;
  }
}
.c-gradient{
    background: -webkit-gradient(linear, 0 0, 0 bottom, from(#FDFCFA), to(#fcfcfa));
    background: -webkit-linear-gradient(#FDFCFA, #fcfcfa);
    background: -moz-linear-gradient(#FDFCFA, #fcfcfa);
    background: -ms-linear-gradient(#FDFCFA, #fcfcfa);
    background: -o-linear-gradient(#FDFCFA, #fcfcfa);
    background: linear-gradient(#FDFCFA, #fcfcfa);
}
.con-act-heading {
  color: $text-dark;
}
.type-dark {
  .con-act-heading {
    color: #d5dce5 !important;
  }
}
.con-act-tabs {
  border-bottom-color: #879ab5;
  .nav-item {
    margin-left: 5px;
    margin-right: 5px;
  }
  .nav-link {
    color: $text-dark;
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    padding-bottom: 0.5rem;
    &:hover {
      border-color: transparent;
    }
    &.active:hover {
      border-color: $text-dark;
    }
  }
}
.type-dark {
  .con-act-tabs {
    .nav-link {
      color: $white;
      &:hover {
        border-color: transparent;
      }
      &.active, &.active:hover {
        border-color: $white;
      }
    }
  }
}

.socialpressed {
    background: #879ab5;
}

.locations-list {
  .state {
    margin-bottom: 10px;
    > div {
      font-size: 15px;
      font-weight: bold;
    }
  }
  .cities {
    font-size: 14px;
  }
}
.StyledHeader{
  width: 207px !important;

}
.jumbo-page-header {
  background-color: $text-dark;
  color: $white;
  text-align: center;
  padding: 70px 20px 30px;

  h1 {
    font-family: $font-family-alt;
    font-size: 30px;
    font-weight: $font-weight-bold;
  }

  @include media-breakpoint-up(md) {
    padding: 80px 20px 40px;

    h1 {
      font-size: 50px;
    }
  }
}

.project-main-img {
  width: 100%;
  height: 200px;
  max-height: unset;
  object-fit: cover;
  object-position: center center;
  background-color: $text-dark;
}

.movie-thumbnail {
  width: 110px;
  max-height: unset;
}

.posted-video-img-holder {
  position: relative;
  width: 100px;
  height: 100px;
  border: 1px solid #ddd;
  padding: 2px;
  img {
    width: 100%;
    height: 100%;
    max-height: unset;
    object-fit: cover;
    object-position: center center;
  }
  .video-holder, .audio-holder {
    width: 100%;
    height: 100%;
  }
  .black-box {
    width: 100%;
    height: 100%;
    // position: absolute;
  }
}
.buyersCategory{
  text-transform: capitalize;
}

.blog-img {
  max-height: unset;
}
.featured-blurred-bg-shadow-none {
  &:after {
    display: none;
  }
}
.feeds-video-main {
  width: 320px;
  height: auto;
  max-height: initial;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.feeds-audio-main {
  width: 100%;
}
.feeds-img-main {
  max-width: 100%;
}

.feeds-media-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
  > li {
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 10px;
  }
}
.feeds-media-list-item {
  width: 98px;
  height: 98px;
  padding: 2px;
  border: 1px solid #ddd;
  cursor: pointer;
}

.feed-media-thumbnail {
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-height: initial;
}

.no-result-category{
  text-transform: capitalize;
  font-size: 44px;
  margin-bottom: 8px;
  font-family: $headings-font-family;
  text-shadow: 1px 1px 4px #000000;
  color: #fff;
  line-height: 1;
}

.font-awesome.fa, .font-awesome .fa{
  font-family: "Font Awesome 5 Brands", "Museo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight:501;
  content: '\f077';
}



// Reviews CSS
.html-review-wrapper, .comment-body-renderer {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6 {
    font-family: inherit;
    font-weight: 500;
    line-height: 1.1;
    color: inherit;
  }

  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3 {
    margin-top: 20px;
    margin-bottom: 10px;
  }

  h4,
  .h4,
  h5,
  .h5,
  h6,
  .h6 {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  h1,
  .h1 {
    font-size: 36px;
  }

  h2,
  .h2 {
    font-size: 30px;
  }

  h2,
  .h4 {
    color: #d1bb78;
  }

  h3,
  .h3 {
    font-size: 24px;
  }

  h4,
  .h4 {
    font-size: 18px;
  }

  h5,
  .h5 {
    font-size: 14px;
  }

  h6,
  .h6 {
    font-size: 12px;
  }

  h1 {
    font-size: 44px;
    margin-bottom: 8px;
    font-family: $headings-font-family;
    text-shadow: 1px 1px 4px #000000;
    color: #fff;
    line-height: 1;
  }

  h2 {
    font-size: 38px;
    padding-bottom: 12px;
    font-family: $headings-font-family;
    border-bottom: 1px solid #ede5cd;
    margin-top: 0;
    margin-bottom: 0;
    line-height: 1;
  }

  h5 {
    font-size: 15px;
    color: #726647;
    margin-bottom: 11px;
  }

  blockquote p:last-child,
  blockquote ul:last-child,
  blockquote ol:last-child {
    margin-bottom: 0;
  }

  .blockquote p {
    margin-bottom: 25px;
  }

  .blockquote p {
    margin-bottom: 25px;
  }

  p {
    line-height: 1.14;
    font-family: Arial, Helvetica, sans-serif;
    margin-bottom: 10px;
  }

  p {
    margin: 0 0 10px;
  }
}

// RichTextEditor
.RichTextEditor__root___2QXK- {
  font-size: 14px !important;
  font-family: $font-family-base !important;
  color: #15c3d4 !important;
  line-height: 1.2 !important;
  // padding: 10px 0 0 0 !important;
  * {
    font-family: inherit;
  }
  h1 {
    display: block;
    font-size: 2em;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: 500;
  }
  h2 {
    display: block;
    font-size: 1.5em;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: 500;
  }
  h3 {
    display: block;
    font-size: 1.17em;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: 500;
  }
  h4 {
    display: block;
    margin-block-start: 1.33em;
    margin-block-end: 1.33em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: 500;
  }
  h5 {
    display: block;
    font-size: 0.83em;
    margin-block-start: 1.67em;
    margin-block-end: 1.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: 500;
  }
  h6 {
    display: block;
    font-size: 0.67em;
    margin-block-start: 2.33em;
    margin-block-end: 2.33em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: 500;
    line-height: 1.5;
  }
  p {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }
  strong {
    font-weight: 500;
  }

  a {
    background: #d5d6e5;
    color: #325271;
    padding: 4px;
    margin-bottom: 2px;
    font-size: 12px;
    display: inline;
  }

  p {
    margin: 0;
    line-height: 16.8px;
    /*padding: 5px 20px;*/
  }


  .embedlink-box p {
    padding: 0;
  }

  /* h1 {
    font-size: initial;
    margin-bottom: initial;
    font-family: initial;
    text-shadow: unset;
    color: initial;
    line-height: initial;
  }

  h2 {
    font-size: initial;
    padding-bottom: initial;
    font-family: initial;
    border-bottom: unset;
    margin-top: initial;
    margin-bottom: initial;
    line-height: initial;
  }

  h5 {
    font-size: initial;
    color:initial;
    margin-bottom: initial;
  } */
}
.RichTextEditor__editor___1QqIU {
  font-size: 14px !important;
}


.add_info_type_add {
  background-color: $body-bg;
}
.uploadError{
  height: 55px;
  border-radius: 5px;
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
  padding-top: 5px !important;
  padding-left: 25px !important;
}

// Hide Image button on buyer's guide, Hack
.buyerguide-rte {
  .Button__root___1gz0c[title="Image"] {
    display: none;
  }
}

.add-connection{
	background: #325271;
    color: #fff;
    padding: 5px 14px;
    cursor: pointer;
    margin: 10px 0;
    display: inline-block;
}

.actorbutton{
	color: #fff !important;
    background-color: #15c3d4 !important;
    border-color: #15c3d4 !important;
}

.characters-length-text {
  font-size: 14px;
  float: right;
  color: #353535;
  font-family: $font-family-alt;
  margin-right: 40px;
  margin-top: -20px;
}
svg > g > g:last-child { pointer-events: none }

// Pagination
.pagination-wrapper {
  display: inline-flex;
  align-items: center;
  gap: 0.125rem;
}

.btn-pagination {
  padding: 0.25rem;
  color: $tertiary;
  font-family: $font-family-alt;
  background-color: transparent;
  + .btn-pagination {
    margin-left: 0 !important;
  }
  &:not(:disabled) {
    @include hover-focus() {
      color: $text-dark;
    }
  }
  &:active, &.active {
    color: $text-dark;
  }
}
.carouselCategoryDropdown{
  z-index: 1000000 !important;
}
.mediaContainer{
  position: relative;
  .mediaRating {
    position: absolute;
    inset: 2px;
    display: none;
    padding: 1em;
    justify-content: center;
    align-items: center;
    opacity: 0;

    .ratingInfo{
        color: #15c3d4;
        font-size: 18px;
        font-weight: 600;
        z-index: 100000;
    }
  }
  .media{
    opacity:1;
  }
  &:hover{
    .mediaRating{
      display: flex;
      flex-direction: column;
      justify-content: center;
      .rateMore{
        color: white;
        margin-top: 20px;
        font-size: 16px;
        font-weight: bold;
      }
      opacity: 1;
    }
    .media{
      opacity: .6 !important;
    }
    cursor: pointer;
    display: inline-block;
  }
}

body iframe[style*="position: fixed; top: 0px; left: 0px; width: 100%; height: 100%; border: medium none; z-index: 2147483647;"] {
  display: none;
}

body iframe[style*="position: fixed; top: 0px; left: 0px; width: 100%; height: 100%; border: none; z-index: 2147483647;"] {
  display: none;
}

.messageText {
  height: auto;

  &:focus {
    outline: none;
  }
}

.whatReviewersSay {
  height: 170px;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  
  &::-webkit-scrollbar {
    display: none;
  }
}

.cs-toolpop {
  background: #fff;
  outline: 3px solid $primary;
  max-width: 410px;


  .welcomeContent{
    font-size: 20px;
    font-weight: normal;

    b {
      font-family: 'Museo Sans 900';
    }
  }
  .actionCenter{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .container {
    width: 100%;
    padding: 0;

    .icon {
      flex: 1;
      padding: 4px;
      height: 100%;
      display: flex;
      justify-items: flex-start;

      img {
        height: 40%;
        width: 60%;
        object-fit: cover;
      }
    }
    .iconReverse {
      flex: 1;
      padding: 4px;
      height: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      img {
        object-fit: cover;
      }
    }

    .iconBottom {
      flex: 1;
      height: 100%;
      padding-top: calc(50% + 10px);

      img {
        height: 40%;
        width: 60%;
        object-fit: cover;
      }
    }

    .intro-block {
      padding: 15px 15px 15px 0;
      flex: 3;
      display: flex;
      flex-direction: column;
      gap: 12px;
      justify-content: space-around;

      span {
        font-size: 14px;
        text-align: left;
        line-height: 1.3;
        font-family: 'Museo Sans 500';
        color: #4a5f7a;
        padding: 5px 0;

        b,strong {
          font-family: 'Museo Sans 900';
        }
      }

      img {
        display: block;
        width: 100%;
        object-fit: contain;
      }

      .actionContainer {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 8px;

        button {
          max-width: max-content;

          &.btn-primary {
            min-width: 100px;
            font-family: 'Museo Sans 900';
            text-transform: uppercase;
            font-size: 15px;
            background-color: #15c3d4 !important;
          }
        }

        span {
          color: #94a6bf;
          font-size: 14px;
          font-weight: 300;
          text-decoration: underline;
          cursor: pointer;

        }
      }

    }
  }

  .sec-container {
    padding: 2px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    gap: 12px;

    .infoContainer {
      flex: 2;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 5px;
      padding: 8px;
      .icon {
        flex: 1;
        padding: 4px;

        img {
          height: 80%;
        }

        .intro-block {
          flex: 3;
          display: flex;
          text-align: justify;

          span {
            font-size: 12px;
            color: #4a5f7a;
            line-height: 1.14;
          }
        }
      }
    }

    .secImg {
      flex: 2;
      width: 100%;

      img {
        display: block;
        width: 100%;
        object-fit: contain;
      }
    }

    .actionContainer {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 8px;
      width: 100%;
      padding: 8px;

      button {
        max-width: max-content;
        background-color: #15c3d4 !important;
      }

      span {
        color: #94a6bf;
        font-size: 14px;
        font-weight: 300;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
  .mainAction{
    button{
      background-color: #48ccd9!important;
      &:hover{
        background-color: #48ccd9!important;
      }
    }
  }
}

.stepStart {
  outline: none !important;
  background: url('../assets/images/website-tour/t1-home.jpg');
  text-align: center;
  color: white !important;
  display: flex;
  max-width: 100% !important;
  width: 500px;
  // max-height: 400px !important;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 15px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);

  h4 {
    text-align: center;
    font-size: 46px;
    line-height: 1.2;
    font-family: 'League Gothic';
    font-weight: normal;
    font-style: normal;
  }

  p {
    font-size: 20px;
    font-weight: normal;
    font-family: 'Museo Sans 500';
    font-style: normal;
    margin-block: 15px 10px;
  }

  .actionCenter{
    display: flex;
    align-items: center;
    flex-direction: column;
    gap:0;
    margin-top: 10px;

    button {
      font-size: 13px;
      font-family: 'Museo Sans 500';
      padding: 0;
      
    }
  }

  button.btn-primary {
    width: 170px;
    line-height: 1;
    font-size: 18px;
    font-family: 'Museo Sans 900';
    padding: 10px 0;
    
  }
}

.max-height-500 {
  max-height: 500px !important;
}
.actionTextLink {
  font-size: 15px;
  font-weight: normal;
  color: white !important;
  text-decoration: underline;
  text-align: center;
  background-color: none !important;
  font-family: 'museo_sans500', Arial;

  &:hover{
    text-decoration: none;
  }
}

.navbarItem {
  display: flex;
  align-items: center;

  .step-2 {
    flex: 2;
  }

  .crp_nav_item {
    flex: 1;
  }
}

.position {
  display: flex;
  align-items: flex-start;
}

.position-reverse {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  margin: 10px 0 10px 10px;
}
.startBannerHeader{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.startBannerIcon {
  margin: 5px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    height: 40%;
    width: 50%;
  }
}

.__floater__arrow {
  svg {
    stroke: $primary;
    stroke-width: 2.5px;
    stroke-dasharray: 45px;
  }
}

#react-joyride-step {
  &-1 {
    .cs-toolpop {
      padding-top: 18px;
      padding-right: 10px;
    }

    img {
      width: 78px;
      height: 81px;
    }

    button.btn-primary {
      margin-right: 10px;
    }

    .actionContainer {
      padding-bottom: 10px;

      span {
        white-space: nowrap;
      }
    }

    .icon {
      margin: 15px;
      padding: 0;
    }
  }
  &-2 {
    .cs-toolpop {
      padding-top: 20px;
      padding-right: 20px;
    }

    .intro-block {
      margin-block: 10px; 
    }
    img {
      width: 101px;
      height: 118px;
      margin-right: 8px;
    }
  }
  &-3 {
    img {
      width: 86px;
      height: 89px;
      margin: 15px;
    }

    .intro-block {
      margin-block: 10px;

      b {
        display: block;
      }
    }
  }
  &-4 {
    .icon {
      padding: 0;
      margin-right: 10px;
    }

    img {
      width: 100%;
    }
  }

  &-5 {
    .icon {
      margin: 15px;

      img {
        width: 100%;
      }
    }
  }
}

.__floater {
  .stepStart-2 {
    max-width: 400px !important;

    .actionCenter button {
      font-size: 15px;
    }

    img {
      width: 140px;
    }

    h4 {
      font-size: 40px;
      margin-bottom: 15px;
    }

    .btn-primary {
      font-size: 15px;
      line-height: 1;
      text-transform: uppercase;
      padding: 6px 0;
    }
  }

  .ls-1 {
    &-2 {
      .container {
        .intro-block {
          padding: 17px 4px 16px 0 !important;
        }
      }

    }

    &-3 {
      .container {
        .intro-block {
          padding: 17px 4px 16px 0 !important;
        }
      }
    }

    &-4 {
      .container {
        .intro-block {
          margin-block: 0px !important;
        }

        .icon {
          img {
            width: 86px !important;
            height: 89px !important;
            margin-left: 0px !important;
          }
        }
      }

    }

    &-5 {
      .container {
        .intro-block {
          padding: 15px 15px 16px 0 !important;
        }
      }
    }

    &-8 {
      .container {
        .intro-block {
          padding: 0 !important;

          .infoContainer {
            .icon {

              padding: 0 !important;

              img {
                height: 90px !important;
                width: 90px !important;
                margin: 15px;
              }

            }

            .intro-block {
              padding: 0 !important;

              span {
                padding: 15px 15px 15px 0 !important;
              }
            }
          }
        }
      }
    }
  }

  .ls-2 {
    &-1 {
      padding-top: 0 !important;
      padding-right: 0 !important;

      .icon {
        margin-right: 0 !important;
      }

      b {
        font-size: 13px;
      }
    }

    &-4,
    &-2 {
      padding-top: 0 !important;
      padding-right: 0 !important;

      .intro-block {
        padding-left: 20px;
        padding-top: 0;
        padding-bottom: 0;

        p {
          margin-bottom: 8px;

          b {
            color: #00162B;
            font-size: 15px;
          }
        }

      }

      .position-reverse {
        align-items: flex-end;
        margin: 0;

        .iconBottom {
          padding: 0;
        }
      }
    }

    &-3 {
      b {
        display: inline !important;
        color: #00162B;
      }
    }

    &-4.cs-toolpop {
      padding-top: 20px !important;

      .intro-block {
        padding-bottom: 20px;
      }
    }

    &-6 {
      img {
        width: 100% !important;
      }
    }
  }

  .stepStart-3 {
    p {
      margin-top: 0;
      line-height: 1;
      margin-bottom: 30px;
    }

    h4 {
      font-size: 30px;
    }

    .actionCenter {
      margin-top: 20px;
    }
  }

  .ls-3 {
    &-0 {
      max-width: 400px !important;

      .startBannerHeader {
        .startBannerIcon {
          img {
            width: 95px !important;
            height: 97px !important;
            margin: 5px auto !important;
          }

        }

        h4 {
          font-size: 40px;
        }
      }

      .actionCenter {
        margin-top: 8px !important;
      }
    }

    &-1 {
      padding-top: 0 !important;
      padding-right: 0 !important;
    }

    &-2.cs-toolpop {
      padding-right: 0 !important;
      padding-top: 10px !important;
      padding-left: 5px;
      max-width: 390px;

      .intro-block {
        margin-top: 0 !important;
      }

      .icon {
        margin-right: 10px;

        img {
          width: 100% !important;
          height: auto !important;
        }
      }
    }

    &-3 {
      b {
        display: inline !important;
      }

      .icon {
        img {
          margin-left: 10px !important;
        }
      }
    }
  }

  .ls-4 {
    &-1 {
      padding-right: 0px !important;

      .container {
        .icon {
          margin: 0px 20px 0px 0px !important;
          flex-direction: row-reverse;

          img {
            width: 100%;
          }
        }

        .intro-block {
          padding: 0px !important;
        }
      }

    }

    &-2 {
      padding: 0 !important;

      .container {
        padding: 0 !important;
        width: 380px !important;
        max-width: 380px !important;

        .icon {
          margin: 15px 0 0 5px;

          img {
            width: 77px !important;
            height: 80px !important;
          }
        }

        .intro-block {
          padding-bottom: 5px;
          margin-block: 0px !important;
          gap: 0px !important;
        }
      }

    }

    &-3 {
      .container {
        max-width: 380px !important;
        padding-top: 10px !important;

        .icon {
          img {
            height: auto !important;
            width: 75px !important;
            margin: 0 !important;
          }
        }

        .intro-block {
          margin-block: 0 !important;
          padding: 0 !important;
          grid-gap: 0 !important;
          gap: 0 !important;

          span {
            padding: 0 !important;

            p {
              margin-bottom: 0 !important;
            }
          }

          img {
            width: 226px !important;
            height: 93px !important;
            margin: 0 !important;
          }

          .actionContainer {
            margin-top: 10px !important;
            margin-bottom: 10px !important;
          }
        }

      }




    }

    &-4 {
      max-width: 360px !important;

      b {
        display: inline !important;
      }

      .infoContainer {
        padding: 0 !important;
      }

      .icon {
        img {
          margin: 0 !important;
        }
      }

      .intro-block {
        margin: 0 !important;
        padding-right: 0;
        gap: 0;

        img {
          margin: 0 !important;
          width: 100% !important;
          height: auto !important;
        }
      }

      .secImg {
        box-shadow: none !important;
        margin: 10px 10px !important;
      }

      .actionContainer {
        justify-content: flex-start !important;
        padding-inline: 12px !important;
      }
    }

    &-12,
    &-11,
    &-9,
    &-8,
    &-7,
    &-6,
    &-5 {
      .position-reverse {
        margin: 0 !important;
      }

      .iconReverse {
        padding-right: 0;

        img {
          width: 75px !important;
          margin-top: 10px;
        }
      }

      .intro-block {
        padding-left: 10px;
        padding-right: 0px;
      }
    }

    &-6 {
      max-width: 375px !important;

      .intro-block {
        padding-right: 0 !important;
      }
    }

    &-7 {
      max-width: 380px !important;

      .container .iconReverse {
        img {
          width: 100px !important;
        }
      }

      .intro-block {
        padding-right: 0 !important;
        gap: 0 !important;
      }
    }

    &-8 {
      max-width: 380px !important;

      .iconReverse {
        img {
          max-height: 110px;
          width: auto !important;
        }
      }

      .intro-block {
        gap: 0 !important;
        padding-top: 10px;
        padding-right: 0 !important;
        padding-left: 15px !important;
      }
    }

    &-9 {
      .container .iconReverse img {
        width: 120px !important;
      }

      .intro-block {
        padding-inline: 15px 0 !important;
        gap: 0 !important;
      }
    }

    &-11 {
      .iconReverse {
        max-width: 100px;

        img {
          width: 90px !important;
        }
      }

      .intro-block {
        padding-right: 0 !important;
      }
    }

    &-12 {
      .iconReverse {
        max-width: 60px;

        img {
          max-width: 100%;
        }
      }

      .intro-block {
        padding-right: 0 !important;

        b {
          display: block !important;
        }
      }
    }
  }

  .ls-5 {
    &-1 {
      padding-right: 0px !important;
      padding-bottom: 0px !important;
      padding-top: 0px !important;
      overflow: hidden;
      .container{
        .iconReverse {
          vertical-align: middle;
  
          img {
            height: auto !important;
            width: auto !important;
          }

        }
        .intro-block {
          padding-bottom: 0px !important;
          padding-top: 0px !important;
        }
      }
      

    }

    &-4 {
      padding-right: 0px !important;
      padding-top: 0px !important;
      overflow: hidden;

      .container {
        .intro-block {
          margin-block: 0px !important;
        }
      }

      .icon {
        padding-left: 0px !important;
        justify-items: flex-end !important;
        align-items: flex-end;
        flex-direction: row-reverse;

        img {
          margin: 17px 6px 0px 10px !important;
          width: 67px !important;
          height: 76px !important;
        }

      }
    }

    &-5 {
      padding-right: 0px !important;
      padding-top: 0px !important;
      overflow: hidden;

      .position-reverse {
        margin-top: 0px !important;

        .iconReverse {
          img {
            margin: 0px 12px !important;
            height: auto !important;
            width: 100px !important;
          }

        }
      }


    }
  }

  .ls-6 {
    &-1 {
      padding: 0 !important;

      .container {
        width: 380px;

        .icon {
          margin: 15px 0px 0px 0px !important;

        }

        .intro-block {
          padding: 15px 0px 15px 0 !important;
          grid-gap: 0px !important;
          gap: 0px !important;

          .actionContainer {
            padding-bottom: 0 !important;
          }
        }
      }
    }

    &-2 {
      padding: 0 !important;

      .container {
        .icon {
          padding-top: 5px;

          img {
            width: 94px !important;
            height: 87px !important;
          }
        }

        .intro-block {
          margin-block: 0px !important;
          gap: 0px !important;
        }

      }

    }

    &-3 {
      .intro-block {
        padding: 0px !important;
      }
    }
  }
}
.chooseAPerson-rating {
  display: flex;
  align-items: center;

  label {
    max-width: 360px;
  }

  .stars-rating-input {
    margin-right: auto;
  }
}
.public-DraftEditor-content { min-height: 110px; }

.menu_img{
  width: 50px;
  height: 50px;
  object-fit: cover;
}

.vocab-search-input::placeholder {
  font-weight: bold;
  color: #d1bb78 !important;
}
.rounded-circle {
  object-fit: cover;
  object-position: center center;
  vertical-align: middle;
}

.listing-profile-image {
  object-fit: cover;
  object-position: center center;
  vertical-align: middle;
  aspect-ratio: 1/1;
}
.customOption {
  display: flex;
  gap: 5px;
  align-items: center;

  .friendTag {
    margin-right: 5px;
    font-size: 12px;
    text-align: center;
    padding: 5px;
    color: #a8a8a8;
  }
}
.react-select-container{
  margin: 8px 5px;
  &:focus {
    border: 1px solid #15c3d4 !important;
  }
  
}
.fetching {
  display: flex;
  justify-content: center;
  font-size: 16px;
  padding: 20px 0;
  font-weight: 200;
}

.secImg.pt-0 {
  box-shadow: inset -5px -11px 12px -14px rgb(105, 105, 105);
  margin: 0 20px;
  width: auto !important;
  padding-bottom: 0 !important;

  .form-group {
    margin-bottom: 0;
  }

  .form-control {
    max-width: 120px;
    max-height: 30px;
  }

  p {
    margin-bottom: 0;

    &:empty {
      display: none;
    }
  }

  .cities {
    border: 1px solid #e6e6e6;

    li {
      border-bottom: 1px solid #e6e6e6;

      span {
        display: flex;
        align-items: center;

        img {
          max-width: 20px;
          margin: 2px 10px;
        }

        small {
          margin-left: 5px;
          position: relative;
          top: 3px;
        }
      }
    }
  }

  .catlist {
    border: 1px solid #e6e6e6;
    max-width: max-content;
    margin: auto;

    li {
      display: flex;
      padding: 5px;
      padding-right: 45px;
      border-bottom: 1px solid #e6e6e6;

      img {
        max-width: 20px;
        margin: 2px 10px;
      }
    }
  }

  .input-group {
    .input-group-addon {
      height: 30px;
      width: 60px;
      background: #869ab5;
      padding: 5px 10px;

      img {
        width: auto;
      }
    }
    .form-control {
      max-width: 220px;
    }

    .fa-search {
      position: absolute;
      right: 8px;
      top: 8px;
    }
  }

  .rest-food {
    li {
      display: flex;
      padding: 10px;
      gap: 12px;
      border: 1px solid #e6e6e6;
      max-width: 280px;

      img {
        width: 62px;
      }
    }
    h3 {
        font-size: 17px;
        font-family: 'Roboto', sans-serif;
        margin-bottom: 4px;
    }
  }

  .food-group {
    max-width: 280px;
    margin-left: auto;
  }
}

.nowExplore {
  max-width: 370px;

  .container {
    max-width: 352px !important;

    .intro-block {
      padding: 0 !important;

      .infoContainer {
        .icon {

          padding: 0 !important;

          img {
            height: 90px !important;
            width: 90px !important;
            margin: 15px;
          }

        }

        .intro-block {
          padding: 0 !important;

          span {
            padding: 15px 15px 15px 0 !important;
          }
        }
      }
    }
  }
}

.searchRestaurant {
  max-width: 400px;

  .form-group {
    max-width: 320px;
    margin-left: auto;
  }
}

.browserReview {
  overflow: hidden;

  .icon {
    margin-left: -30px !important;
  }
}

.input-box {
  input {
    border: 1px solid #15c3d4;
    width: 120px;
    height: 26px;
  }

  .fa-pencil-alt {
    margin-left: 20px;
    color: #15c3d4;
  }
}

.baloons-image {
  width: 133px !important;
  margin-top: 40px !important;
}

.time-image-outer {
  img {
    width: 60px !important;
  }

  .welcomeContent {
    font-size: 16px;
    text-align: left;
    margin-left: 30px;
  }
}

.cs-toolpop.tourEndThanks {
  max-width: 400px !important;

  h4 {
    font-size: 36px;
  }
}

.crp-start {
  max-width: 450px !important;
  padding-block: 30px;
  
  h4 {
    text-align: left;
    font-size: 48px;
  }
  p {
    font-size: 15px !important;
  }
}

.nav-item-link-white {
  text-decoration: none !important;
  color: $white;

  &:hover,
  &:active {
    color: $white;
  }
}

.upload-pdf-cutsheet {
  input {
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0;
    pointer-events: none;
  }
}

.step-3_myProfile{
  img{
    width: 270px !important;
  }
}

.wr-movie-less-info-about {
  span {
    .text {
      font-family: 'Museo Sans 900' !important;

      &:hover {
        color: #2a6496 !important;
      }
    }
  }
}
.tourStep-2_movie{
  span{
    font-family: 'Museo Sans 900';
  }
}
.offer-uploaded-media-thumbnail {
  height: 95px !important;
  width: 112px !important;
  
  img{
    object-fit: cover !important;
    width: 102px !important;
    height: 85px !important;
  }
  
}
.write-own-review {
  span {
    float: right;
    color: #15c3d4;
    font-family: "Museo Sans 900";
    text-decoration: none;
    font-size: 14px;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
}

.login-prompt-modal {
  width: 420px;
  
}

.delete-box {
  .modal-content {
    width: 350px;

    .modal-header {
      text-align: center;
      padding: 15px;

      h5 {
        margin-bottom: 5px;
        color: #325271;
        font-size: 32px;
      }

      .btn {
        float: right;
        padding: 0;
        font-size: 21px;
        font-family: 'Museo Sans 900';
        line-height: 1;
        color: #000;
        text-shadow: 0 1px 0 #fff;
        opacity: .2;
        margin-bottom: auto;

        &:hover {
          text-decoration: none;
          cursor: pointer;
          opacity: .5;
        }
      }
    }

    .modal-body {
      padding: 0;
      font-weight: 500;
      font-size: 14px;
    }

    .modal-footer {
      padding: 15px;
    }

  }
}
.admin {
  display: flex;
  background-color: #fff;
  min-height: 100vh;
  height: 100%;


  &-sidebar {
    flex: 1;
    background-color: #f8f9f9;
    position: sticky;

    .sidebarBrand {
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      text-align: center;
      flex-direction: column;

      h4 {
        font-family: "Museo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
        font-size: 24px;
        font-weight: 600;
        color: black;
        cursor: pointer;

        &:hover {
          color: #999;
        }
      }
    }

    .sidebarWrapper {
      padding: 20px;
      color: #555;
      top: 50px;
    }

    .sidebarMenu {
      margin-bottom: 10px;
    }

    .sidebarTitle {
      font-size: 1.7rem;
      color: rgb(187, 186, 186);
    }

    .sidebarList {
      list-style: none;
      padding: 5px;
    }

    .sidebarListItem {
      padding: 5px;
      cursor: pointer;
      display: flex;
      align-items: center;
      border-radius: 10px;
    }

    .sidebarListItem.active,
    .sidebarListItem:hover {
      background-color: #e9e9e9;
    }
  }

  &-container {
    flex: 4;
    display: flex;
    flex-direction: column;
    padding: 10px;

    .main-content {
      padding: 10px;

      .main-data-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        border-radius: 4px;
        margin-bottom: 20px;
        box-shadow: 0 1px 1px #262525;
        border: .7px solid #ddd;
        ;

        .table-header {
          width: 100%;
          background-color: #e9e9e9;
          display: flex;
          justify-content: center;
          align-items: center;

          .table-title {
            font-size: 2rem !important;
          }
        }

        .table-container {
          padding: 10px;
          min-width: 100%;

          .search-container {
            display: flex;
            justify-content: flex-start;
            margin-bottom: 8px;

            input {
              max-width: 30%;
              margin-right: 5px;
              border-radius: 4px;
            }

            button {
              border-radius: 4px;
              background-color: #efefee;
              border: none;

              &:hover {
                background-color: #dedede;
              }
            }
          }

          table {

            tbody {
              span {
                font-size: 14px;
                font-weight: normal;
              }

              .review-content {
                max-width: 450px;
                word-wrap: break-word !important;
                display: flex;
                flex-wrap: wrap;
                overflow-x: auto;

              }

              .listing-name {
                max-width: 150px !important;
                word-wrap: break-word !important;
                display: flex;
                flex-wrap: wrap;
                overflow-x: auto;

              }

              .enable-button {
                background-color: #32dfbc;
                color: white;
                border-radius: 4px;

                &:hover {
                  background-color: #28b79b;
                }
              }

              .disable-button {
                background-color: #ec6d4e;
                color: white;
                border-radius: 4px;

                &:hover {
                  background-color: #da6244;
                }
              }
            }
          }

        }


      }

    }
  }

}

.modal-dataTable {

  span {
    font-size: 14px;
    font-weight: normal;
  }

  .listing-name {
    max-width: 150px !important;
    word-wrap: break-word !important;
    display: flex;
    flex-wrap: wrap;
    overflow-x: auto;

  }

  .review-content {
    max-width: 350px !important;
    word-wrap: break-word !important;
    display: flex;
    flex-wrap: wrap;
    overflow-x: auto;
  }

  .enable-button {
    background-color: #32dfbc;
    color: white;
    border-radius: 4px;

    &:hover {
      background-color: #28b79b;
    }
  }

  .disable-button {
    background-color: #ec6d4e;
    color: white;
    border-radius: 4px;

    &:hover {
      background-color: #da6244;
    }
  }
}

.review-media-modal {
  max-width: 700px;
  min-height: 400px;

  .react-multiple-carousel__arrow::before {
    color: #6c757d !important;

    &:hover {
      color: #343a40 !important;
    }
  }

  .react-multi-carousel-track {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .carsousel-media-conatiner {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .media-holder {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;

      img {
        max-width: 450px;
        object-fit: contain;
        cursor: pointer;
      }


    }


    .media-action-container {
      .enable-button {
        background-color: #32dfbc;
        color: white;
        border-radius: 4px;

        &:hover {
          background-color: #28b79b;
        }
      }

      .disable-button {
        background-color: #ec6d4e;
        color: white;
        border-radius: 4px;

        &:hover {
          background-color: #da6244;
        }
      }
    }
  }



}
.notification-item{
  border-top: 1px solid #00162b;
  padding: 5px 15px;
  margin: 5px 0;
  line-height: 1;
  font-size: 14px !important;
    img{
      width: 30px !important;
      height: 30px !important;
      border-radius: 25px;
      object-fit: cover;
    }
  
}
.zero-star-text{
  background-color: #15c3d4;
  margin-left: 4px;
  padding: 4px;
}
 .margin-right{
  &-2{
    margin-right: 2px !important;
  }
 }

 .cross-icon {
  color: #325271;
  opacity: 0.3;
  cursor: pointer;

  &:hover {
    font-weight: bold;
    opacity: 0.8;
  }
 }
 
.more-link-home {
  margin-top: 0 !important;

  a {
    a {
      width: 50px;
      height: 50px;
      display: flex;
      align-items: start;
      justify-content: flex-start;
      align-content: flex-start;
      flex-direction: column-reverse;
    }
  }
}

.museo-sans {
  &-500 {
    font-family: "Museo Sans 500" !important;
  }

  &-900 {
    font-family: "Museo Sans 900" !important;
  }
}

.disable-link{
  pointer-events: none;
}
.nav-link-decoration-none {
  text-decoration: none !important;
  cursor: pointer;
  &.option-active{
    color: #335371 !important;
  }
  &.tertiary-link {
    color: #93a2b7 !important
  }
}


.star-filter-input-checkbox {
  
  -webkit-appearance: radio !important;
  -moz-appearance: radio !important;
  appearance: radio !important;
  box-shadow: none !important;
  height: 13px !important;
  width: 13px !important;
}

.rating-star {
  color: #355472;

  &:hover {
    color: rgb(21, 195, 212) !important;
  }
}

.custom-icon-size{
  font-size: 22px;
 
}

.custom-consent-checkbox{
  display: flex;
}

.link-transition {
  .main-content {
    min-height: 100vh;
    min-width: 100vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    padding-top: 8rem;
    align-items: center;
    text-align: center;
  }
}

a.home-internal-link {
  &:hover, &:focus {
    color: #326085 !important;
    text-decoration: none;
  }
}

.custom-feed-input{
  &::placeholder{
    color: #15c3d4;
    font-weight: bold;
  }
}


.phone-otp-verify{
  max-width: 450px !important;
  min-height: 450px !important;
  height: 450px;

  padding: 0 !important;

  &-title{
    max-width: 450px !important;
  }

  .body {
    background-color: #f0f1f6 !important;
    min-width: 400px !important;

    .resend-code {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      max-width: 400px !important;
    }
  }
}

.custom-otp-modal {
  .modal-content {
    justify-content: center;
    display: flex;
    align-items: center;
  }
}


.recommend-carousel {
  .react-multi-carousel-list{
    position: unset !important;
  
    .react-multiple-carousel__arrow--left {
      margin-left: 8.5rem;
    }
  
    .react-multiple-carousel__arrow--right {
      margin-right: 8.5rem;
    }
  }
}

.flip-card-recommend {
  background-color: transparent;
  width: 100%;
  padding-left: 12px;
  padding-right: 12px;
  height: 200px;
  perspective: 1000px;
}

.flip-card-inner-recommend {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform;
  transform-style: preserve-3d;
  // box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.flip-card-inner-normal {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform;
  transform-style: preserve-3d;
  // box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.flip-card-recommend:hover .flip-card-inner-recommend {
  transform: rotateX(180deg);
}

.flip-card-front-recommend,
.flip-card-back-recommend {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-front-recommend {
  color: black;
}

.flip-card-back-recommend {
  color: blue;
  transform: rotateX(180deg);
}

.dark-overlay {
  opacity: 0.2;
}

.recommend-action {
  position: absolute;
  width: 75%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.close-btn {
  opacity: 0.5;

  &:hover {
    opacity: 0.8;
  }
}

