@each $color,
$value in $theme-colors {
  .btn-#{$color} {
    &:not(:disabled):not(.disabled):focus, &:not(:disabled):not(.disabled):active:focus {
      box-shadow: inset 0 3px 5px rgba($black, .125) !important;
    }
  }
}
@each $color,
$value in $social {
  .btn-#{$color} {
    @include button-variant($value, $value);

    &:not(:disabled):not(.disabled):focus, &:not(:disabled):not(.disabled):active:focus {
      box-shadow: inset 0 3px 5px rgba($black, .125) !important;
    }
  }
}

@each $color, $value in $social {
  .btn-outline-#{$color} {
    @include button-outline-variant($value);
  }
}

.btn{
  font-weight: $font-weight-bold;
  font-size: 14px;
  & + &:not(.btn-block){
    margin-left: 15px;
  }
  &.mw {
    min-width: 100px;
    text-align: center;
  }
  &-xs {
    padding: 0.25rem 0.25rem;
    line-height: 1.2;
  }
}

.btn-link {
  &:focus {
    box-shadow: none !important;
  }
  &.as-text {
    color: inherit;
    padding: 0;
    line-height: initial;
    height: initial;
    vertical-align: initial;
  }
}

.btn-sm {
  &.mw {
    min-width: 80px;
  }
}

.btn-lg {
  font-size: 16px;
  &.mw {
    min-width: 120px;
  }
}

.btn-transparent {
  color: $primary;
  @include hover-focus {
    color: $primary;
    text-decoration: underline;
    // background-color: $gray-100;
    box-shadow: none;
  }
}

.btn-hollow {
  color: $gray-900;
  background-color: transparent !important;
  @include hover-focus {
    color: darken($gray-900, 10%);
  }
  &.light {
    color: $gray-100;
    @include hover-focus {
      color: $white;
    }
  }
}
.btn-icon-split {
  padding: 0;
  overflow: hidden;
  display: inline-flex;
  align-items: stretch;
  justify-content: center;
  .icon {
    background: rgba(0,0,0,0.15);
    display: inline-block;
    padding: .375rem .75rem;
  }
  .text {
    display: inline-block;
    padding: .375rem .75rem;
  }
  &.btn-sm {
    .icon, .text {
      padding: 0.25rem 0.5rem;
    }
  }
}
.btn-circle {
  height: 2.5rem;
  width: 2.5rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border-radius: 100%;
  &.btn-xs {
    height: 1rem;
    width: 1rem;
    font-size: 0.5rem;
  }
  &.btn-md {
    height: 1.5rem;
    width: 1.5rem;
    font-size: 0.75rem;
  }
  &.btn-lg {
    height: 4rem;
    width: 4rem;
  }
}

button.close {
  &.btn-sm {
    font-size: 1rem;
  }
}

.btn-grey:not(:disabled), .btn-tertiary:not(:disabled) {
  @include hover-focus() {
    background-color: darken($primary, 7.5%);
    border-color: darken($primary, 7.5%);
    color: color-yiq(darken($primary, 7.5%));
  }
}

.btn-tertiary.reset {
  @include hover-focus() {
    background-color: darken($tertiary, 7.5%);
    border-color: darken($tertiary, 7.5%);
    color: color-yiq(darken($tertiary, 7.5%));
  }
}

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    .btn#{$infix}-inline-block {
      display: inline-block;
      width: initial;
    }
  }
}

.btn-group {
  &.type-filter {
    margin: 0;
    .item {
      margin-right: 1rem;
      font-size: 14px;
      .count {
        font-family: $font-family-alt !important;
        font-size: 12px !important;
        font-weight: $font-weight-normal !important;
        padding: 3px 6px !important;
        margin-left: 6px;
        min-width: 20px;
        height: 20px;
        background-color: $primary !important;
        color: $white !important;
        align-self: center;
        text-align: center;
        line-height: 14px !important;
        border-radius: 10px;
      }
    }
    .btn {
      color: $tertiary;
      font-weight: $font-weight-bold;
      padding: 0.25rem 0 0;
      border: 0;
      border-bottom: 3px solid transparent;
      &.active {
        color: $text-dark;
        border-bottom-color: transparent;
      }
      @include hover-focus {
        background-color: transparent;
        box-shadow: none;
        color: $text-dark;
        text-decoration: none;
      }
    }
  }
}
.a-btn {
  line-height: 45px;
  height: 45px;
  padding: 0 20px;
}
.btn.btn-confirm {
  min-width: 80px;
}
.btn-delete-media {
  border: 1px solid #ccc;
  background: rgba(220, 220, 220, 0.52);
  width: 30px;
  height: 30px;
  color: #333;
  padding: 5px;
  border-radius: 4px;
  &._alt {
    background-color: $white;
    padding: 6px 12px;
    width: initial;
    height: initial;
    @include hover-focus {
      color: #333;
      background-color: #e6e6e6;
      border-color: #adadad;
    }
  }
}
.btn-text-dark {
  background-color: $text-dark;
  color: $white;
  @include hover-focus {
    color: $white;
  }
}

.btn-filterable {
  color: $tertiary;
  background-color: transparent;
  padding: 0;
  &:hover, &.active {
    color: $text-dark;
  }
  &:focus {
    box-shadow: none;
  }
}

.close.modal-type-close {
  opacity: 1;
  color: $white;
  font-weight: 100;
  &:before {
    content: 'Close ';
    display: inline;
    font-family: $font-family-base;
    font-weight: $font-weight-normal;
    font-size: 14px;
  }
  &:after {
    content: '';
    background-image: url("../../assets/images/sprite.png");
    background-repeat: no-repeat;
    display: inline-block;
    height: 12px;
    line-height: 14px;
    margin-top: 1px;
    vertical-align: text-top;
    width: 13px;
    background-position: -228px -289px;
    margin-left: 10px;
    margin-top: 2px;
    vertical-align: middle;
  }
  span {
    display: none;
  }
  @include hover-focus() {
    outline: 0;
  }

  &._dark {
    text-shadow: none;
    &:before {
      color: $text-dark;
      font-weight: $font-weight-bold;
    }
    &:after {
      background-position: -602px 0px;
    }
  }
}
.btn-withoutbg 
{
  background: none;
}
.btn-googleNew {
	color: #fff !important;
    background-color: #d34836 !important;
    border-color: #d34836 !important;
	font-size: 14px !important;
	
}
.btn-on-focus-none {
  &:focus {
    outline: none !important;
    box-shadow: none;
  }
}
@-webkit-keyframes glowing {
  0% { background-color: #15c2d4; -webkit-box-shadow: 0 0 3px #fff; }
  50% { background-color: #15c2d4; -webkit-box-shadow: 0 0 10px #fff; }
  100% { background-color: #15c2d4; -webkit-box-shadow: 0 0 3px #fff; }
}

@-moz-keyframes glowing {
  0% { background-color: #15c2d4; -moz-box-shadow: 0 0 3px #fff; }
  50% { background-color: #15c2d4; -moz-box-shadow: 0 0 10px #fff; }
  100% { background-color: #15c2d4; -moz-box-shadow: 0 0 3px #fff; }
}

@-o-keyframes glowing {
  0% { background-color: #15c2d4; box-shadow: 0 0 3px #fff; }
  50% { background-color: #15c2d4; box-shadow: 0 0 10px #fff; }
  100% { background-color: #15c2d4; box-shadow: 0 0 3px #fff; }
}

@keyframes glowing {
  0% { background-color: #15c2d4; box-shadow: 0 0 3px #fff; }
  50% { background-color: #15c2d4; box-shadow: 0 0 10px #fff; }
  100% { background-color: #15c2d4; box-shadow: 0 0 3px #fff; }
}


.wk-new-button-glow {
  -webkit-animation: glowing 1500ms infinite;
  -moz-animation: glowing 1500ms infinite;
  -o-animation: glowing 1500ms infinite;
  animation: glowing 1500ms infinite;
  }