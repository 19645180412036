.body-bg {
    background-color: $body-bg;
}
.bg-white-shaded {
    background-color: rgba($color: $white, $alpha: 0.95) !important;
}
.bg-white-shaded-md {
    background-color: rgba($color: $white, $alpha: 0.75) !important;
}
.bg-white-shaded-sm {
    background-color: rgba($color: $white, $alpha: 0.5) !important;
}
.bg-black-shaded {
    background-color: rgba($color: $black, $alpha: 0.95) !important;
}
.bg-black-shaded-md {
    background-color: rgba($color: $black, $alpha: 0.75) !important;
}
.bg-black-shaded-sm {
    background-color: rgba($color: $black, $alpha: 0.5) !important;
}
.bg-dark-shaded {
    background-color: rgba($color: $dark, $alpha: 0.95) !important;
}
.bg-dark-shaded-md {
    background-color: rgba($color: $dark, $alpha: 0.75) !important;
}
.bg-dark-shaded-sm {
    background-color: rgba($color: $dark, $alpha: 0.5) !important;
}
.bg-darker {
    background-color: rgba($color: $darker, $alpha: 1) !important;
}
.bg-darker-shaded {
    background-color: rgba($color: $darker, $alpha: 0.95) !important;
}
.bg-darker-shaded-md {
    background-color: rgba($color: $darker, $alpha: 0.75) !important;
}
.bg-darker-shaded-sm {
    background-color: rgba($color: $darker, $alpha: 0.5) !important;
}
.bg-business-bar {
    background-image: url("../../assets/images/business-bar.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}
.bg-movie-dark {
    background-color: #0e2339 !important;
}
.bg-dark-alt {
    background-color: $text-dark !important;
}

.bg-secondary-light {
    background-color: #ede5cd !important;
}
.bg-lightish {
    background-color: #f2f3f4 !important;
}
.bg-greyish {
    background-color: #d5dce5 !important;
}
.review-bg {
    background-color: #f0f1f6 !important;
}

/* .jury-bg {
    background-image: url("../../assets/images/jury-popup-bg.png");
    background-size: cover;
    background-position: bottom center;
} */