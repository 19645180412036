.input-label-block__control {
  background: #fff;
  min-height: 120px;
  padding-left: 4px;
  font-size: 14px;
  line-height: 16.8px;
}

.input-label-block__control textarea {
  border: 1px solid #3fb9de;
  margin-top: 20px;
  outline: none;
  padding: 20px;
  color: #3fb9de;
}

.input-label-block__control textarea::placeholder {
  color: #3fb9de;
  font-size: 14px;
}

.input-label-block__control__highlighter__caret {
  border: 0;
}
