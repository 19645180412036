$scrollbar-width: 6px;
$scrollbar-height: 6px;
.scrollbar {
    &::-webkit-scrollbar {
        width: 0px;
        height: 0px;
        &-thumb {
            background: transparent;
        }
        &-track {
            background: transparent;
        }
    }
    &:hover{
        &::-webkit-scrollbar {
            width: $scrollbar-width;
            height: $scrollbar-height;
            &-thumb {
                background-color: rgba($color: $black, $alpha: 0.2);
                &:hover {
                    background-color: rgba($color: $black, $alpha: 0.4);
                }
            }
        }
        @include media-breakpoint-down(xs) {
            &:hover::-webkit-scrollbar {
                width: 0;
                height: 0;
            }
        }
    }
}
.scrollbar-none {
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
        display: none;
    }
}

@mixin custom-scrollbar {
    $scrollbar-width: 6px;
    $scrollbar-height: 6px;
    &::-webkit-scrollbar {
        width: $scrollbar-width;
        height: $scrollbar-height;
        &-thumb {
            background-color: rgba($color: $black, $alpha: 0.2);
            &:hover {
                background-color: rgba($color: $black, $alpha: 0.4);
            }
        }
    }
}

.scrollbar-visible {
    @include custom-scrollbar()
}