.media-post {
  font-size: 14px;
  color: $gray-700;
  > .media {
    margin-right: 10px;
  }
  .media-object {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
    // border: 2px solid $primary;
  }
  .media-heading {
    font-family: $font-family-base;
    font-weight: bold;
    margin-bottom: 5px;
    font-size: 15px;
  }
  .time-stamp {
    color: #325271;
    font-weight: normal;
    font-size: 12px;
    &.light {
      color: #879ab5;
    }
  }
  .form-control {
    &:focus {
      border-color: $primary;
    }
  }

  .media-body {
    .media-object {
      width: 36px;
      height: 36px;
    }
  }
}

.quick-reactions {
  .reaction-holder {
    position: relative;
    display: flex;
    flex-direction: column;
    .reaction-anim {
      position: absolute;
      top: -45px;
      //   left: 0;
      > img {
        height: 50px;
        margin-left: -14px;
      }
    }
  }
  button.reaction {
    @extend .btn;
    @extend .btn-sm;
    font-weight: $font-weight-medium;
    color: $tertiary;
    background-color: transparent;
    padding: 0;
    font-size: 13px;
    height: unset;
    font-weight: $font-weight-bold;
    // border: 1px solid $gray-100;
    margin: 0 6px 6px 0;
    // margin-top: auto !important;
    margin-left: 0 !important;
    color: #809ab5;
    .icon {
      display: inline-block;
      margin-right: 2px;
      img{
        height: 22px;
        width: 22px;
        object-position: top;
        object-fit: cover;
        transition: all 0.2s;
      }
    }
    .name {
      vertical-align: middle;
    }
    .count {
      vertical-align: middle;
      margin-left: 6px !important;
    }
    @include hover-focus {
      // background-color: $white;
      // border-color: $gray-600;
      box-shadow: none;
      color: #809ab5;
    }
    &.reacted {
      color:#325271;
      // border-color: darken($primary, 20%);
      // background-color: fade-out($primary, 0.8);
      // background-color: $white;
      // font-weight: $font-weight-bold;
      .icon {
        img {
          object-position: bottom;
        }
      }
    }
  }
  
  .reaction-btn-group {
    display: flex;
    flex-wrap: nowrap;
    margin: 0 6px 6px 0;
    button.reaction {
      margin-right: 0;
      margin-bottom: 0;
      padding-left: 0;
      padding-right: 0;
      height: unset;
      .count {
        margin-left: 0;
      }
    }
  }
}

.media-min-height-container {
  min-height: 420px;
  // min-height: calc(100vh - 300px);
  .shown-media {
    max-height: 420px;
    &img {
      border: 2px solid #8791b5;
    }
  }
}