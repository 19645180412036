@font-face {
    font-family: 'League Gothic';
    src: url('LeagueGothic.woff2') format('woff2'),
        url('LeagueGothic.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Museo Sans 500';
    src: url('MuseoSans-500.woff2') format('woff2'),
        url('MuseoSans-500.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Museo Sans 900';
    src: url('MuseoSans-900.woff2') format('woff2'),
        url('MuseoSans-900.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

