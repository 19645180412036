.carousel-indicators {
    li {
        &.active {
            background-color: $primary;
        }
    }
    &.type-dots {
        li {
            width: 14px;
            height: 14px;
            border-radius: 50%;
        }
    }
    &.dark {
        li {
            background-color: $dark;
        }
    }
}


.type-secondary {
    .react-multi-carousel-dot--active button, 
    .react-multi-carousel-dot button:active,
    .react-multi-carousel-dot button:active:hover {
        background-color: $secondary;
        border-color: $secondary;
    }
}
.rr-carousel {
    .carousel {
        &.carousel-slider {
            .control-arrow {
                &:before {
                    color: $white;
                }
            }
        }
        .control-arrow {
            &:before {
                color: $dark;
            }
        }
        .control-arrow:before, &.carousel-slider .control-arrow:before {
            display: inline-block;
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
            font-size: 25px;
            -moz-osx-font-smoothing: grayscale;
            -webkit-font-smoothing: antialiased;
            font-style: normal;
            font-variant: normal;
            text-rendering: auto;
            line-height: 1;
            border: 0;
        }
        .control-prev.control-arrow:before {
            content: '\f104';
            border-right: 0;
        }
        .control-next.control-arrow:before {
            content: '\f105';
            border-left: 0;
        }
        .thumbs-wrapper {
            margin: 10px 30px;
        }

        /* .control-disabled.control-arrow {
            display: initial;
            opacity: 0.4;
        } */

        .slide {
            display: flex;
            justify-content: center;
            align-items: center;
            img {
                max-height: 400px;
                width: 100%;
                object-fit: contain;
            }
        }
        .thumb {
            img {
                height: 50px;
                object-fit: cover;
                object-position: center center;
            }
        }
    }
    &.type-media {
        .carousel .slider-wrapper.axis-horizontal .slider {
            align-items: flex-start;
        }
        .carousel .slide:not(.selected) {
            height: 0;
        }
    }
}

.react-multi-carousel-list {
    .react-multiple-carousel__arrow {
        background-color: transparent;
        opacity: 0.75;
        &::before {
            font-size: 40px;
            line-height: 1;
            font-weight: 700;
        }
        @include hover-focus {
            background-color: transparent;
            opacity: 1;
            outline: none;
        }
    }
}
.gallery-carousel {
    width: calc(100% - 20px);
    margin: 0 auto;
    .react-multiple-carousel__arrow--right {
        right: 1px;
    }
    .react-multiple-carousel__arrow--left {
        left: 1px;
    }
}
.category-carousel {
    padding-left: 40px;
    padding-right: 40px;
    background-color: #f0f1f6;
}
.theme-carousel-control {
    width: 40px;
    &.carousel-control-prev {
        left: 0px;
        opacity: 1;
    }
    &.carousel-control-next {
        right: 0;
        opacity: 1;
    }
    .carousel-control-prev-icon,
    .carousel-control-next-icon {
        background-color: transparent;
        opacity: 1;
        background-image: none;
        width: 20px;
        height: 40px;
        &::before {
            font-size: 40px;
            line-height: 1;
            font-weight: 700;
            // font-size: 20px;
            color: #fff;
            display: block;
            font-family: revicons;
            text-align: center;
            z-index: 2;
            position: relative;
            text-shadow: 0 0 4px rgba(0,0,0,0.5);
        }
    }
    .carousel-control-prev-icon {
        &::before {
            content: "\e824";
        }
    }
    .carousel-control-next-icon {
        &::before {
            content: "\e825";
        }
    }
}