// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Color system

$white:    #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black:    #000 !default;

$peach : #f4f3ea !default;
$turquoise: #15c3d4 !default;
$darkBlue: #122c40 !default;
$MediumOrchid: #b54fb3 !default;
$khakhi: #d1bb78 !default; 
$brown: #726647 !default;
$blue: #15c3d4 !default;

$primary:       $turquoise !default;
$primary-dark:       darken($primary, 20%) !default;
$secondary:     $khakhi !default;
$secondary-dark: $brown !default;
$tertiary: #879ab5 !default;
$lighter: #D7D5EA !default;
$royal-blue: #428bca !default;

$dark: $darkBlue !default;
$darker: #00162b !default;
$text-dark: #325271 !default;
$text-red: #d34836 !default;

$theme-colors: (
    "white": $white,
    "black": $black,
    "secondary-dark": $secondary-dark,
    "primary-dark": $primary-dark,
    "tertiary": $tertiary,
    "grey": #d5d6e5,
    "royal-blue": $royal-blue
) !default;

$social: (
    "google": #d34836,
    "facebook": #3b5998,
    "twitter": #55acee,
    "linkedin": #007bb5,
    "yahoo": #5e419a,
    "live": #f47007
) !default;

// $btn-block-spacing-y: 1rem !default;

//- Add custom variables
$navbar-height: 55px !default;

$nav-tabs-border-color: $text-dark !default;
$nav-tabs-link-active-border-color: transparent transparent $text-dark !default;
$nav-tabs-link-active-color: $text-dark !default;
$nav-tabs-link-active-bg: transparent !default;
$nav-link-padding-y:                .25rem !default;
$nav-link-padding-x:                0.75rem !default;

$enable-rounded: false !default;
// Body
//
// Settings for the `<body>` element.

$body-bg:                   $peach !default;
$body-color:                #353535 !default;


// Shadows
$text-shadow-sm: 0 1px 1px $black;
$text-shadow: 0 2px 2px $black;
$text-shadow-lg: 0 2px 4px $black;

// Paragraphs
//
// Style p element.

$paragraph-margin-bottom:   1rem !default;

// Typography
//
// Font, line-height, and color for body text, headings, and more.

// stylelint-disable value-keyword-case
$font-weight-normal:          500 !default;
$font-weight-medium:          500 !default;
$font-weight-semi-bold:       600 !default;
$font-weight-bolder:          900 !default;
$font-family-sans-serif:      "Museo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$headings-font-family:        "League Gothic", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", 'Noto Color Emoji' !default;
$font-family-alt:             Arial, Helvetica, sans-serif;
$font-family-base:            $font-family-sans-serif !default;
// stylelint-enable value-keyword-cases

$font-size-base:              1rem !default; // Assumes the browser default, typically `16px`


$input-placeholder-color: $gray-600 !default;
$input-focus-border-color: darken($gray-500, 10%) !default;
$input-focus-border-color-light: darken($gray-100, 10%) !default;


$custom-control-indicator-size-lg:         1.25rem !default;
// Modals



$modal-content-color:               null !default;
$modal-content-bg:                  transparent !default;
$modal-content-border-color:        rgba($black, .2) !default;
$modal-content-border-width:        0 !default;
$modal-content-border-radius:       0 !default;
$modal-header-padding-y:            0rem !default;

$modal-backdrop-opacity:            .85 !default;


$modal-header-border-color:         none !default;
$modal-footer-border-color:         $modal-header-border-color !default;
$modal-header-border-width:        0 !default;


$modal-xl:                          1140px !default;
$modal-lg:                          900px !default;
$modal-md:                          660px !default;
$modal-sm:                          331px !default;


// The yiq lightness value that determines when the lightness of color changes from "dark" to "light". Acceptable values are between 0 and 255.
$yiq-contrasted-threshold:  160 !default;
