@for $i from 10 through 50 {
    .fs-#{$i} {
        font-size: #{$i}px !important;
    }
}
.font-weight-medium  {
    font-weight: $font-weight-medium !important;
}
.font-weight-semi-bold {
    font-weight: $font-weight-semi-bold !important;
}
.text-shadow-soft {
    text-shadow: 0 4px 4px rgba(0,0,0,0.2);
}
.text-shadow-sm {
    text-shadow: $text-shadow-sm;
}
.text-shadow {
    text-shadow: $text-shadow;
}
.text-shadow-lg {
    text-shadow: $text-shadow-lg;
}
.as-link {
    @extend a.text-dark;
    @include hover-focus {
        cursor: pointer;
        @extend a.text-dark;
    }
}
.icon-circle {
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.actionable {
    font-weight: $font-weight-semi-bold;
    color: $secondary;
    font-size: 14px;
    // font-family: $font-family-alt;
    &:hover {
        cursor: pointer;
        color: darken($secondary, 10%);
    }
}

.text-brown {
    color: $brown !important;
}
.text-blue {
    color: $blue !important;
}
.text-dark {
    color: $text-dark !important;
}

.text-darker {
    color: $darker !important;
}

.text-lightGrey{
    color:#879ab5;
    
}
.option-active{
    color: #15c3d4 !important;;
    font-weight: bold !important;
}

// .text-lighter {
//     color: $lighter !important;
// }

.text-lighter {
    color: #879ab5;
}

.text-royal-blue {
    color: $royal-blue !important;
}

.lh-1 {
    line-height: 1 !important;
}
.lh-1-1 {
    line-height: 1.1 !important;
}
.lh-1-2 {
    line-height: 1.2 !important;
}
.lh-1-3 {
    line-height: 1.3 !important;
}
.lh-1-4 {
    line-height: 1.4 !important;
}
.lh-1-5 {
    line-height: 1.5 !important;
}
.lh-1-6 {
    line-height: 1.6 !important;
}
.lh-1-7 {
    line-height: 1.7 !important;
}
.lh-1-8 {
    line-height: 1.8 !important;
}
.lh-1-9 {
    line-height: 1.9 !important;
}
.lh-2 {
    line-height: 2 !important;
}

.hover-blue, a.hover-blue {
    @include hover-focus {
        color: #2a6496 !important;
    }
}

.hover-underline {
    @include hover-focus {
        text-decoration: underline !important;
    }
}

.text-secondary-dark {
    &._not-icon {
        @include hover-focus {
            > .svg-inline--fa {
                color: $secondary-dark;
            }
        }
    }
}

.text-secondary-darker {
    color: #362f2d !important;
}

.text-tertiary-darker {
    color: #879ab5 !important;
}

.text-greyish {
    color: #d5dce5 !important;
}

.text-highlight {
    background-color: rgba($color: $primary, $alpha: 0.1);
    padding: 4px;
    // color: $primary;
    &._tertiary {
        background-color: rgba($color: #eceff4, $alpha: 0.9);
    }
    &._warning {
        background-color: rgba($color: #fff7d2, $alpha: 0.9);
    }
}

.text-normal {
    white-space: normal !important;
}
.pe-none {
    pointer-events: none !important;
}

.draft-text{
    color:red;
    padding: 0px 10px;
    border: 1px solid red;
}

// input[type=radio] {
//     accent-color: rgb(0, 255, 0);
// }

input[type=radio]{
    border:2px solid white;
    box-shadow:0 0 0 1px #726647;
    appearance:none;
    border-radius:50%;
    width:10px;
    height:10px;
    background-color:#fff;
    transition:all ease-in 0.2s;
  
  }
  input[type=radio]:checked{
    background-color:#726647;
  }


  
  .invisible-scrollbar::-webkit-scrollbar {
    display: none;
  }
  


.sticky-header{
    position: sticky;
    top: 146px;
    z-index: 1;    
}

.sticky-header-2{
    position: sticky;
    top: 209px;
    z-index: 1;    
}

.sticky-header-3{
    position: sticky;
    top: 272px;
    z-index: 1;    
}

.sticky-header-4{
    position: sticky;
    top: 336px;
    z-index: 1;    
}

.sticky-main-header{
    position: sticky;
    top: 46px;
    z-index: 1;    
}

/* The container */
.containerCheckbox {
    display: block;
    position: relative;
    padding-left: 20px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    
  }
  
  /* Hide the browser's default checkbox */
  .containerCheckbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 2px;
    left: -1px;
    height: 13px;
    width: 13px;
    background-color: transparent;
    border: 1px solid #726647;
    border-radius: 2px;
  }
  
  /* On mouse-over, add a grey background color */
  .container:hover input ~ .checkmark {
    background-color: transparent;
    border: 1px solid #726647;
  }
  
  /* When the checkbox is checked, add a blue background */
  .containerCheckbox input:checked ~ .checkmark {
    background-color: white;
    border: 1px solid #726647;
    
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
    
  }
  
  /* Show the checkmark when checked */
  .containerCheckbox input:checked ~ .checkmark:after {
    display: block;
    
  }
  
  /* Style the checkmark/indicator */
  .containerCheckbox .checkmark:after {
    transform: rotate(45deg);
    height: 9px;
    width: 5px;
    margin-top: 0px;
    margin-left: 3px;
    border-bottom: 2px solid #726647;
    border-right: 2px solid #726647;
  }