.popover-lg{
	max-width: 500px;
}
.popover.placement-bottom {
	top: 60px !important;
}
.popover.placement-bottom > .arrow {
    top: calc(-0.5rem - 1px);
	bottom: unset;
	&::before {
		top: 0;
		bottom: unset;
		border-width: 0 0.5rem 0.5rem 0.5rem;
		border-bottom-color: rgba(0, 0, 0, 0.25);
	}
	&::after {
		top: 1px;
		bottom: unset;
		border-width: 0 0.5rem 0.5rem 0.5rem;
		border-bottom-color: #fff;
	}
}

.popover-basic {
	.popover {
		border: 2px solid #d1bb78;
    border-radius: 0;
	padding: 1px;
	}
	.arrow {
		display: none;
	}
	.popover-header {
		color: #333;
		font-family: $font-family-base;
		padding: 8px 14px;
    margin: 0;
    font-size: 14px;
	font-weight: bold;
    line-height: 18px;
    background-color: #f7f7f7;
    border-bottom: 1px solid #ebebeb;
    border-radius: 5px 5px 0 0;
	}
	.popover-body {
		font: 14px/1.5 'arial';
		word-wrap: break-word;
		color: #325271;
	}
	
	.popover-body {
		padding: 9px 14px;
	}
	.editable-item {
		
	cursor: url(https://userdatawikireviews.s3.amazonaws.com/images/edit-info-icon.png), auto;
    border-bottom: 0;
    font: 14px/1.5 Arial;
    color: #325271;
    font-weight: bold;
    vertical-align: top;
    line-height: normal;
	}
}