.nav-tabs {
    // border-bottom-width: 1px;
    margin-bottom: $spacer;
    .nav-link {
        cursor: pointer;
        font-size: 14px;
        color: $tertiary;
        font-weight: $font-weight-bold;
        border: 0;
        font-family: $font-family-base;
        border-bottom: 3px solid transparent;
        @include hover-focus {
            color: $text-dark;
        }
    }
    &.main {
        flex-wrap: nowrap;
        .nav-link {
            font-size: 14px;
        }
    }

    &.non-decorated {
        border: none;
        .nav-link {
            text-decoration: none;
            font-family: $font-family-base;
            background-color: transparent;
            font-weight: $font-weight-normal;
            border: none;
            font-size: 1rem;
            @include hover-focus {

            }
            &.active {
                font-weight: $font-weight-bold;
            }
        }
    }
    &.non-decorated-alt {
        border: none;
        .nav-link {
            text-decoration: none;
            font-family: $font-family-base;
            background-color: transparent;
            border: none;
            font-size: 14px;
            color: $tertiary;
            padding-left: 0px;
            padding-right: 0px;
            margin-right: 14px;
            .count {
                font-family: $font-family-alt !important;
                font-size: 12px !important;
                font-weight: $font-weight-normal !important;
                padding: 3px 6px !important;
                margin-left: 6px;
                min-width: 20px;
                height: 20px;
                background-color: $primary !important;
                color: $white !important;
                align-self: flex-start;
                text-align: center;
                line-height: 14px !important;
                border-radius: 10px;
            }
            @include hover-focus {
                color: $text-dark;
            }
            &.active {
                color: $text-dark;
            }
        }
    }

    &.active-transparent {
        .nav-link {
            &.active {
                background-color: transparent;
            }
        }
    }
    &.active-bold {
        .nav-link {
            &.active {
                font-weight: $font-weight-bold;
            }
        }
    }
    &.non-active-normal {
        .nav-link {
            font-weight: $font-weight-normal;
        }
    }
    &.hover-none {
        .nav-link {
            &:hover {
                border-color: transparent;
            }
        }
        &:not(.dark) {
            .nav-link {
                color: $text-dark;
                &.active {
                    border-color: $text-dark;
                }
            }
        }
    }

    &.dark {
        border-color: $tertiary;
        .nav-link {
            color: $tertiary;
            &.active {
                color: $white;
                border-color: $white;
            }
        }
    }
    &.movie-listing-tabs {
        .nav-link {
            font-size: 15px;
            padding: 0 6px 6px;
            &:hover {
                color: $white;
                border-color: $white;
                &.active {
                    color: $text-dark;
                }
            }
            &.active {
                border-color: $white;
            }
            
        }
    }
    &.secondary-dark {
        border-color: $secondary-dark;
        border-color: $gray-400;
        .nav-link {
            color: rgba($color: $secondary-dark, $alpha: 0.7);
            &:hover {
                // border-color: rgba($color: $secondary-dark, $alpha: 0.7);
                color: $secondary-dark;
            }
            &.active {
                color: $secondary-dark;
                border-color: $secondary-dark;
            }
        }
    }
    &.active-border-primary {
        .nav-link {
            &:hover, &.active {
                border-color: $primary;
            }
        }
    }

    &.with-counts {
        .nav-item {
            margin-right: 12px;
            display: flex;
            flex-wrap: nowrap;
        }
        .nav-link, .count {
            padding: 0.25rem 0;
        }
        .nav-link {
            font-size: 13px;
            &:hover {
                border-color: transparent;
            }
            &.active {
                border-color: $text-dark;
            }
        }
        .count {
            font-family: $font-family-alt !important;
            font-size: 12px !important;
            font-weight: $font-weight-normal !important;
            padding: 3px 6px !important;
            margin-left: 6px;
            min-width: 20px;
            height: 20px;
            background-color: $primary !important;
            color: $white !important;
            align-self: flex-start;
            text-align: center;
            line-height: 14px !important;
            border-radius: 10px;
        }
    }
}
.main-tab-count {
    font-family: $font-family-alt !important;
    font-size: 12px !important;
    font-weight: $font-weight-normal !important;
    padding: 3px 6px !important;
    margin-left: 6px;
    min-width: 20px;
    height: 20px;
    background-color: $primary !important;
    color: $white !important;
    align-self: center;
    text-align: center;
    line-height: 14px !important;
    border-radius: 10px;
}
.nav-pills {
    .nav-link {
        background-color: $primary;
        color: color-yiq($primary);
        font-family: $font-family-base;
        font-size: 14px;
        &.active {
            background-color: darken($primary, 10%);
        }
    }
    
    &.featured {
        // .nav-item {
        //     &:not(:last-child) {
        //         margin-right: 2px;
        //     }
        // }
        .nav-link {
            padding: 15px 10px;
            font-size: 16px;
            letter-spacing: 1px;
            // text-shadow: $text-shadow-sm;
            background-color: rgba($color: $darker, $alpha: 0.8);
            color: #d9dada;
            &:hover, &.active {
                background-color: rgba($color: $white, $alpha: 0.8);
                color: $dark;
            }
        }
    }
}
ul.search-results-nav {
    border-color: $tertiary;
    li a {
        width: 40px;
        height: 30px;
        margin: 0 8px;
        display: inline-block;
        padding: 0;
        text-align: center;
        font-size: 16px;
        span {
            width: 23px;
            display: inline-block;
        }
    }
}
/*products*/
ul.search-results-nav li a span.products{
	background:url('https://userdatawikireviews.s3.amazonaws.com/images/top-bar-search.png') no-repeat 0 -95px;
	height: 23px;
}
ul.search-results-nav li a.active span.products,
ul.search-results-nav li a:hover span.products{
	background:url('https://userdatawikireviews.s3.amazonaws.com/images/top-bar-search.png') no-repeat 0 -114px;	
}

/*business*/

ul.search-results-nav li a span.business{
	background:url('https://userdatawikireviews.s3.amazonaws.com/images/top-bar-search.png') no-repeat 0 -50px;
	height: 23px;
}

ul.search-results-nav li a.active span.business,
ul.search-results-nav li a:hover span.business{
	background:url('https://userdatawikireviews.s3.amazonaws.com/images/top-bar-search.png') no-repeat 0 -73px;	
}

/*movies*/
ul.search-results-nav li a span.movies{
	background:url('https://userdatawikireviews.s3.amazonaws.com/images/top-bar-search.png') no-repeat 0 -136px;
	height: 23px;
}
ul.search-results-nav li a.active span.movies,
ul.search-results-nav li a:hover span.movies{
	background:url('https://userdatawikireviews.s3.amazonaws.com/images/top-bar-search.png') no-repeat 0 -162px;	
}

/*professionals*/
ul.search-results-nav li a span.professionals{
	background:url('https://userdatawikireviews.s3.amazonaws.com/images/professionals-icon.png') no-repeat 0 0;
	height: 23px;
}
ul.search-results-nav li a.active span.professionals,
ul.search-results-nav li a:hover span.professionals{
	background:url('https://userdatawikireviews.s3.amazonaws.com/images/professionals-icon.png') no-repeat 0 -28px;	
}

/*people*/
ul.search-results-nav li a span.people{
	background:url('https://userdatawikireviews.s3.amazonaws.com/images/search-people.png') no-repeat;
	height: 23px;
	width: 25px;
}
ul.search-results-nav li a.active span.people,
ul.search-results-nav li a:hover span.people{
	background:url('https://userdatawikireviews.s3.amazonaws.com/images/search-people.png') no-repeat 0 -23px;
	height: 23px;
}

/*people*/
ul.search-results-nav li a span.actors{
	background:url('https://userdatawikireviews.s3.amazonaws.com/images/actors.png') no-repeat;
	height: 23px;
	width: 25px;
}
ul.search-results-nav li a.active span.actors,
ul.search-results-nav li a:hover span.actors{
	background:url('https://userdatawikireviews.s3.amazonaws.com/images/actors.png') no-repeat 0 -28px;
	height: 23px;
}
/*crp*/
ul.search-results-nav li a span.crp-search{
	background:url('https://userdatawikireviews.s3.amazonaws.com/images/crp.png') no-repeat;
	height: 23px;
	width: 21px;
}
ul.search-results-nav li a.active span.crp-search,
ul.search-results-nav li a:hover span.crp-search{
	background:url('https://userdatawikireviews.s3.amazonaws.com/images/crp-hover.png') no-repeat;
	height: 23px;
}