.notification-heading {
    color: #325271;
    border-bottom: 1px solid #879ab5;
}
.notification-wrapper {
    position: relative;
    display: inline-block;
    padding-right: 20px;
    .notification-count {
        position: absolute;
        top: -5px;
        right: 8px;
    }
}
.notification-count {
    font-size: 11px;
    position: absolute;
    right: 4px;
    left: 20px;
    top: 0;
    color: #000;
    text-align: center;
    background: #15c3d4;
    width: 15px;
    height: 15px;
    display: inline-block;
    font-family: Arial, Helvetica, sans-serif;
    border-radius: 50%;
    line-height: 15px;
    font-weight: 300;

    &._sm {
        width: 16px;
        height: 16px;
        left: 0 !important;
        font-size: 10px;
        position: relative !important;
    }
}