.app-header {
  padding-top: 45px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: $black;
  color: $white;
  margin-bottom: $spacer;
  @include media-breakpoint-down(sm) {
    position: relative;
    &:after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba($color: $black, $alpha: 0.3);
    }
  }
}
.app-header-block {
  min-height: 400px;
  padding: 1rem 2rem 0;
  background-image: linear-gradient(
    to right,
    #333,
    transparent 30%,
    transparent 70%,
    #333 100%
  );
  @include media-breakpoint-down(sm) {
    padding: 0;
    position: relative;
    z-index: 2;
    background-image: none;
  }
}
.stat {
  border-bottom: 1px solid $white;
  &:not(:last-child) {
    margin-bottom: 10px;
  }
  .count {
    font-family: $headings-font-family;
    font-size: 30px;
    line-height: 1;
  }
  .type {
    font-weight: $font-weight-bold;
    margin-bottom: 5px;
    font-size: 14px;
  }
  &.dark {
    border-bottom: 1px solid $dark;
    color: $dark;
  }
  @include media-breakpoint-down(sm) {
    margin-bottom: 0 !important;
    border-bottom: none;
  }
}
.avatars {
  height: 210px;
  width: 210px;
  padding: 34px;
  margin: 0 auto;
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &.silver {
    background: url("../../assets/images/avatars/silver-white.png") no-repeat
      center;
  }
  &.palladium {
    background: url('../../assets/images/avatars/palladium-white.png') no-repeat center;
  }

  &.osmium {
    background: url('../../assets/images/avatars/osmium-white.png') no-repeat center;
    background-position-y: 25%;
    background-position-x: center;
    background-size: 90%;
  }

  &.rhenium {
    background: url("../../assets/images/avatars/rhenium-white.png") no-repeat center;
    background-size: 100%;
  }

  &.iridium {
    background: url('../../assets/images/avatars/iridum-white.png') no-repeat center;
    background-position-y: 8%;
    background-position-x: center;
    background-size: 95%;
  }

  &.ruthenium {
    background: url('../../assets/images/avatars/ruthenium-white.png') no-repeat center;
  }

  &.gold {
    background: url('../../assets/images/avatars/gold-white.png') no-repeat center;
  }

  &.platinum {
    background: url('../../assets/images/avatars/platinum-white.png') no-repeat center;
  }

  &.rhodium {
    background: url('../../assets/images/avatars/rhodium-white.png') no-repeat center;
  }
  @include media-breakpoint-down(sm) {
    width: 100px;
    height: 100px;
    padding: 10px;
    background-size: contain !important;
  }
}
.dragaa {
  position: relative;
  overflow: hidden;
  cursor: move;
  z-index: 99;

  img {
    width: 320px;
    max-width: 320px;
    height: 240px;
    position: absolute;
  }
}

.pancontainer {
  width: 150px;
  height: 150px;
  background-color: white;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 99em;
  margin: 10px auto;
}

.save-image {
  cursor: pointer;
  display: inline;
  padding: 4px;
  background-color: rgba($color: $black, $alpha: 0.4);
  color: rgba($color: $white, $alpha: 0.9);

  @include hover-focus() {
    color: $white;
  }
}

.avatar-img-holder {
  position: relative;
  z-index: 1;
  background-color: $white;
  border-radius: 50%;
  width: 140px;
  height: 140px;
  overflow: hidden;
  //margin: 0 auto;
  > img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
  //&.dragaa > img {
  //  position: absolute;
  //  width: 320px;
  //  height: 240px;
  //  object-fit: cover;
  //  object-position: center center;
  //}
}

.user-badge-link {
  display: inline-block;
  text-decoration: none !important;
}
.user-badge {
  background-image: url("../../assets/images/icons/badge-bg.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center top;
  display: inline-flex;
  flex-direction: column;
  min-height: 75px;
  .badge-date {
    margin-top: 2px;
    background-color: #1e5284;
    color: $white;
    font-size: 10px;
    width: 100%;
    text-align: center;
  }
  .badge-img {
    text-align: center;
    width: 100%;
    > img {
      padding: 10px;
      width: 40px;
      height: 40px;
      object-fit: contain;
      box-sizing: border-box;
    }
    &.full {
      > img {
        padding: 0;
        width: 100%;
        height: auto;
        box-sizing: border-box;
      }
    }
  }
}

.profile-pic-holder {
  position: relative;
  border-radius: 50%;
  width: 150px;
  height: 150px;
  margin: auto;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  .profile-pic {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }
  .upload-file-block {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba($color: $dark, $alpha: 0.7);
    color: $gray-100;
    font-size: 12px;
    font-weight: $font-weight-medium;
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.2s;
  }
  &:hover {
    .upload-file-block {
      opacity: 1;
    }
  }
}

.instant-results {
  position: absolute;
  margin-top: 5px;
  max-height: 345px;
  overflow-y: auto;
  z-index: 1;
  .result-item {
    // border-bottom: 1px solid $gray-300;
  }
  .result-link {
    font-family: $font-family-base;
    display: block;
    background-color: $white;
    padding: 0.5rem 1rem;
    text-decoration: none !important;
    color: $text-dark;
    font-size: 14px;
    cursor: pointer;
    &:hover {
      background-color: darken($color: $white, $amount: 2%);
    }
  }
  .result-icon {
    width: 32px;
    margin-right: 0.5rem;
  }
}
.input-label-block__suggestions {
  box-shadow: $box-shadow-sm;
  &__item {
    // font-family: $font-family-base;
    display: block;
    background-color: $white;
    padding: 0.5rem 1rem;
    text-decoration: none !important;
    color: #428bca;
    font-size: 14px;
    cursor: pointer;
    &--focused {
      background-color: #428bca;
      color: $white;
    }
  }
}

.gallery-media {
  width: 125px;
  height: 105px;
  border: $border-width solid #ddd;
  font-size: 12px;
  white-space: normal;
  word-break: break-all;
  border-radius: 4px;
  > img, > video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
  &._type-audio {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    > img {
      width: 40px;
      height: auto;
      border: 0;
      background-color: #fff;
    }
  }
}
.gallery-media2 {
  width: 100%;
  height: 100%;
  border: $border-width solid #ddd;
  font-size: 12px;
  white-space: normal;
  word-break: break-all;
  border-radius: 4px;
  > img, > video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
  &._type-audio {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    > img {
      width: 40px;
      height: auto;
      border: 0;
      background-color: #fff;
    }
  }
}

.checkable-media {
  position: relative;
  padding-left: 0;
  .gallery-media {
    position: relative;
    padding: 5px;
    background-color: $gray-200;
    border-color: $gray-200;
    &::after {
      content: "\f111";
      background-color: rgba($color: $dark, $alpha: 0.7);
      padding: 6px;
      top: 5px;
      right: 5px;
      position: absolute;
      display: inline-block;
      font-family: "Font Awesome 5 Free";
      font-weight: 400;
      color: $gray-100;
      font-size: 1rem;
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      font-style: normal;
      font-variant: normal;
      text-rendering: auto;
      line-height: 1;
    }
  }

  .form-check-label {
    cursor: pointer;
  }
  .form-check-input {
    position: absolute;
    opacity: 0;
    &:checked {
      + .gallery-media {
        border-color: $primary;
        background-color: $primary;
        &::after {
          content: "\f058";
          color: $primary;
          font-weight: 900;
        }
      }
    }
  }
}

.selectable-media {
  cursor: pointer;
  .gallery-media {
    padding: 0.25rem;
    background-color: #f4f3ea;
    border: 1px solid #dee2e6;
  }
  &.selected {
    .gallery-media {
      border-color: $dark;
      background-color: $dark;
    }
  }
}
#workHistory .interactive-appear , #myEducation .interactive-appear {
  position: initial !important;
}