.circle-step {
    color: $text-dark;
    text-align: center;
    margin-bottom: 30px;
    ul {
        display: flex;
        flex-wrap: wrap;
    }
    li {
        @extend .col;
        font-family: $font-family-base;
        font-size: 14px;
        &:not(:first-child) {
            &::after {
                content: "";
                position: absolute;
                width: 110px;
                border-top: 2px dashed $text-dark;
                left: -55px;
                top: 16px;
                height: 2px;
            }
        }
        span {
            margin-top: 10px;
            display: inline-block;
        }
        &.active {
            strong {
                background: $text-dark;
                color: $white;
            }
            span {
                font-weight: $font-weight-bold;
            }
        }
    }
    strong {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        border: 1px solid $text-dark;
        font-size: 16px;
        line-height: 32px;
        display: block;
        margin: 0 auto;
    }
}