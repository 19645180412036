.notification-alert {
    background-color: #15c3d4;
    color: white;
}

.notification-alert-title {
    font-family: "League Gothic", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 2.25rem;
    line-height: 1;
}

.notification-alert-desc {
    font-size: 0.9rem;
    padding: 0.5rem 0;
}